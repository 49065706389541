import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalizeWithSpaces'
})
export class CapitalizeWithSpacesPipe implements PipeTransform {

    shouldBeAllUppercase: string[] = ['mrn', 'dma', 'qsr'];

    transform(value: string): string {
        if(this.shouldBeAllUppercase.includes(value)) return value.toUpperCase();
        value = value.charAt(0).toUpperCase() + value.slice(1);
        value = value.replace(/([a-z])([A-Z])/g, '$1 $2');
        return value;
    }

}


