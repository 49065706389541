<nav class="navbar is-primary" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
        <a class="navbar-item" [routerLink]="homeLink" [queryParams]="{step: 1}" (click)="onLogoClick()">
            <img src="../../../assets/images/{{logo}}" width="138">
        </a>
        <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="appNavbar"
            [class.is-active]="showBurgerMenu" (click)="showBurgerMenu = !showBurgerMenu">
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
        </a>
    </div>
    <div id="appNavbar" class="navbar-menu" [class.is-active]="showBurgerMenu">
        <div class="navbar-start">
        </div>
        <div class="navbar-end">
            <div class="navbar-item">
                <button *ngIf="!isLoggedIn" class="button is-accent is-rounded btn-nav"
                    (click)="onLogInClick()" fragment="calculator">Log In</button>
            </div>
            <div *ngIf="isLoggedIn" class="navbar-item" [class.has-dropdown]="isLoggedIn" [class.is-hoverable]="isHoverable">
                <a class="navbar-link">{{userInfo.firstName + ' ' + userInfo.lastName | titlecase}}
                </a>
                <div class="navbar-dropdown">
                    <a class="navbar-item is-hidden" routerLink="/projects" (click)="onNavMenuClick($event)">
                        My Projects
                    </a>
                    <hr class="navbar-divider is-hidden">
                    <a class="navbar-item" (click)="onLogOutClick()">
                        Logout
                    </a>
                </div>
            </div>
        </div>
    </div>
</nav>
