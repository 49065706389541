<app-header></app-header>

<div id="disclaimer" class="modal">
    <div class="modal-background"></div>
    <div class="modal-card">
        <header class="modal-card-head is-justify-content-center">
            <p>
                <img src="../../../assets/images/{{logo}}" alt="TREDLite" style="height: 36px;">
            </p>
        </header>
        <section class="modal-card-body">
            <p [innerHtml]="disclaimer"></p>
            <ul class="ul-disclaimer mt-2">
                <ng-container *ngFor="let f of features" >
                    <li *ngIf="f.isActive">{{f.title}}</li>
                </ng-container>
            </ul>
            <p class="mt-2">{{disclaimerContinue}}</p>
            <div class="mt-2" [innerHtml]="legal"></div>
        </section>
        <footer class="modal-card-foot is-justify-content-space-around">
            <button class="button is-primary is-rounded" (click)="onModalClose()">Accept</button>
        </footer>
    </div>
</div>
<router-outlet></router-outlet>

<footer class="footer is-secondary">
    <div class="level">
        <div class="level-left">
            <div class="level-item">
                <img src="../assets/images/kh_logo_168x36.png">
            </div>
        </div>
        <div class="level-right">
            <p>Copyright {{year}}, All Rights Reserved.</p>
        </div>
    </div>
  </footer>
