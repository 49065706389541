import { Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import esriConfig from '@arcgis/core/config.js';
import WebMap from '@arcgis/core/WebMap';
// Changed name from Map to ArcMap to not conflict with JS type Map
import * as watchUtils from '@arcgis/core/core/watchUtils';
import Geometry from '@arcgis/core/geometry/Geometry';
import * as geometryEngine from '@arcgis/core/geometry/geometryEngine';
import Point from '@arcgis/core/geometry/Point';
import Polygon from '@arcgis/core/geometry/Polygon';
import Graphic from '@arcgis/core/Graphic';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import GroupLayer from "@arcgis/core/layers/GroupLayer";
import AggregateField from '@arcgis/core/layers/support/AggregateField';
import Field from '@arcgis/core/layers/support/Field';
import ArcMap from '@arcgis/core/Map';
import * as rendererJsonUtils from '@arcgis/core/renderers/support/jsonUtils';
import Request from '@arcgis/core/request';
import Query from "@arcgis/core/rest/support/Query";
import PictureMarkerSymbol from "@arcgis/core/symbols/PictureMarkerSymbol.js";
import SimpleFillSymbol from "@arcgis/core/symbols/SimpleFillSymbol";
import FeatureLayerView from '@arcgis/core/views/layers/FeatureLayerView';
import MapView from '@arcgis/core/views/MapView';
import BasemapToggle from '@arcgis/core/widgets/BasemapToggle';
import LayerList from '@arcgis/core/widgets/LayerList';
import SketchViewModel from '@arcgis/core/widgets/Sketch/SketchViewModel';
import { isGuid } from 'src/app/utils/string-utils';

import { FilterResultType } from 'src/app/models/ev/filter.model';
import { ClassBreakInfo, IHighlight, RendererCollection, RendererInfo } from 'src/app/models/shared/map.model';
import { LayerRelationshipType, SiteThemeLayerInfo, ThemeLayer } from 'src/app/models/shared/theme-layer.model';
import { EvLogicService } from 'src/app/services/ev/logic-services/ev-logic.service';
import { ExcelExportService } from 'src/app/services/ev/logic-services/excel-export.service';
import { AgencyService } from 'src/app/services/shared/agency.service';
import { EsriLocationService } from 'src/app/services/shared/esri-location.service';
import { FeatureSelectorType, MapInteractionService } from 'src/app/services/shared/map-interaction.service';
import { NotificationCloseType, NotificationService, NotificationType } from 'src/app/services/shared/notification.service';
import { ThemeLayerService } from 'src/app/services/shared/theme-layer.service';
import { ImageService } from 'src/app/services/vmt/image.service';

export class EvStats {
    total: number;
    remainder: number;
}

@Component({
    selector: 'app-map-widget',
    templateUrl: './map-widget.component.html',
    styleUrls: ['./map-widget.component.scss']
})
export class MapWidgetComponent implements OnInit, OnDestroy {
    // The <div> where we will place the map
    @ViewChild('mapViewNode', { static: true }) private mapViewElement: ElementRef;
    private view: MapView = null;
    private map: ArcMap;
    private graphicsLayer: GraphicsLayer;
    private defaultZoomLevel: number = 5;
    private defaultZoomInLevel: number = 15;
    private layerList: LayerList;
    private basemapToggle: BasemapToggle;
    private isMapReady: boolean;
    private subscriptions: Subscription[] = [];
    private readonly NEIGHBORHOOD: number = 20000;
    private readonly KHID: string = "KHID";
    private readonly BUFFER_SIZE = 5;
    private readonly CENSUS_BLOCKGROUP_URL = 'https://tigerweb.geo.census.gov/arcgis/rest/services/Census2020/tigerWMS_Census2010/MapServer/12';
    private readonly CENSUS_BLOCK_URL = 'https://tigerweb.geo.census.gov/arcgis/rest/services/Census2020/tigerWMS_Census2010/MapServer/14';
    private readonly DISTANCE = 100;
    private readonly UNITS = 'miles';
    evStats: EvStats = {
        total: 0,
        remainder: 0
    };
    evThemeLayers: ThemeLayer[] = [];
    evThemeLayerGroups: ThemeLayer[][] = [];
    evGroupSet = 3;

    previousPoint: Point;
    sketchViewModel: SketchViewModel;
    mapDictionary: any = {};
    rendererDictionary: Map<string, RendererCollection> = new Map<string, RendererCollection>();
    highlights: IHighlight[] = [];
    parcelLayers: string[] = [];
    priorityLayers: any[] = [];
    loaderText: string = 'Loading...';
    tazLayer: string;
    tazIDField: string;
    startExtentIndex = 7;
    shapefileLayerId: string;
    webmapId: string;
    highlightsToDo: any[];
    extent = {
        xmax: 0,
        xmin: 0,
        ymax:0,
        ymin: 0,
        spatialReference: {
            wkid: 102100
        }
    };
    minScale: number;
    isTazVisible: boolean = false;
    isParcelVisible: boolean = false;
    isEv: boolean = false;
    maxZoomCluster = 8;
    storedFeatureReduction: any;
    hasAttributeMatches: boolean;
    attributeEvIds: Set<number> = new Set();
    performanceEvIds: Set<number> = new Set();
    pinGraphic: Graphic;
    pinSymbol = new PictureMarkerSymbol({
        width: 24,
        height: 24,
        url: '../../assets/images/pin.svg'
    });
    selectedPinPoint: Point;
    agencyName: string;

    get themeLayerData(): Map<number, SiteThemeLayerInfo[]> { return this.themeLayerService.themeLayerData; }

    get allThemeLayerDataGathered(): boolean { return this.themeLayerService.allThemeLayerDataGathered; }

    constructor(private zone: NgZone, private esriLocationService: EsriLocationService, private mapInteractionService: MapInteractionService,
        private notificationService: NotificationService, private imageService: ImageService,
        private agencyService: AgencyService, private _evLogicService: EvLogicService, private activatedRoute: ActivatedRoute,
        private _excelExportService: ExcelExportService, private themeLayerService: ThemeLayerService) {
        this.isMapReady = false;
        this.graphicsLayer = new GraphicsLayer({ listMode: 'hide', title: 'tredlite-graphics' });

        this.subscriptions.push(agencyService.configUpdated$.subscribe(config => {
            this.parcelLayers = config.parcelLayers;
            this.priorityLayers = config.priorityLayers;
            this.tazLayer = config.tazLayer;
            this.tazIDField = config.tazIdField;
            this.webmapId = config.webmapId;
            this.extent.xmin = config.xMin;
            this.extent.xmax = config.xMax;
            this.extent.ymin = config.yMin;
            this.extent.ymax = config.yMax;
            this.agencyName = config.agencyName;
            if (!this.isEv)
                this.setupMap();
        }));

        this.subscriptions.push(esriLocationService.locationUpdated$.subscribe(point => {
            if (this.previousPoint) {
                this.graphicsLayer.removeAll();
                this.goTo(this.defaultZoomLevel, this.previousPoint).then(x => {
                    setTimeout(() => {
                        this.goTo(this.defaultZoomInLevel, point)
                        .then(() => {
                            if (!this.isEv)
                                this.selectFeatures(point);
                            this.esriLocationService.getLocationFromCoordinates(point, 'City')
                                .then(city => {
                                    this.mapInteractionService.updateCity(city);
                                });
                        });
                    }, 1000);
                }).catch(error => {
                    if (error.name === 'AbortError') {
                        this.goTo(this.defaultZoomInLevel, point);
                    }
                });
            } else {
                this.goTo(this.defaultZoomInLevel, point)
                .then(() => {
                    this.selectFeatures(point);
                    this.esriLocationService.getLocationFromCoordinates(point, 'City')
                        .then(city => {
                            this.mapInteractionService.updateCity(city);
                        });
                });
            }

            this.previousPoint = point;
            if (this.isEv) {
                let evLayer = this.map.findLayerById(this.mapDictionary[this.parcelLayers[0]]) as FeatureLayer;
                // Find nearest EV site and don't drop pin if it exists
                let buffer = geometryEngine.geodesicBuffer(point, this.BUFFER_SIZE, 'meters') as Polygon;
                evLayer.queryFeatures({
                    geometry: buffer,
                    returnGeometry: true,
                    spatialRelationship: 'intersects'
                }).then(featureSet => {
                    if (featureSet.features.length) return;
                    this.addPinToMapFromPoint(point);
                });
            } else {
                this.addPinToMapFromPoint(point);
            }
        }));

        this.subscriptions.push(mapInteractionService.featureSelectorUpdated$.subscribe(selectorType => {
            switch (selectorType) {
                case FeatureSelectorType.Shape:
                    this.selectByPolygon();
                    break;
                case FeatureSelectorType.Box:
                    this.selectByRectangle();
                    break;
                case FeatureSelectorType.Single:
                    this.selectByPoint();
                    break;
            }
        }));
        this.subscriptions.push(mapInteractionService.clearSelectionStarted$.subscribe(() => {
            this.removeAllHighlights();
        }));
        this.subscriptions.push(mapInteractionService.undoSelectionStarted$.subscribe(() => {
            let highlight = this.highlights.pop();
            if (!highlight) return;
            highlight.features = [];
            highlight.layerId = null;
            highlight.geometry = null;
            highlight.graphics.remove();
            this.sendHighlightUpdate();
        }));
        this.subscriptions.push(mapInteractionService.takeScreenshotRequested$.pipe(take(1)).subscribe(() => {
            this.view.takeScreenshot().then(screenshot => {
                this.mapInteractionService.completeScreenshot(screenshot);
            });
        }));
        this.subscriptions.push(mapInteractionService.uploadShapefileRequested$.subscribe(filename => {
            this.generateFeatureCollection(filename);
        }));
        this.subscriptions.push(mapInteractionService.selectionToTazRequested$.subscribe(options => {
            this.getTazFromSelection(options);
        }));
        this.subscriptions.push(mapInteractionService.shapefileToTazRequested$.subscribe(() => {
            this.getTazFromShapefile();
        }));
        this.subscriptions.push(mapInteractionService.widgetsToggled$.subscribe(() => {
            this.toggleAllWidgets();
        }));
        this.subscriptions.push(mapInteractionService.centerOnSelectionRequested$.subscribe(() => {
            this.centerOnSelection();
        }));
        this.subscriptions.push(mapInteractionService.goToRequested$.subscribe(coords => {
            if (coords === null) {
                let mainLayer = this.map.findLayerById(this.mapDictionary[this.parcelLayers[0]]) as FeatureLayer;
                if (mainLayer && mainLayer.fullExtent)
                    this.goTo(this.defaultZoomLevel, mainLayer.fullExtent.center);
                return;
            }
            let point = new Point();
            point.latitude = coords[0];
            point.longitude = coords[1];
            this.goTo(10, point);
        }));
        this.subscriptions.push(mapInteractionService.highlightSet$.pipe(take(1)).subscribe((highlights: any[]) => {
            this.highlightsToDo = highlights;
            // Wait for map to be ready
            const intervalId = setInterval(() => {
                if (this.isMapReady) {
                    clearInterval(intervalId);
                    if (this.highlightsToDo) {
                        this.highlightsToDo.forEach(h => {
                            let geometry = JSON.parse(h.Geometry);
                            if (geometry.x && geometry.y)
                                geometry.type = "point";
                            else if (geometry.rings)
                                geometry.type = "polygon";
                            this.selectFeaturesByLayer(h.LayerId, geometry);
                        });
                    }
                }
            }, 500);
        }));
        this.subscriptions.push(mapInteractionService.togglePriorityLayersRequested$.subscribe(show => {
            this.priorityLayers.forEach(layerInfo => {
                let mapLayer = this.map.findLayerById(this.mapDictionary[layerInfo.title]);
                if (mapLayer) {
                    mapLayer.opacity = show ? 0.8 : 0;
                    mapLayer.visible = show;
                }
            });
            if (show)
                this.centerOnSelection(this.defaultZoomInLevel);
            else
                this.centerOnSelection(this.defaultZoomInLevel + 1);
        }));
        this.subscriptions.push(mapInteractionService.classBreakUpdated$.subscribe(params => {
            this.addLayerClassBreaks(params.layer, params.valueExpression, params.classBreakInfo);
        }));
        this.subscriptions.push(mapInteractionService.filterChanged$.subscribe(filter => {
            this.filterEvLayer(filter.includedIds, filter.filterType, filter.goToExtent, filter.hasMatches);
        }));
        this.subscriptions.push(mapInteractionService.initializePinDropRequested$.subscribe(() => {
            this.initializePinDrop();
        }));
        this.subscriptions.push(mapInteractionService.resetFilterRequested$.subscribe(() => {
            this.resetEvLayer();
        }));
        this.subscriptions.push(themeLayerService.layerList().subscribe(layers => {
            this.evThemeLayers = layers;
            if (this.isEv)
                this.setupMap();
            for (let i = 0; i < this.evThemeLayers.length; i += this.evGroupSet) {
                this.evThemeLayerGroups.push(this.evThemeLayers.slice(i, i + this.evGroupSet));
            }
        }));
        this.subscriptions.push(
            this.themeLayerService.allThemeLayerDataExportPosted$.subscribe({
                next: () => {
                    let formattedData = this._evLogicService.formatSiteDataForExport(this.themeLayerData);
                    this._excelExportService.exportToExcel(formattedData, "filtered_site_data.xlsx");
                },
                error: (err) => {
                    throw new Error(err);
                }
            }));

        let urlSegments = this.activatedRoute.snapshot.url;
            if (!urlSegments.length) return;
            this.isEv = urlSegments[0].path === 'ev';
    }

    ngOnInit(): void {

    }

    setupMap() {
        //esriConfig.assetsPath = environment.baseURL + 'assets';
        esriConfig.apiKey = 'AAPKf9055458d6614262bc7acdea9b3048f8_xinTi3rzj_HaMo97CG-ZWmfsDgST8JrScxA6wDsJrhEZ36braYtGHNgPKvd4eQh';
        esriConfig.portalUrl = 'https://tredlite.maps.arcgis.com';

        // this.zone.runOutsideAngular(() => {
            // Initialize MapView and return an instance of MapView
            this.initializeMap().then(() => {
                this.map.add(this.graphicsLayer);
            });
        // });

        this.setUpSketchViewModel();
    }

    ngOnDestroy(): void {
        if (this.view) {
            // destroy the map view
            this.view.destroy();
        }
        this.subscriptions.forEach(x => x.unsubscribe());
    }

    initializeMap(): Promise<any> {
        let container = this.mapViewElement.nativeElement;
        this.map = new WebMap({
            portalItem: {
                id: this.webmapId,
            },
            basemap: 'streets-navigation-vector'
        });

        this.map.allLayers.on('change', event => {
            if (event.added.length > 0) {
                event.added.forEach((layer:any) => {
                    // ESRI map generates IDs when adding
                    this.mapDictionary[layer.title] = layer.id;
                    if (layer.title === this.parcelLayers[0]) {
                        this.minScale = layer.minScale === 0 ? this.NEIGHBORHOOD : layer.minScale;
                    }
                    if (this.evThemeLayers && this.evThemeLayers.find(x => x.title === layer.title))  {
                        layer.listMode = 'hide';
                        layer.visible = false;
                    }
                });
            }
            this.mapInteractionService.notifyMapLoaded();
        });

        let view = new MapView({
            container,
            map: this.map,
            zoom: 5,
            extent: this.extent,
            highlightOptions: {
                color: [255, 94, 0],
                haloOpacity: 0.9,
                fillOpacity: 0.2
            }
        });


        this.initializePinDrop();

        container.addEventListener('dragenter', e => {
            e.preventDefault();
        });
        container.addEventListener('dragover', e => {
            e.preventDefault();
        });
        container.addEventListener('drop', e => {
            e.preventDefault();
            var dropId = e.dataTransfer.getData('dropId');
            if (dropId === e.currentTarget.getAttribute('data-drop-id')) {
                const mapPoint = this.view.toMap(e);

                if (this.pinGraphic)
                    this.graphicsLayer.remove(this.pinGraphic)
                this.addPinToMapFromPoint(mapPoint);
                if (this.view.zoom < this.defaultZoomInLevel)
                    this.goTo(this.defaultZoomInLevel, mapPoint);
            }
        });

        // Add widgets here
        this.basemapToggle = new BasemapToggle({
            id: 'basemapToggle',
            view: view,
            nextBasemap: 'hybrid'
        });

        function defineActions(event) {
            var item = event.item;
            // An array of objects defining actions to place in the LayerList.
            // By making this array two-dimensional, you can separate similar
            // actions into separate groups with a breaking line.
            // Add actions here

            if (item.layer.type != 'group') {
                // don't show legend twice
                item.panel = {
                  content: 'legend',
                  open: true
                };
            }
        }

        this.layerList = new LayerList({
            id: 'layerList',
            view: view,
            listItemCreatedFunction: defineActions
        });

        let that = this;
        this.layerList.on('trigger-action', function (event) {
            // The layer visible in the view at the time of the trigger.
            var visibleLayer = event.item.layer;

            // Capture the action id.
            var id = event.action.id;
            if (id === 'symbology') {
                that.toggleNextClassBreakRenderer(event.item.layer.id);
            } else if (id === 'full-extent') {
                // if the full-extent action is triggered then navigate
                // to the full extent of the visible layer
                view.goTo(visibleLayer.fullExtent)
                    .catch(function (error) {
                        if (error.name != 'AbortError') {
                            console.error(error);
                        }
                    });
            } else if (id === 'information') {
                // if the information action is triggered, then
                // open the item details page of the service layer
                //window.open(visibleLayer.url);
            } //else if (id === 'increase-opacity') {
        });

        this.view = view;
        this.view.on("click", site => this.loadSite(site));

        this.view.on('layerview-create', result => {
            // Set EV point icon with clustering
            // let urlSegments = this.activatedRoute.snapshot.url;
            // if (!urlSegments.length) return;
            if (this.isEv && result.layer.title === this.parcelLayers[0]) {
                this.getEvPointFeatures(result.layer);
            }
        });

        watchUtils.whenOnce(view, 'ready')
            .then(result => {
                this.isMapReady = true
                this.toggleAllWidgets();

                let themes = document.getElementById('evThemeLayerWidget');
                this.view.ui.add(themes, 'bottom-left');
                themes.classList.remove('is-hidden');

                // Ensure the layer is loaded before accessing its fullExtent
                let item = view.map.layers.getItemAt(view.map.layers.length - 2) as FeatureLayer;
                if (item) {
                    item.outFields = ['*'];
                    return item.load();
                }
                return Promise.resolve();
            })
            .then(layer => {
                // Animate to the full extent of the layer if configured extent not set
                if (layer && this.extent && this.extent.xmin === 0 && this.extent.xmax == 0)
                    return view.goTo(layer.fullExtent);
                return Promise.resolve();
            })
            .catch(err => {
                console.log(err);
            });

        this.view.watch('scale', scale => {
            let tazLayer = this.map.findLayerById(this.mapDictionary[this.tazLayer]) as FeatureLayer;
            if (!tazLayer) return;
            let isTazVisible = this.checkMinScale(scale, tazLayer.minScale);
            if (isTazVisible !== this.isTazVisible) {
                this.mapInteractionService.notifyTazVisibilityChanged(isTazVisible);
                this.isTazVisible = isTazVisible;
            }

            if (this.parcelLayers.length === 0) return;
            let parcelLayer = this.map.findLayerById(this.mapDictionary[this.parcelLayers[0]]) as FeatureLayer;
            if (!parcelLayer) return;
            let isParcelVisible = this.checkMinScale(scale, parcelLayer.minScale);
            if (isParcelVisible !== this.isParcelVisible) {
                this.mapInteractionService.notifyParcelVisibilityChanged(isParcelVisible);
                this.isParcelVisible = isParcelVisible;
            }
        });

        watchUtils.whenTrue(view, 'stationary', () => {
            if (view) {
                // Debug - Use to get client's map extent
                // console.log(`Extent: ${view.extent.xmin} ${view.extent.xmax} ${view.extent.ymin} ${view.extent.ymax}`);
                // if (view.center)
                //     console.log(`Center: ${view.center.latitude} ${view.center.longitude}`);
                // console.log(`Zoom: ${view.zoom}`);

                if (this.isEv){
                    if (!this.mapDictionary || !this.parcelLayers) return;
                    if (!this.mapDictionary.length || !this.parcelLayers.length) return;

                    let evLayer = this.map.findLayerById(this.mapDictionary[this.parcelLayers[0]]) as FeatureLayer;
                    if (!evLayer) return;
                    if (view.zoom > this.maxZoomCluster){
                        if (!this.storedFeatureReduction)
                            this.storedFeatureReduction = evLayer.featureReduction;
                        evLayer.featureReduction = null;
                    } else {
                        if (!evLayer.featureReduction) evLayer.featureReduction = this.storedFeatureReduction;
                    }
                }
            }
        });

        return this.view.when();
    }

    loadSite(event: any) {
        if (!this.isEv) return;
        this.removeAllHighlights(false);
        var screenPoint = {x: event.x, y:event.y};

        this.view.hitTest(screenPoint)
        .then((hitTestResult) => {
            let result = hitTestResult.results[0];
            if (result.type !== 'graphic') return;
            if (result.layer.type !== 'graphics' && result.layer.type !== 'feature') return;

            let layerView = this.view.layerViews.find(x => x.layer.id === result.layer.id) as FeatureLayerView;
            if (layerView) {
                this.highlights.push({
                    graphics: layerView.highlight(result.graphic),
                    features: [result.graphic],
                    layerId: result.layer.id,
                    geometry: result.graphic.geometry,
                    geometryType: 'point'
                });
            }

            if (result.graphic.attributes && result.graphic.attributes.KHID) {
                this._evLogicService.getSiteById(result.graphic.attributes.KHID);
                this.themeLayerService.setCurrentSiteThemeLayerData(result.graphic.attributes.KHID);
                const evLayer = this.map.findLayerById(this.mapDictionary[this.parcelLayers[0]]) as FeatureLayer;
                const khid = result.graphic.attributes.KHID;
                evLayer.queryFeatures({
                    where: `${this.KHID} = ${result.graphic.attributes.KHID}`,
                    returnGeometry: true,
                }).then(queryResult => {
                    if (!queryResult.features.length) return;
                    const feature = queryResult.features[0];
                    this.getSiteDataForAllThemeLayers(khid, feature.geometry as Point);
                });

            } else {
                this.selectedPinPoint = result.mapPoint;
                this.getTazFromSelection({hasPriority: false});
                this.getSiteDataForAllThemeLayers(-1, result.mapPoint);
            }
        })
        .catch((error) => {
            console.log(error)
        })
    }

    checkMinScale(mapScale: number, layerMinScale: number) : boolean {
        let layerScale = layerMinScale === 0 ? this.minScale : layerMinScale;
        return mapScale <= layerScale;
    }

    goTo(zoom: number, point: Point): Promise<any> {
        return this.view.goTo({
            target: point,
            zoom: zoom
        }, { duration: 500, animate: true, easing: 'ease-in-out' });
    }

    toggleAllWidgets() {
        this.toggleWidget('layerList', this.layerList, 'top-right');
        this.toggleWidget('basemapToggle', this.basemapToggle, 'bottom-left');
    }

    toggleWidget(id: string, widget: any, addPosition: string) {
        if (this.view.ui.find(id))
            this.view.ui.remove(widget)
        else
            this.view.ui.add(widget, addPosition);
    }

    setUpSketchViewModel() {
        this.sketchViewModel = new SketchViewModel({
            view: this.view,
            layer: this.graphicsLayer,
            pointSymbol: {
                type: 'simple-marker',
                color: [255, 255, 255, 0],
                size: '1px',
                outline: {
                    color: 'gray',
                    width: 0
                }
            }
        });
        this.sketchViewModel.on('create', event => {
            if (event.state === 'complete') {
                this.graphicsLayer.remove(event.graphic);
                this.selectFeatures(event.graphic.geometry);
                this.mapInteractionService.completeSelection();
            }
        })
    }

    prepareForDraw() {
        this.view.popup.close();
        this.graphicsLayer.removeAll();
    }

    selectByPolygon() {
        this.prepareForDraw();
        this.sketchViewModel.create('polygon');
    }

    selectByRectangle() {
        this.prepareForDraw();
        this.sketchViewModel.create('rectangle');
    }

    selectByPoint() {
        this.prepareForDraw();
        this.sketchViewModel.create('point');
    }

    selectFeatures(geometry: Geometry) {
        this.parcelLayers.forEach(layerName => {
            this.selectFeaturesByLayer(this.mapDictionary[layerName], geometry);
        })
    }

    selectFeaturesByLayer(id: string, geometry: Geometry) {
        let query = {
            geometry: geometry,
            outFields: ['*'],
            returnGeometry: true
        };

        let parcelLayer = this.map.findLayerById(id) as FeatureLayer;
        parcelLayer.queryFeatures(query).then(result => {
            let layerView = this.view.layerViews.find(x => x.layer.id === id) as FeatureLayerView;
            if (layerView && result.features.length) {
                this.highlights.push({
                    graphics: layerView.highlight(result.features),
                    features: result.features,
                    layerId: id,
                    geometry: geometry,
                    geometryType: 'polygon'
                });
                this.sendHighlightUpdate();
            }
            if (this.highlightsToDo) {
                var resizeHandle = this.view.on('resize', $event => {
                    resizeHandle.remove();
                    setTimeout(() => {
                        this.centerOnSelection(this.defaultZoomInLevel + 1);
                    }, 1000);
                });
            }


        });
    }

    lastFilename: string;
    generateFeatureCollection(fileName) {
        var name = fileName.split('.');
        // Chrome and IE add c:\fakepath to the value - we need to remove it
        // see this link for more info: http://davidwalsh.name/fakepath
        name = name[0].replace('c:\\fakepath\\', '');
        this.lastFilename = name;

        let uploadStatus = document.getElementById('upload-status');
        uploadStatus.classList.remove('is-danger');
        uploadStatus.innerHTML = '<b>Loading </b>' + name;

        // define the input params for generate see the rest doc for details
        // https://developers.arcgis.com/rest/users-groups-and-items/generate.htm
        var params = {
            'name': name,
            'targetSR': this.view.spatialReference,
            'maxRecordCount': 1000,
            'enforceInputFileSizeLimit': true,
            'enforceOutputJsonSizeLimit': true,
        };

        // generalize features to 10 meters for better performance
        params['generalize'] = true;
        params['maxAllowableOffset'] = 10;
        params['reducePrecision'] = true;
        params['numberOfDigitsAfterDecimal'] = 0;

        var myContent = {
            'filetype': 'shapefile',
            'publishParameters': JSON.stringify(params),
            'f': 'json',
        };

        let boundError = this.errorHandler.bind(this);
        let boundAddShapefileToMap = this.addShapefileToMap.bind(this);
        let formElement = document.getElementById('uploadForm') as HTMLFormElement;
        // use the REST generate operation to generate a feature collection from the zipped shapefile
        Request('https://www.arcgis.com/sharing/rest/content/features/generate', {
            query: myContent,
            body: formElement,
            responseType: 'json'
        })
            .then(response => {
                var layerName = response.data.featureCollection.layers[0].layerDefinition.name;
                document.getElementById('upload-status').innerHTML = '<b>Loaded: </b>' + layerName;
                boundAddShapefileToMap(response.data.featureCollection);
            })
            .catch(boundError);
    }

    errorHandler(error) {
        console.log(error.message)
        this.notificationService.showNotification(NotificationType.Error, 'Shapefile uploading failed.', NotificationCloseType.Self);
        document.getElementById('upload-status').innerHTML = '';
    }

    addShapefileToMap(featureCollection) {
        // add the shapefile to the map and zoom to the feature collection extent
        // if you want to persist the feature collection when you reload browser, you could store the
        // collection in local storage by serializing the layer using featureLayer.toJson()
        // see the 'Feature Collection in Local Storage' sample for an example of how to work with local storage
        var sourceGraphics = [];
        var layers = featureCollection.layers.map(layer => {
            var graphics = layer.featureSet.features.map(feature => {
                return Graphic.fromJSON(feature);
            })
            sourceGraphics = sourceGraphics.concat(graphics);
            var featureLayer = new FeatureLayer({
                objectIdField: 'FID',
                source: graphics,
                fields: layer.layerDefinition.fields.map(field => {
                    return Field.fromJSON(field);
                })
            });
            featureLayer.title = layer.layerDefinition.name;
            this.shapefileLayerId = featureLayer.id;
            //let layerJson = JSON.stringify(featureLayer);
            return featureLayer;
            // associate the feature with the popup on click to enable highlight and zoom to
        });
        this.map.addMany(layers);
        this.view.goTo(sourceGraphics)
            .catch(error => {
                if (error.name != 'AbortError') {
                    console.error(error);
                }
            });

        document.getElementById('upload-status').innerHTML = '';
    }

    getSelectionAsUnion() : Geometry {
        let polygons = [];
        this.highlights.filter(h => h.geometryType === 'polygon').forEach(highlight => {
            highlight.features.forEach((feature: Graphic) => {
                let polygon = feature.geometry as Polygon;
                polygons.push(polygon);
            });
        });
        if (polygons.length) {
            let union = geometryEngine.union(polygons);
            return union;
        } else return null;
    }

    async getShapefileAsUnion() : Promise<Geometry> {
        let polygons = [];
        let shapefileLayer = this.map.findLayerById(this.shapefileLayerId) as FeatureLayer;
        let query = {
            outFields: ['*'],
            returnGeometry: true
        };

        let result = await shapefileLayer.queryFeatures(query);
        result.features.forEach((feature: Graphic) => {
            let polygon = feature.geometry as Polygon;
            polygons.push(polygon);
        });
        let union = geometryEngine.union(polygons);
        return union;
    }

    getTazFromSelection(options: {hasPriority: boolean}) {
        let unionArea = 0.0001;
        let union = this.getSelectionAsUnion();
        if (union)
            unionArea = geometryEngine.geodesicArea(union as Polygon, 'square-meters')
        let geometry = union ?? this.selectedPinPoint;
        this.getTazData(geometry);
        if (options.hasPriority){
            this.priorityLayers.forEach(layerInfo =>  {
                let layer = this.map.findLayerById(this.mapDictionary[layerInfo.title]) as FeatureLayer;
                let query = {
                    geometry: union,
                    outFields: ['*'],
                    returnGeometry: true
                };

                layer.queryFeatures(query).then(result => {
                    let totalArea = 0;
                    result.features.forEach((graphic: Graphic) => {
                        let geometry = geometryEngine.intersect(union, graphic.geometry);
                        if (geometry !== null) {
                            let area = geometryEngine.geodesicArea(geometry as Polygon, 'square-meters');
                            totalArea += area;
                        }
                    });

                    let coveragePercent = Math.round(totalArea / unionArea * 100);
                    if (coveragePercent > 100)
                        coveragePercent = 100;
                    this.mapInteractionService.updatePriorityLayerCoverage(layerInfo.title, coveragePercent);
                }).catch(reason => {
                    console.log(reason);
                });
            });
        }
    }

    getTazFromShapefile() {
        this.getShapefileAsUnion().then(union => {
            this.getTazData(union);
        });
    }

    getTazData(union: Geometry) {
        let query = new Query({
            geometry: union,
            outFields: [this.tazIDField],
            returnGeometry: true,
            spatialRelationship: 'intersects'
        });
        let blockQuery = new Query({
            geometry: union,
            outFields: ['GEOID'],
            returnGeometry: true,
            spatialRelationship: 'intersects'
        });

        let centroid: Point = null;
        switch (union.type) {
            case 'polygon':
                const polygon = union as Polygon;
                centroid = polygon.centroid;
                break;
            case 'point':
                centroid = union as Point;
                break;
        }

        this.esriLocationService.getLocationFromCoordinates(centroid, null).then(result => {
            let county = '', address = '', city = '', state = '', zip = '';
            if (result !== 'Error') {
                county = result['Subregion'];
                address = result['Address'];
                city = result['City'];
                state = result['RegionAbbr'];
                zip = result['Postal'];
            }

            let tazLayer = this.map.findLayerById(this.mapDictionary[this.tazLayer]) as FeatureLayer;
            let tazData = [];
            const blockLayer = new FeatureLayer({url: this.CENSUS_BLOCK_URL});

            let promises = [{
                queryFunction: (q) => tazLayer.queryFeatures(q),
                query: query
            }];

            if (this.isEv) {
                tazLayer = new FeatureLayer({url: this.CENSUS_BLOCKGROUP_URL});
                promises.push({
                    queryFunction: (q) => blockLayer.queryFeatures(q),
                    query: blockQuery
                });
            }
            Promise.allSettled<__esri.FeatureSet>(promises.map(p => p.queryFunction(p.query))).then(pResults => {
                pResults.forEach((result, i) => {
                    if (result.status !== 'fulfilled') return;
                    switch(i) {
                        case 0:
                            let totalArea = 0;
                            result.value.features.forEach((taz: Graphic) => {
                                let geometry = geometryEngine.intersect(union, taz.geometry);
                                if (geometry !== null) {
                                    let area = geometryEngine.geodesicArea(geometry as Polygon, 'square-meters');
                                    totalArea += area;
                                    tazData.push({
                                        id: parseInt(taz.getAttribute(this.tazIDField)),
                                        parcelArea: area,
                                        percentage: 0,
                                        county: county,
                                        address: address,
                                        city: city,
                                        state: state,
                                        zip: zip
                                    });
                                }
                            });

                            tazData.forEach(t => {
                                t.percentage = Math.round((t.parcelArea + Number.EPSILON) * 10000.0 / totalArea) / 10000;
                            });

                            if (this.isEv)
                                tazLayer.destroy();

                            break;
                        case 1:
                            if (!result.value.features) return;
                            const block = result.value.features[0] as Graphic;
                            if (tazData.length > 0)
                                tazData[0].blockId = parseFloat(block.getAttribute('GEOID'));
                            blockLayer.destroy();
                            break;
                    }
                });
                this.mapInteractionService.setTazFromSelection(tazData);
            });
        });
    }

    centerOnSelection(overrideZoom: number = 0) {
        let union = this.getSelectionAsUnion();
        let polygon = union as Polygon;
        let zoom = overrideZoom > 0 ? overrideZoom : this.view.zoom;
        this.goTo(zoom, polygon.centroid).catch(reason => {
            console.log(reason);
        });
    }

    sendHighlightUpdate() {
        this.mapInteractionService.updateHighlights(this.getCurrentHighlights());
    }

    getCurrentHighlights() {
        return this.highlights.map(h => {
            return {layerId: h.layerId, geometry: JSON.stringify(h.geometry)}
        });
    }

    toggleNextClassBreakRenderer(layerId: string) {
        let mapLayer = this.map.findLayerById(layerId) as FeatureLayer;
        let collection = this.rendererDictionary[layerId] as RendererCollection;
        if (!mapLayer || !collection) return;
        let rendererInfo = collection.getNext() as RendererInfo;
        mapLayer.renderer = rendererInfo.classBreaksRenderer ? rendererInfo.classBreaksRenderer
            : rendererJsonUtils.fromJSON(rendererInfo.rendererJson);
    }

    enableClassBreakRenderer(layerId: string, index: number) {
        let mapLayer = this.map.findLayerById(layerId) as FeatureLayer;
        let collection = this.rendererDictionary[layerId] as RendererCollection;
        if (!mapLayer || !collection) return;
        let rendererInfo = collection.getAt(index) as RendererInfo;
        mapLayer.renderer = rendererInfo.classBreaksRenderer ? rendererInfo.classBreaksRenderer
            : rendererJsonUtils.fromJSON(rendererInfo.rendererJson);
        collection.setCurrentIndex(index);
    }

    addLayerClassBreaks(layer:string, valueExpression: string, classBreakInfo: ClassBreakInfo) {
        let mapLayer = this.map.findLayerById(this.mapDictionary[layer]) as FeatureLayer;
        if (!mapLayer) return;
        if (!mapLayer.renderer) return;

        // New renderer collection
        if (!this.rendererDictionary[mapLayer.id]) {
            let collection = new RendererCollection();
            // Save default renderer first
            let rendererInfo = new RendererInfo();
            rendererInfo.rendererJson = mapLayer.renderer.toJSON();
            collection.add(rendererInfo);
            this.rendererDictionary[mapLayer.id] = collection;
        }

        let infos = [];
        classBreakInfo.classBreaks.forEach(classBreak => {
            infos.push({
                minValue: classBreak.min,
                maxValue: classBreak.max,
                symbol: new SimpleFillSymbol({
                    style: 'solid',
                    color: classBreak.color,
                }),
                label: classBreak.label
            });
        });

        let renderer = {
            type: 'class-breaks',
            valueExpression: valueExpression,
            legendOptions: {
                title: classBreakInfo.title
            },
            classBreakInfos: infos
        };

        let classBreakRenderInfo = new RendererInfo();
        classBreakRenderInfo.classBreaksRenderer = renderer;
        this.rendererDictionary[mapLayer.id].add(classBreakRenderInfo);
    }

    getEvPointFeatures(layer: any) {
        layer.queryFeatures({
            where: "1=1",
            returnGeometry: true,
            outFields: ['*']
        }).then(featureSet => {
            let featureLayer = new FeatureLayer({
                source: featureSet.features,
                fields: [...layer.fields, {
                    name: 'filter',
                    alias: 'Filter',
                    type: 'integer',
                    nullable: false,
                    defaultValue: 0
                }],
                renderer: layer.renderer,
                spatialReference: {
                    wkid: 102100
                },
                outFields: ['*'],
                minScale: layer.minScale,
                popupEnabled: false
            });
            // ESRI bug: need to add a space to title otherwise being set to variable causes infinite exceptions for some reason
            featureLayer.title = this.parcelLayers[0] + ' ';
            return featureLayer.load();
        }).then(clientLayer => {
            this.map.add(clientLayer)
            this.mapDictionary[this.parcelLayers[0]] = clientLayer.id;
            this.map.remove(layer);
            clientLayer.queryFeatures({outFields: ['*']}).then(result => {
                result.features.forEach(feature => {
                    feature.attributes['filter'] = FilterResultType.Included;
                });
                let edits = { updateFeatures: result.features};
                clientLayer.applyEdits(edits).then(() => {
                    this.setEvPointVisuals(clientLayer);
                });
            });
        }).catch(e => {
            debugger;
        });
    }

    setEvPointVisuals(layer: any) {
        // layer.renderer overridden by cluster renderer
        layer.featureReduction = {
            fields: [
                new AggregateField(
                {
                    name: "SUM_filter",
                    onStatisticField: "filter",
                    statisticType: "sum"
                }),
                new AggregateField(
                {
                    name: "SUM_remainder",
                    alias: 'Key',
                    statisticType: "sum",
                    onStatisticExpression: {
                        returnType: 'number',
                        expression: 'if ($feature.filter == 0) return 1; else return 0;'
                    }
                })
            ],

            type: 'cluster',
            clusterMinSize: '24px',
            clusterMaxSize: '60px',
            clusterRadius: '100px',
            // defines the label within each cluster
            labelingInfo: [
                {
                    deconflictionStrategy: 'none',
                    labelExpressionInfo: {
                        expression: `
                            var remainder = $feature.cluster_count - $feature.SUM_filter;
                            var label = Text(remainder, '#,###');
                            if(remainder != $feature.cluster_count){
                                label += \`\n(\${$feature.cluster_count})\`
                            }
                            return label;
                        `
                    },
                    symbol: {
                        type: 'text',
                        color: 'white',
                        font: {
                            family: 'Noto Sans',
                            size: '12px',
                            weight: 'bold'
                        }
                      },
                    labelPlacement: 'center-center'
                }
            ],
            renderer: {
                type: "class-breaks",
                field: "SUM_remainder",
                classBreakInfos: [
                    {
                        minValue: 0,
                        maxValue: 0,
                        label: 'Excluded Sites (Score = 0-4)',
                        symbol: {
                            type: "simple-marker",
                            color: '#abacb2',
                            style: 'circle',
                            outline: {
                                width: 2,
                                color: [171, 172, 178, 0.5],
                            },
                        }
                    },
                    {
                        minValue: 1,
                        maxValue: 9999999,
                        label: 'Included Sites (Score = 5)',
                        symbol: {
                            type: "simple-marker",
                            color: '#42859E',
                            style: 'circle',
                            outline: {
                                width: 2,
                                color: [66, 133, 158, 0.5],
                            },
                        }
                    }
                ]
            }

            // information to display when the user clicks a cluster
            // popupTemplate: {
            //     title: 'EV Site Cluster',
            //     content: "This area has <b>{cluster_count}</b> '{aggregateCount}' EV sites.",
            //     fieldInfos: [{
            //         fieldName: 'cluster_count',
            //         format: {
            //             places: 0,
            //             digitSeparator: true
            //         }
            //     }, {fieldName: 'aggregateCount', format: {places: 0, digitSeparator: true}}]
            // }
        };
    }

    filterEvLayer(includedIds: number[], filterType: string, goToExtent: boolean, hasMatches: boolean) {
        let isNoAttributeFilter = filterType === 'attribute' && includedIds.length === 1 && includedIds[0] === 0;
        if (isNoAttributeFilter)
            includedIds = [];

        switch(filterType) {
            case 'attribute':
                this.attributeEvIds.clear();
                this.hasAttributeMatches = hasMatches;
                break;
            case 'performance':
                this.performanceEvIds.clear();
            break;
        }

        if (isNoAttributeFilter) this._evLogicService.resetFilteredSitesAndExcelExportSites();

        if (isNoAttributeFilter && this.performanceEvIds.size > 0) {
            includedIds = Array.from(this._evLogicService.model.sites.keys());
            this.performanceEvIds.clear();
            this.notificationService.showNotification(NotificationType.Warning, 'Attribute filters changed. Please re-evaluate Performance filters.', NotificationCloseType.Self);
        }

        let setToAdd = this.attributeEvIds;
        if (filterType === 'performance')
            setToAdd = this.performanceEvIds;

        includedIds.forEach(id => {
            setToAdd.add(id)
        });
        let isReset = (this.attributeEvIds.size + this.performanceEvIds.size === 0) && hasMatches;

        let localIncludedIds: number[] = [];
        // if both filters are being used, have to combine
        if (this.attributeEvIds.size > 0 && (this.performanceEvIds.size > 0 || filterType === 'performance')) {
            let intersection = new Set([...this.performanceEvIds].filter((x) => this.attributeEvIds.has(x)));
            localIncludedIds = [...intersection];
        } else if (this.attributeEvIds.size > 0 && this.performanceEvIds.size === 0) {
            localIncludedIds = [...this.attributeEvIds];
        } else if (this.attributeEvIds.size === 0 && this.performanceEvIds.size > 0)
            localIncludedIds = [...this.performanceEvIds];

        if (filterType === 'performance' && !this.hasAttributeMatches)
            localIncludedIds = [];

        this.showEvStats();
        let evLayer = this.map.findLayerById(this.mapDictionary[this.parcelLayers[0]]) as FeatureLayer;
        let allSitesTotal = 0;
        // Reset status
        evLayer.queryFeatures()
        .then(result => {
            allSitesTotal = result.features.length;
            if (this.evStats.total === 0) {
                this.evStats.total = result.features.length;
                this.evStats.remainder = this.evStats.total;
            }

            result.features.forEach(feature => {
                feature.attributes['filter'] = FilterResultType.Included;
            });

            return evLayer.applyEdits({ updateFeatures: result.features });
        })
        .then(() => {
            if (isReset) {
                this.evStats.total = allSitesTotal;
                this.evStats.remainder = allSitesTotal;
                return Promise.resolve();
            }
            // Apply filters
            let isEmpty = localIncludedIds.length === 0;
            const whereClause = isEmpty ? null : `${this.KHID} NOT IN (${localIncludedIds.join(',')})`;

            evLayer.queryFeatures({
                where: whereClause
            }).then(result => {
                this.evStats.remainder = this.evStats.total - result.features.length;
                if (this.evStats.remainder < 0)
                    this.evStats.remainder = 0;
                result.features.forEach(feature => {
                    feature.attributes['filter'] = FilterResultType.Excluded;
                });
                let edits = { updateFeatures: result.features };
                evLayer.applyEdits(edits)
                .then(() => { evLayer.refresh(); })
                .catch(e => {
                    console.log(e);
                });
            });
            return Promise.resolve();
        });


        if (!goToExtent) return;

        evLayer.queryExtent({
            where: `${this.KHID} IN (${localIncludedIds.join(',')})`
        }).then(response => {
            this.view.goTo(response.extent);
        });
    }

    resetEvLayer() {
        this.attributeEvIds.clear();
        this.performanceEvIds.clear();
        let evLayer = this.map.findLayerById(this.mapDictionary[this.parcelLayers[0]]) as FeatureLayer;

        // Reset status
        evLayer.queryFeatures()
        .then(result => {
            this.evStats.total = result.features.length;
            this.evStats.remainder = this.evStats.total;
            result.features.forEach(feature => {
                feature.attributes['filter'] = FilterResultType.Included;
            });
            if (!evLayer.editingEnabled) return;
            evLayer.applyEdits({ updateFeatures: result.features });
        });
    }

    showEvStats() {
        let component = this.view.ui.find('evStats');
        if (component) return;

        let stats = document.getElementById('evStats');
        this.view.ui.add(stats, 'bottom-right');
        stats.classList.remove('is-hidden');
    }

    onEvThemeLayerClick(clickEvent, themeLayer: ThemeLayer) {
        const layerName = themeLayer.title;
        const layerLabel = themeLayer.label;
        let layer = this.map.findLayerById(this.mapDictionary[layerName]);
        if (!layer) {
            let state = this.agencyName;
            if (isGuid(state)) state = 'California';
            const blockGroupName = `${state} Block Group`;
            if (layerName.replace(' Theme', '') === blockGroupName) {
                layer = this.map.findLayerById(this.mapDictionary[blockGroupName]);
                if (!layer) return;

                // disable opposite layer, make active/inactive for class
                const collection = this.rendererDictionary[layer.id] as RendererCollection;
                if (collection) {
                    const currentIndex = collection.getCurrentIndex();
                    let nextIndex = layerLabel === 'Residential VMT/Capita' ? 1 : 2;

                    const activeButtons = document.getElementsByClassName('is-renderer-active');
                    for (let button of activeButtons) {
                        let element = button as HTMLElement;
                        element.classList.remove('is-active');
                        element.classList.remove('is-renderer-active');
                        element.blur();
                    }

                    if (nextIndex === currentIndex)
                        nextIndex = 0;
                    else  {
                        clickEvent.currentTarget.classList.add('is-active');
                        clickEvent.currentTarget.classList.add('is-renderer-active');
                    }

                    this.enableClassBreakRenderer(layer.id, nextIndex);
                }
            }
            return;
        }
        layer.listMode = layer.listMode === 'show' ? 'hide' : 'show';
        layer.visible = !layer.visible;
        if (layer.visible)
            clickEvent.currentTarget.classList.add('is-active');
        else {
            clickEvent.currentTarget.classList.remove('is-active');
            clickEvent.currentTarget.blur();
        }
    }

    onThemeWidgetMove(e) {
        const themeButtons = document.getElementsByClassName('btn-ev-theme-icon');
        for (let button of themeButtons) {
            let element = button as HTMLElement;
            element.blur();
        }
    }

    toggleEvThemeExpander() {
        let expander = document.getElementsByClassName('icon-expander')[0];
        let div = document.getElementById('evThemeList');
        if (expander.classList.contains('is-collapsed')) {
            expander.classList.remove('is-collapsed');
            expander.classList.add('is-expanded');
            div.classList.remove('is-hidden');
        } else {
            expander.classList.add('is-collapsed');
            expander.classList.remove('is-expanded');
            div.classList.add('is-hidden');
        }
    }

    exportEvSiteDataToExcel(): void {
        this.initiateExcelExportLoadingScreen();
        this.fetchThemeLayerDataForExport();
    }

    initiateExcelExportLoadingScreen(): void {
        this.loaderText = 'Gathering data for export...';
        this._excelExportService.initiateExcelExportLoadingScreen();
    }

    initializePinDrop() {
        const pin = document.getElementById('pin');
        if (!pin) return;
        pin.removeEventListener('dragstart', this.drag);
        pin.addEventListener("dragstart", this.drag);
    }

    drag(ev) {
        const target = ev.target as HTMLInputElement;
        ev.dataTransfer.setData("dropId", target.getAttribute('data-drop-id'));
        ev.dataTransfer.setData("id",target.id);
    }

    addPinToMapFromPoint(point: Point) {
        this.pinGraphic = new Graphic({ geometry: point, symbol: this.pinSymbol });
        this.graphicsLayer.add(this.pinGraphic);
    }

    removeAllHighlights(deletePin: boolean = true) {
        this.highlights.forEach(highlight => {
            highlight.features = [];
            highlight.layerId = null;
            highlight.geometry = null;
            highlight.graphics.remove();
        })
        this.highlights = [];
        this.sendHighlightUpdate();

        if (deletePin)
            this.graphicsLayer.remove(this.pinGraphic);
    }

    async getSiteDataForAllThemeLayers(siteId: number, point: Point) {
        // Try to send theme layer data from cache
        let cachedDataExists = this.themeLayerService.trySendSiteDataForAllLayers(siteId);
        if (cachedDataExists) return;

        // Doesn't exist in cache, do map operations and update ThemeLayerService
        let promises = [];
        this.evThemeLayers
            .forEach(themeLayer => {
                const mapLayer = this.map.findLayerById(this.mapDictionary[themeLayer.title]) as FeatureLayer;
                let query = {
                    geometry: point,
                    outFields: ['*'],
                    returnGeometry: false
                };
                if (mapLayer.type != 'feature') {
                    // group type
                    const groupLayer = this.map.findLayerById(this.mapDictionary[themeLayer.title]) as GroupLayer;
                    groupLayer.layers.forEach((layer: FeatureLayer) => {
                        promises.push({
                            queryFunction: (q) => layer.queryFeatures(q),
                            query: query,
                            layerTitle: layer.title
                        });
                    });
                    return;
                }
                if (themeLayer.relationshipType === LayerRelationshipType.Proximity) {
                    query['distance'] = this.DISTANCE;
                    query['units'] = this.UNITS;
                    query.returnGeometry = true;
                }
                promises.push({
                    queryFunction: (q) => mapLayer.queryFeatures(q),
                    query: query,
                    layerTitle: mapLayer.title
                });
            });

        await Promise.allSettled<__esri.FeatureSet>(promises.map(p => p.queryFunction(p.query))).then(pResults => {
            pResults.forEach((result, i) => {
                if (result.status !== 'fulfilled') return;

                const resultLayerTitle = promises[i].layerTitle;
                let layer = this.evThemeLayers.find(l => l.title === resultLayerTitle);
                if (!layer)
                    layer = this.evThemeLayers[i];
                if (layer && (!result.value.features || result.value.features.length === 0)) {

                    this.themeLayerService.updateLayerData(siteId, layer, '');
                    return;
                };

                if(layer && layer.relationshipType === LayerRelationshipType.Proximity) {
                    let minDistance = Infinity;
                    result.value.features.forEach((feature: Graphic) => {
                        const distance = geometryEngine.distance(point, feature.geometry, this.UNITS);
                        if (distance < minDistance) {
                            minDistance = distance;
                        }
                    });
                    this.themeLayerService.updateLayerData(siteId, layer, `${minDistance.toFixed(2)} ${this.UNITS}`);
                    return;
                }

                result.value.features.forEach((feature: Graphic) => {
                    const featureLayerTitle = feature.layer.title;
                    let themeLayer = this.evThemeLayers.find(l => l.title === featureLayerTitle);
                    const parent = feature.layer['parent'];
                    if (!themeLayer && parent) {
                        themeLayer = this.evThemeLayers.find(l => l.title === parent.title);
                    }
                    if (!themeLayer) return;
                    const siteValue = feature.getAttribute(themeLayer.valueField);
                    this.themeLayerService.updateLayerData(siteId, themeLayer, siteValue);
                    if (themeLayer.title === 'Multi-Family Housing')
                        this.themeLayerService.updateLayerAttributes(siteId, themeLayer, (feature.layer as FeatureLayer).fields, feature.attributes);
                });
            });

            // Send
            this.themeLayerService.trySendSiteDataForAllLayers(siteId);
            if (siteId === -1)
                this.themeLayerService.removePinDropFromCache();
        });
    }

    fetchThemeLayerDataForExport() {
        const evLayer = this.map.findLayerById(this.mapDictionary[this.parcelLayers[0]]) as FeatureLayer;
        evLayer.queryFeatures({
            // Use filter field to only get included sites and their site IDs
            where: 'filter = 0',
            returnGeometry: true,
        }).then(async result => {
            let siteIds = [];
            for (const feature of result.features) {
                const siteId = feature.attributes[this.KHID];
                siteIds.push(siteId);
                await this.getSiteDataForAllThemeLayers(siteId, feature.geometry as Point);
            }
            // Let subscribers know that data is ready
            this.themeLayerService.postAllThemeLayerDataExport(siteIds);
        });
    }
}
