<div id="site-fsv-grid">
    <div class="grid-container">
        <div class="item header1 grey"><strong>Fuel Sales Volume (FSV)</strong></div>
        <div class="item grey">
            <strong>Site Value</strong>
        </div>
        <div class="item grey"><strong>Averages</strong></div>
        <div class="item grey"><strong>DMA</strong></div>
        <div class="item grey"><strong>Statewide</strong></div>
        <div class="data-item first">
            <p>Annual: 2022 (cubic gallons)</p>
        </div>
        <div class="data-item second"><p>{{this.fsv ? (this.fsv | number) : "-"}}</p></div>
        <div class="data-item third"><p>{{this.fsvDmaAvg | number}}</p></div>
        <div class="data-item fourth"><p>{{this.fsvStateAvg | number}}</p></div>
    </div>
</div>


