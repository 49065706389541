export enum LayerRelationshipType {
    Intersect = 1,
    Proximity,
}

// DTO from API
export class ThemeLayer {
    public title: string;
    public relationshipType: LayerRelationshipType;
    public label: string;
    public iconClass: string;
    public iconClass2?: string;
    public matIconText?: string;
    public valueField?: string;
    public imgUrl?: string;
    public detailsText?: string;
    public tooltipText?: string;
    public isGrouped: boolean;
}

export class ThemeLayerInfo {
    public layerName: string;
    public layerLabel: string;
    public detailsText: string;
    public tooltipText: string;
}

export class ThemeLayerControlIconInfo {
    public iconClass: string;
    public iconClass2?: string;
    public matIconText?: string;
    public imgUrl?: string;
}

export class SiteThemeLayerInfo {
    public siteId: number;
    public layer: ThemeLayerInfo;
    public siteValue: string;
    public icon: ThemeLayerControlIconInfo;
    public attributes: any;
    public fields: any;
}
