import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SitePhotoDataService {

    constructor(
        private _httpClient: HttpClient,
    ) { }

    getSitePhotoUrlsBySiteId(siteId: number): Observable<any> {
        const url = `${environment.api.baseURL}/Sites/GetSitePhotoUrlsBySiteId`
        return this._httpClient.post(url, siteId);
    }

    downloadFile(url: string): Observable<any> {
        return this._httpClient.get(url, { responseType: 'blob' })
      }
}
