import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app/app.constants';
import { MapInteractionService } from 'src/app/services/shared/map-interaction.service';
import { NavigationService } from 'src/app/services/shared/navigation.service';

import { IAgencyConfig, ICity } from 'src/app/models/shared/agency-data.model';
import { EvScreenComponent } from '../ev-screen/ev-screen.component';
import { AuthenticationService } from 'src/app/services/shared/authentication.service';

@Component({
    selector: 'app-alt-fuel',
    templateUrl: './alt-fuel.component.html',
    styleUrls: ['./alt-fuel.component.scss']
})
export class AltFuelComponent implements OnInit {

    @ViewChild(EvScreenComponent) evScreener: EvScreenComponent;
    clientSite: string;
    page: number = 0;

    constructor(private mapInteractionService: MapInteractionService,
        private navigationService: NavigationService, private router: Router,
        public constants: AppConstants, private _authenticationService: AuthenticationService ) {
        navigationService.appStarted$.subscribe(x => {
            this.onStartClicked();
        });
        navigationService.clientUpdated$.subscribe(clientSite => this.clientSite = clientSite);
    }

    ngOnInit(): void {

    }


    onStartClicked() {
        this.router.navigate(['ev', this.clientSite, 'screen'],
        {
            queryParams: {
                step: 1
            },
        });
        this.evScreener.onClearClick();

        if(this.evScreener.mapSource !== 1)
            this.evScreener.mapSource = 1;
    }

    onPageChange(page) {
        setTimeout(() => {
            this.page = page;
        }, 100);
    }
}
