import { Injectable } from '@angular/core';
import { FilterAttributeDataService } from '../data-services/filter-attribute-data.service';
import { FilterAttribute } from 'src/app/models/ev/filter.model';
import { FilterAttributeServiceModel } from './models/filter-attribute-logic-model';
import { EvSite } from 'src/app/models/ev/evSite.model';

@Injectable({
  providedIn: 'root'
})
export class FilterAttributeLogicService {
    public model: FilterAttributeServiceModel;

    constructor(
        private _filterAttributeDataService: FilterAttributeDataService,
        ) {
            this.initModel();
        }

    initModel(): void {
        if (this.model === undefined) {
            this.model = new FilterAttributeServiceModel();
        }
    }

    getDistinctAttributes() {
        this._filterAttributeDataService.getDistinctAttributes()
        .subscribe({
            next: (filterAttributes: FilterAttribute[]) => this.setFilterAttributes(this.formatFilterAttributes(filterAttributes)),
            error: (err) => console.log(err),
        });
    }

    setFilterAttributes(filterAttributes: FilterAttribute[]): void {
        this.model.filterAttributes = filterAttributes;
    }

    formatFilterAttributes(filterAttributes: FilterAttribute[]): FilterAttribute[] {
        if (filterAttributes === undefined) throw new Error("parameter cannot be undefined");
        return this.formatFilterAttributeDisplayNames(this.removeUnwantedCommonFilterAttributes(filterAttributes));
    }

    removeUnwantedCommonFilterAttributes(filterAttributes: FilterAttribute[]): FilterAttribute[] {
        return filterAttributes.filter(filterAttribute => !this.model.unwantedCommonFilterAttributes.has(filterAttribute.fieldName));
    }

    formatFilterAttributeDisplayNames(filterAttributes: FilterAttribute[]): FilterAttribute[] {
        if (filterAttributes.length === 0) return filterAttributes;
        filterAttributes.forEach(filterAttribute => {
            filterAttribute.displayName = this.capitalizeAndAddSpaces(filterAttribute.displayName);
            if (filterAttribute.displayName === "Zip") filterAttribute.displayName = "Zip Code";
            if (filterAttribute.fieldName) {
                filterAttribute.fieldName = this.firstLetterLowercase(filterAttribute.fieldName);
                if (filterAttribute.fieldName === "eV Network") filterAttribute.fieldName = "ev Network";
                if (this.model.lowerCaseAttributes.includes(filterAttribute.fieldName.toLowerCase())) filterAttribute.fieldName = filterAttribute.fieldName.toLowerCase();
            }
        })
        return filterAttributes;
    }

    addScoreFilterAttribute(filterAttribute: FilterAttribute[]): FilterAttribute[] {
        const scoreFilterAttribute: FilterAttribute = {fieldName: "finalScore", displayName: "Preset Score", fieldType: "number"};
        filterAttribute.push(scoreFilterAttribute);
        return filterAttribute.sort((a, b) => a.displayName.localeCompare(b.displayName));
    }

    firstLetterLowercase(str: string): string {
        if (str === undefined) throw new Error("parameter cannot be undefined");
        return str.charAt(0).toLowerCase() + str.slice(1);
    }

    capitalizeAndAddSpaces(value: string): string {
        if (value === undefined) throw new Error("parameter cannot be undefined");
        let shouldBeAllUppercase: string[] = ['mrn', 'dma', 'qsr'];

        if (shouldBeAllUppercase.includes(value)) return value.toUpperCase();

        value = value.charAt(0).toUpperCase() + value.slice(1);
        value = value.replace(/([a-z])([A-Z])/g, '$1 $2');
        return value;
    }

    configureFilterAttributes(sites: Map<number, EvSite>): void {
        if (sites === undefined) throw new Error("parameter cannot be undefined");
        this.getUniquePropertyValues(sites);
    }

    getUniquePropertyValues(sites: Map<number, EvSite>): void {
        const unwantedCommonFilterAttributes = this.model.unwantedCommonFilterAttributes;
        sites.forEach((site, siteId) => {
            for(let propertyKey in site) {
                if (unwantedCommonFilterAttributes.has(propertyKey)) continue;
                if (site.hasOwnProperty(propertyKey)) {
                    let propertyValue = site[propertyKey].toString();
                    this.addToAttributeValueMap(propertyKey, propertyValue);
                }
            }
            for (const [propertyKey, siteProperty] of site.additionalProperties.entries()) {
                if (['finalScore','adjustedScore'].includes(propertyKey)) continue;
                let propertyValue = siteProperty.propertyValue.toString();
                this.addToAttributeValueMap(propertyKey, propertyValue);
            };
        });
        this.model.scoreDescriptions.forEach(scoreDescription => this.addToAttributeValueMap("finalScore", scoreDescription.split(":")[0]));
    }

    addToAttributeValueMap(propertyKey: string, propertyValue: string): void {
        if (this.hasKeyAndDoesNotInclude(propertyKey, propertyValue)) {
            this.addValueAndSort(propertyKey, propertyValue);
            return;
        }
        if (this.hasKeyAndAlreadyIncludes(propertyKey, propertyValue)) return;
        this.model.attributeValueMap.set(propertyKey, [propertyValue]);
    }

    hasKeyAndDoesNotInclude(propertyKey: string, propertyValue: string): boolean {
        if (this.model.attributeValueMap.has(propertyKey) && !this.model.attributeValueMap.get(propertyKey)!.includes(propertyValue)) return true;
        return false;
    }

    hasKeyAndAlreadyIncludes(propertyKey: string, propertyValue: string): boolean {
        if (this.model.attributeValueMap.has(propertyKey) && this.model.attributeValueMap.get(propertyKey)!.includes(propertyValue)) return true;
        return false;
    }

    addValueAndSort(propertyKey: string, propertyValue: string): void {
        const filterAttributes = this.model.filterAttributes;
        const filterAttribute = filterAttributes.find(filterAttribute => filterAttribute['fieldName'] === propertyKey);
        this.model.attributeValueMap.get(propertyKey)!.push(propertyValue);
        if (filterAttribute && filterAttribute.fieldType === "number") {
            this.model.attributeValueMap.get(propertyKey)!.sort((a, b) => parseFloat(a) - parseFloat(b));
            return;
        }
        this.model.attributeValueMap.get(propertyKey)!.sort((a, b) => a.localeCompare(b));
    }

    isAnyFilterAttributeScore(filterAttributes: FilterAttribute[]): boolean {
        return filterAttributes.some(filterAttribute => filterAttribute.fieldName === "finalScore");
    }
}
