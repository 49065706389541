<!-- Login Form
<div *ngIf="!registerPage" id="loginForm">
    <mat-card-header>
        <mat-card-title
            >Login
            <button
                style="float: right"
                class="delete"
                aria-label="close"
                (click)="onClose()"
            ></button
        ></mat-card-title>
    </mat-card-header>
    <mat-slide-toggle color="primary" [(ngModel)]="phoneToggle">{{phoneToggle ? 'Phone Login' : 'Standard Login'}}</mat-slide-toggle>
        <div *ngIf="!phoneToggle">
            <form [formGroup]="loginForm" (ngSubmit)="login()">
                <mat-card-content> -->
                    <!-- <small *ngIf="formError" class="noRecord">Please ensure the email and password are correct</small> -->
                    <!-- <mat-error *ngIf="loginForm.get('email').hasError('email')">
                        <small
                            >Please ensure the email format is correct.</small
                        >
                    </mat-error>
                    <mat-form-field>
                        <input
                            formControlName="email"
                            matInput
                            placeholder="Email"
                            type="email"
                            name="email"
                            required
                        />
                    </mat-form-field>
                    <br />
                    <mat-form-field>
                        <input
                            formControlName="password"
                            matInput
                            placeholder="Password"
                            [type]="hide ? 'password' : 'text'"
                            name="password"
                            required
                        />
                        <mat-icon matSuffix (click)="hide = !hide">{{
                            hide ? "visibility_off" : "visibility"
                        }}</mat-icon>
                    </mat-form-field>
                </mat-card-content>
                <mat-card-actions>
                    <br />
                    <button
                        mat-raised-button
                        type="submit"
                        class="button is-primary"
                        [disabled]="
                            loginForm.get('email').hasError('email') ||
                            loginForm.get('email').hasError('required') ||
                            loginForm.get('password').hasError('required')
                        "
                    >
                        Login
                    </button> -->
                    <!-- <button mat-raised-button (click)="exitLogin()" color="danger">Cancel</button> -->
                <!-- </mat-card-actions>
            </form>
        </div>
        <div *ngIf="phoneToggle">
            <form [formGroup]="phoneSMSForm" (ngSubmit)="phoneSMS()">
                <mat-card-content>
                    <mat-error *ngIf="phoneSMSForm.get('phone').hasError('pattern')">
                        <small
                            >Please enter only numbers.</small
                        >
                    </mat-error>
                    <mat-form-field>
                        <input
                            formControlName="phone"
                            matInput
                            placeholder="Phone Number"
                            type="tel"
                            name="phone"
                        />
                    </mat-form-field>
                    <br />
                </mat-card-content>
                <mat-card-actions>
                    <button
                        mat-raised-button
                        type="submit"
                        class="button is-primary"
                        [disabled]="
                            phoneSMSForm.get('phone').hasError('required') ||
                            phoneSMSForm.get('phone').hasError('pattern')
                        "
                    >
                        Send Code
                    </button>
                </mat-card-actions>
            </form>
            <form *ngIf="phoneSMSPage" [formGroup]="phoneLoginForm" (ngSubmit)="phoneLogin()">
                <mat-card-content>
                    <br/>
                    <mat-error *ngIf="phoneLoginForm.get('sms').hasError('pattern')">
                        <small
                            >Please enter only numbers.</small
                        >
                    </mat-error>
                    <mat-form-field>
                        <input
                            formControlName="sms"
                            matInput
                            placeholder="SMS Code"
                            type="text"
                            name="sms"
                        />
                    </mat-form-field>
                </mat-card-content>
                <mat-card-actions>
                    <br />
                    <button
                        mat-raised-button
                        type="submit"
                        class="button is-primary"
                        [disabled]="
                            phoneLoginForm.get('sms').hasError('required') ||
                            phoneLoginForm.get('sms').hasError('pattern') ||
                            phoneSMSForm.get('phone').hasError('required') ||
                            phoneSMSForm.get('phone').hasError('pattern')
                        "
                    >
                        SMS Login
                    </button>
                </mat-card-actions>
            </form>
        </div>
        <br />
    <small
        ><i
            ><a class="" (click)="registerClick()">Don't have an account?</a>
        </i></small
    >
    <br />
</div> -->

<!--Register Form-->
<div id="registerForm">
    <mat-card-header>
        <mat-card-title
            >Register
            <button
                style="float: right"
                class="delete"
                aria-label="close"
                (click)="onClose()"
            ></button
        ></mat-card-title>
    </mat-card-header>
    <form [formGroup]="registerForm" (ngSubmit)="register()">
        <mat-card-content>
            <mat-form-field>
                <input
                    formControlName="firstname"
                    matInput
                    placeholder="First Name"
                    type="text"
                    name="firstname"
                    required
                />
            </mat-form-field>
            <br />
            <mat-form-field>
                <input
                    formControlName="lastname"
                    matInput
                    placeholder="Last Name"
                    type="text"
                    name="lastname"
                    required
                />
            </mat-form-field>
            <br />
            <mat-error *ngIf="registerForm.get('userEmail').hasError('userEmail')">
                <small>Please ensure the email format is correct.</small>
            </mat-error>
            <mat-form-field>
                <input
                    formControlName="userEmail"
                    matInput
                    placeholder="Email"
                    type="email"
                    name="userEmail"
                    required
                />
            </mat-form-field>
            <br />
            <mat-form-field>
                <input
                    formControlName="phone"
                    matInput
                    placeholder="Phone Number (optional)"
                    type="tel"
                    name="phone"
                />
            </mat-form-field>
            <br />
            <mat-form-field>
                <input
                    formControlName="password"
                    matInput
                    placeholder="Password"
                    [type]="hide ? 'password' : 'text'"
                    name="password"
                    required
                />
                <mat-icon matSuffix (click)="hide = !hide">{{
                    hide ? "visibility_off" : "visibility"
                }}</mat-icon>
            </mat-form-field>
        </mat-card-content>
        <br />
        <mat-card-actions>
            <div class="columns">
                <div class="column">
                    <button
                        mat-raised-button
                        type="submit"
                        class="button is-primary"
                        [disabled]="
                            registerForm.get('userEmail').hasError('userEmail') ||
                            registerForm.get('password').hasError('required') ||
                            registerForm
                                .get('firstname')
                                .hasError('required') ||
                            registerForm.get('lastname').hasError('required')
                        "
                    >
                        Register
                    </button>
                </div>
                <div class="column">
                    <button
                        mat-raised-button
                        (click)="registerCancel()"
                        class="button"
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </mat-card-actions>
    </form>
</div>
