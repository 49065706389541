
<div class="session-timeout-grid">
    <div class="session-timeout-item header"><h1 class="is-size-4">Session Timeout Warning</h1></div>
    <div class="session-timeout-item">Your session is set to expire in
        <strong>{{ timeRemaining$ | async | date:'m'}} minutes and {{ timeRemaining$ | async | date: 'ss'}} seconds</strong>
        due to inactivity. Please click "Continue Session" to continue working or "Sign Out" to end your session.
    </div>
    <div class="session-timeout-item">
        <button (click)="logoutCick()" class="button sign-out-btn is-rounded">Sign Out</button>
        <button (click)="loginClick()" class="button continue-session-btn is-rounded">Continue Session</button>
    </div>
</div>

