import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CalculatorInputFormComponent } from './components/calculator-input-form/calculator-input-form.component';
import { MainComponentComponent } from './components/main-component/main-component.component';
import { UserProjectsComponent } from './components/user-projects/user-projects.component';
import { ResourcesComponent } from './components/resources/resources.component';
import { AltFuelComponent } from './components/alt-fuel/alt-fuel.component';
import { EvScreenComponent } from './components/ev-screen/ev-screen.component';

const routes: Routes = [
    {
        path: 'sites/:site',
        component: MainComponentComponent,
        children: [
            {
                path: 'calculator',
                component: CalculatorInputFormComponent
            }
        ]
    },
    {
        path: 'projects',
        component: UserProjectsComponent
    },
    {
        path: 'resources',
        component: ResourcesComponent
    },
    {
        path: 'ev/:site',
        component: AltFuelComponent,
        children: [
            {
                path: 'screen',
                component: EvScreenComponent
            }
        ]
    }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
