import { Component, HostListener, OnInit } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { FileInfo } from 'src/app/models/ev/blob-file-info';
import { SitePhotoLogicService } from 'src/app/services/ev/logic-services/site-photo-logic.service';

@Component({
  selector: 'app-site-photos',
  templateUrl: './site-photos.component.html',
  styleUrls: ['./site-photos.component.scss']
})
export class SitePhotosComponent implements OnInit {
    checkboxStates: boolean[] = [];
    selectAllState: boolean = false;
    selectedItems: Set<number> = new Set();

    get currentImageIndex(): number { return this._sitePhotoLogicService.model.currentImageIndex; }
    get files(): FileInfo[] { return this._sitePhotoLogicService.model.files; }
    get currentImageUrl(): SafeResourceUrl { return this._sitePhotoLogicService.model.currentImageUrl; }
    get showImageModal(): boolean { return this._sitePhotoLogicService.model.showImageModal; }

    constructor(
        private _sitePhotoLogicService: SitePhotoLogicService
    ) { }

    ngOnInit() {
        this.checkboxStates = new Array(this.files.length).fill(false);
    }

    @HostListener('document:keydown.escape', ['$event'])
    onEscapeKeyPressed(event: KeyboardEvent) {
        if (this.showImageModal) this.showSitePhotoModal();
    }

    @HostListener('document:keydown.arrowleft', ['$event'])
    onArrowLeftKeyPressed(event: KeyboardEvent) {
        if (this.showImageModal) this.toggleImage(true);
    }

    @HostListener('document:keydown.arrowright', ['$event'])
    onArrowRightKeyPressed(event: KeyboardEvent) {
        if (this.showImageModal) this.toggleImage(false);
    }


    onSelectAllChange(event: any): void {
        if (event.checked) {
          this.selectAll();
          return;
        }
        this.deselectAll();
    }

    onCheckboxChange(event: any, index: number): void {
        if (event.checked) {
            this.selectedItems.add(index);
        } else {
            this.selectedItems.delete(index);
        }
        this.updateSelectAllState();
    }

    updateSelectAllState(): void {
        this.selectAllState = this.selectedItems.size === this.files.length;
    }

    selectAll(): void {
        for (let i = 0; i < this.files.length; i++) {
          this.selectedItems.add(i);
          this.checkboxStates[i] = true;
        }
      }

    deselectAll(): void {
        this.selectedItems.clear();
        for (let i = 0; i < this.files.length; i++) {
            this.checkboxStates[i] = false;
        }
    }

    isSelected(index: number): boolean { return this.selectedItems.has(index) };

    toggleImage(isNext: boolean): void {
        this._sitePhotoLogicService.toggleImage(isNext);
    }

    openImageModal(index: number): void {
        this._sitePhotoLogicService.openImageModal(index);
    }

    setCurrentImageUrl(index: number): void {
        this._sitePhotoLogicService.setCurrentImageIndex(index);
    }

    setCurrentImageIndex(increment: number): void {
        this._sitePhotoLogicService.setCurrentImageIndex(increment);
    }

    showSitePhotoModal(): void {
        this._sitePhotoLogicService.showSitePhotoModal();
    }

    downloadFile(): void {
        this._sitePhotoLogicService.downloadFile();
    }

    downloadFiles(): void {
        this._sitePhotoLogicService.downloadAllFiles(this.selectedItems);
    }
}
