import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { of, Subject, timer } from 'rxjs';
import { concatMap, map, takeWhile, tap } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/services/shared/authentication.service';
import { TokenService } from 'src/app/services/shared/token.service';


@Component({
  selector: 'app-session-timeout',
  templateUrl: './session-timeout.component.html',
  styleUrls: ['./session-timeout.component.scss']
})
export class SessionTimeoutComponent {

    constructor(
        private _authenticationSerivice: AuthenticationService,
        private _tokenService: TokenService,
        public dialogRef: MatDialogRef<SessionTimeoutComponent>
        ) { }

    public seconds = 300;

    private _idToken: string = this._tokenService.getIdToken();

    timeRemaining$ = timer(0, 1000).pipe(
    map(n => (this.seconds - n) * 1000),
    takeWhile(timeRemaining => timeRemaining >= 0),
    concatMap(timeRemaining => of(timeRemaining).pipe(
        tap(() => {
            if (timeRemaining === 0) {
                this.logoutCick()
            }
        })
    ))
    )

    loginClick(): void {
        if(this._idToken) {
            this.authorize();
            this.setSessionContinued();
            this.closeDialog();
        }
    }

    logoutCick(): void {
        this.closeDialog();
        this.logoutRedirect();
    }

    authorize(): void {
        this._authenticationSerivice.authorize(this._idToken)
    }

    setSessionContinued(): void {
        this._authenticationSerivice.setSessionContinued();
    }

    logoutRedirect(): void {
        this._authenticationSerivice.logoutRedirect();
    }

    closeDialog(): void {
        this.dialogRef.close();
    }



}
