import { BrowserCacheLocation, Configuration, LogLevel } from "@azure/msal-browser/dist";
import { environment } from "src/environments/environment";

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export const b2cPolicies = {
     names: {
        signIn: environment.b2c.b2cPolicies.signIn
     },
     authorities: {
        signIn: {
             authority: environment.b2c.authority,
         }
     },
     extraQueryParameters: {
        AppName: "tredliteev"
      },
     authorityDomain: environment.b2c.authorityDomain

 };


export const msalConfig: Configuration = {
    auth: {
        clientId: environment.b2c.clientId,
        authority: b2cPolicies.authorities.signIn.authority,
        knownAuthorities: [b2cPolicies.authorityDomain],
        redirectUri: '/',
    },
     cache: {
         cacheLocation: BrowserCacheLocation.SessionStorage,
         storeAuthStateInCookie: isIE,
     },
     system: {
         loggerOptions: {
            loggerCallback: (logLevel, message, containsPii) => {
                // console.log(message);
             },
             logLevel: LogLevel.Verbose,
             piiLoggingEnabled: false
         }
     },

 }

export const protectedResources = {
  Api: {
    endpoint: "",
    scopes: [""],
  },
}
export const loginRequest = {
  extraQueryParameters: {AppName: 'tredliteev'},
  scopes: []
};
