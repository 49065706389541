import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AppConstants {
    public readonly PAGE_LOCATION = 1;
    public readonly PAGE_INFO = 2;
    public readonly PAGE_INTERNAL_CAPTURE = 3;
    public readonly PAGE_PRIORITY = 4;
    public readonly PAGE_CALCULATE = 5;
    public readonly PAGE_TDM = 6;
    public readonly PAGE_BANK = 7;

    public readonly PAGE_EV_START = 1;
    public readonly PAGE_EV_SCREEN = 2;
    public readonly PAGE_EV_SITE = 3;

    public readonly RES_AVG_TRIP_LENGTH = 12.5;
    public readonly WORK_AVG_TRIP_LENGTH = 7.3;
}
