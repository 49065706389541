import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EvSiteRequest } from 'src/app/models/ev/SiteValueRequest.model';
import { EvSite } from 'src/app/models/ev/evSite.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EvDataService {

    constructor(private _httpClient: HttpClient) { }

    getAllSites(): Observable<any> {
        const url = `${environment.api.baseURL}/Sites/GetAllSites`
        return this._httpClient.get<Map<number, EvSite>>(url);
    }

    getFilteredSitesByAttributes(filters: any[]): Observable<any> {
        const url = `${environment.api.baseURL}/Sites/GetFilteredSitesByAttribute`;
        return this._httpClient.post(url, filters);
    }

    getFilteredSites(request: EvSiteRequest): Observable<any> {
        const url = `${environment.api.baseURL}/Sites/GetFilteredSites`;
        return this._httpClient.post(url, request);
    }

    getPresetSiteValues(censusIds: number[], blockIds: number[]): Observable<any> {
        const url = `${environment.api.baseURL}/Sites/GetPresetValues`;
        const request = new EvSiteRequest();
        request.censusIds = Array.from(new Set(censusIds));
        request.blockIds = Array.from(new Set(blockIds));
        return this._httpClient.post(url, request);
    }

    getAdjustedSiteScores(request: EvSiteRequest): Observable<any> {
        const url = `${environment.api.baseURL}/Sites/GetAdjustedScoresAndValues`;
        return this._httpClient.post(url, request);
    }

    getScoresAndValuesForPinDrop(request: EvSiteRequest): Observable<any> {
        const url = `${environment.api.baseURL}/Sites/GetAdjustedScoresAndValues`;
        return this._httpClient.post(url, request);
    }
}
