import Graphic from "@arcgis/core/Graphic.js";
import Geometry from "@arcgis/core/geometry/Geometry";

export class ClassBreakInfo {
    classBreaks: ClassBreak[];
    title: string;
}

export class ClassBreak {
    min: number;
    max: number;
    color: number[];
    label: string;
}

export class RendererInfo {
    classBreaksRenderer: any;
    rendererJson: string;
}

export class RendererCollection {
    private currentIndex: number = 0;
    private renderers: RendererInfo[] = [];

    public getNext() : RendererInfo {
        this.currentIndex++;
        if (this.currentIndex >= this.renderers.length)
            this.currentIndex = 0;
        return this.renderers[this.currentIndex];
    }

    public add(renderer: RendererInfo) {
        this.renderers.push(renderer);
    }

    public getAt(index: number): RendererInfo {
        if (index >= this.renderers.length) return null;
        this.currentIndex = index;
        return this.renderers[index];
    }

    public getCurrentIndex(): number {
        return this.currentIndex;
    }

    public setCurrentIndex(index: number) {
        if (index >= this.renderers.length) return;
        this.currentIndex = index;
    }
}

export interface IHighlight {
    graphics: __esri.Handle;
    features: Graphic[];
    layerId: string;
    geometry: Geometry;
    geometryType: 'point' | 'polygon';
}
