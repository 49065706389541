
<div class="main" [class.is-flex]="page < constants.PAGE_CALCULATE">
        <aside id="sidebar" class="aside-left hide" [class.aside-left-project]="page > constants.PAGE_EV_SCREEN && page < constants.PAGE_CALCULATE"
            [class.aside-left-results]="page === constants.PAGE_CALCULATE"
            [class.aside-left-ev-site-details]="page === constants.PAGE_EV_SITE">
            <app-ev-screen id="calculator" (pageChange)="onPageChange($event)"></app-ev-screen>
        </aside>

    <div id="main-content" class="is-flex is-flex-direction-column main-content-col"
        [class.small]="page > constants.PAGE_LOCATION" [class.hide]="page >= constants.PAGE_CALCULATE">
        <div class="map-container">
            <app-map-widget></app-map-widget>
        </div>
    </div>
</div>
