import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[tooltip]'
})
export class TooltipDirective {
  @Input('tooltip') tooltipText: string;
  @Input('tooltipPosition') position: 'top' | 'right' | 'bottom' | 'left' = 'bottom';

  private tooltip: HTMLElement;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  @HostListener('mouseenter') onMouseEnter() {
    this.createTooltip();
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.removeTooltip();
  }

  createTooltip() {
    this.tooltip = this.renderer.createElement('span');
    this.renderer.appendChild(document.body, this.tooltip);
    this.renderer.addClass(this.tooltip, 'tooltip');
    this.renderer.setStyle(this.tooltip, 'position', 'fixed');
    this.renderer.setStyle(this.tooltip, 'background-color', 'grey');
    this.renderer.setStyle(this.tooltip, 'color', 'white');
    this.renderer.setStyle(this.tooltip, 'padding', '5px');
    this.renderer.setStyle(this.tooltip, 'border-radius', '5px');
    this.renderer.setStyle(this.tooltip, 'font-size', '14px');
    this.renderer.setProperty(this.tooltip, 'textContent', this.tooltipText);

    const hostPos = this.el.nativeElement.getBoundingClientRect();
    const scrollPos = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;
    const tooltipPos = this.tooltip.getBoundingClientRect();

    let top, left;

    switch (this.position) {
      case 'top':
        top = hostPos.top - tooltipPos.height - 10 + scrollPos;
        left = hostPos.left + hostPos.width / 2 - tooltipPos.width / 2;
        break;
      case 'right':
        top = hostPos.top + hostPos.height / 2 - tooltipPos.height / 2 + scrollPos;
        left = hostPos.right + 10;
        break;
      case 'bottom':
        top = hostPos.bottom + 10 + scrollPos;
        left = hostPos.left + hostPos.width / 2 - tooltipPos.width / 2;
        break;
      case 'left':
        top = hostPos.top + hostPos.height / 2 - tooltipPos.height / 2 + scrollPos;
        left = hostPos.left - tooltipPos.width - 10;
        break;
      default:
        throw new Error('Invalid position');
    }

    this.renderer.setStyle(this.tooltip, 'top', `${top}px`);
    this.renderer.setStyle(this.tooltip, 'left', `${left}px`);
  }


  removeTooltip() {
    if (this.tooltip) {
      this.renderer.removeChild(document.body, this.tooltip);
      this.tooltip = null;
    }
  }
}
