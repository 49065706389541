import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { concat, Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, distinctUntilChanged, switchMap, takeUntil, tap } from 'rxjs/operators';

import { AppConstants } from 'src/app/app.constants';
import { AuthenticationService } from 'src/app/services/shared/authentication.service';
import { EsriLocationService, EsriLocatorResult } from 'src/app/services/shared/esri-location.service';
import { MapInteractionService, FeatureSelectorType } from 'src/app/services/shared/map-interaction.service';
import { NotificationService, NotificationType, NotificationCloseType } from 'src/app/services/shared/notification.service';
import { AgencyService } from 'src/app/services/shared/agency.service';
import { ThemeLayerService } from 'src/app/services/shared/theme-layer.service';
import { EvLogicService } from 'src/app/services/ev/logic-services/ev-logic.service';
import { ScreenInputLogicService } from 'src/app/services/ev/logic-services/screen-input-logic.service';
import { FilterAttributeLogicService } from 'src/app/services/ev/logic-services/filter-attribute-logic.service';

import { IAgencyConfig, ICity } from 'src/app/models/shared/agency-data.model';
import { EvSiteFilter, FilterAttribute } from 'src/app/models/ev/filter.model';
import { ScreenInput } from 'src/app/models/ev/screen-input.model';
import { Condition } from 'src/app/models/ev/condition.model';
import { FilterSitesByAttributeRequest } from 'src/app/models/ev/filter-sites-by-attribute-request';

@Component({
    selector: 'app-ev-screen',
    templateUrl: './ev-screen.component.html',
    styleUrls: ['./ev-screen.component.scss']
})
export class EvScreenComponent implements OnInit {
    private _componentDestroyed$: Subject<boolean> = new Subject<boolean>();
    private subscriptions: Subscription[] = [];
    agencyConfig: IAgencyConfig;
    attributeLoading: false;
    cities: ICity[];
    filename: string;
    isNested: boolean;
    isNestingAllowed: boolean;
    isValidating: boolean;
    khPrimary: string;
    loaderText: string = 'Loading';
    locationAttributes: string[] = ['state', 'zipcode'];
    locatorResult$: Observable<EsriLocatorResult[]>;
    locatorResultLoading = false;
    locatorResultInput$ = new Subject<string>();
    mapSource: number = 1;
    nextText: string = 'Next';
    page: number;
    selectedCity: string;
    selectedLocatorResults: EsriLocatorResult;
    siteFilterList: EvSiteFilter[] = [];
    prevousPage: number;

    get filterAttributes(): FilterAttribute[] { return this._filterAttributeLogicService.model.filterAttributes; }

    get attributeValueMap(): Map<string, Array<string>>{ return this._filterAttributeLogicService.model.attributeValueMap; }

    get lowerCaseAttributes(): string[] { return this._filterAttributeLogicService.model.lowerCaseAttributes; }

    get screenInputs(): ScreenInput[] { return this._screenInputLogicService.model.screenInputs; }

    get agencyName(): string { return this.agencyService.model.agencyName; }

    get sessionContinued(): boolean { return this.authenticationService.sessionContinued; }


    @Output() pageChange: EventEmitter<number> = new EventEmitter();
    constructor(
        private activatedRoute: ActivatedRoute, private router: Router, private esriLocationService: EsriLocationService,
        private notificationService: NotificationService, private mapInteractionService: MapInteractionService,
        private agencyService: AgencyService, private authenticationService: AuthenticationService, public constants: AppConstants,
        private _evLogicService: EvLogicService, private _screenInputLogicService: ScreenInputLogicService,
        private _filterAttributeLogicService: FilterAttributeLogicService, private themeLayerService: ThemeLayerService
    ) {
        this.authenticationService.signInSuccess$.subscribe(result => {
            let sidebar = document.getElementById('sidebar');
            sidebar.classList.remove('hide');
        });
        this.subscriptions.push(mapInteractionService.selectionToTazCompleted$.subscribe((data:any[]) => {
            this.getScoreForBlockGroup(data[0]);
        }));
    }

    ngOnInit(): void {
        this.page = 1;
        this.pageChange.emit(this.page);
        this.activatedRoute.queryParamMap.subscribe((paramMap: ParamMap) => {
            if (!paramMap.keys.length) return;
            this.page = parseInt(paramMap.get('step'));
            this.pageChange.emit(this.page);
        });

        this.loadlocatorResult();
        this.loadAgencyConfig();

        this.authenticationService.user$.pipe(takeUntil(this._componentDestroyed$))
        .subscribe((user) => {
            if(user && !this.sessionContinued) {
                this.loadEvSites();
            }
        })

        this._evLogicService.showSiteDetailsPage$.pipe(takeUntil(this._componentDestroyed$))
        .subscribe((showSiteDetailsPage) => {
            if (showSiteDetailsPage) {
                if (this.page !== 3) {
                    this.prevousPage = this.page;
                }
                this.goToPage(this.constants.PAGE_EV_SITE)
            }
        })

        let filter = new EvSiteFilter();
        let attr = new FilterAttribute();
        attr.fieldName = ""
        filter.filterAttribute = attr;
        this.siteFilterList.push(filter);

        this.getScreenInputs();
    }

    ngOnDestory() {
        this.subscriptions.forEach(x => x.unsubscribe());
        this._componentDestroyed$.next(true);
        this._componentDestroyed$.complete();
    }

    ngAfterViewInit() {
        let element = document.getElementsByClassName('is-primary')[0] as HTMLElement;
        this.khPrimary = window.getComputedStyle(element).backgroundColor;
    }

    getAttributeValues(fieldName: string): Array<string | number> {
        if (fieldName === undefined) return [];
        return this.attributeValueMap.get(fieldName) || [];
    }

    locatorTrackByFn(item: EsriLocatorResult) {
        return item.magicKey;
    }

    loadEvSites() {
        this._filterAttributeLogicService.getDistinctAttributes();
        this._evLogicService.getAllSites();
    }

    loadlocatorResult() {
        this.locatorResult$ = concat(
            of([]), // default items
            this.locatorResultInput$.pipe(
                distinctUntilChanged(),
                tap(() => this.locatorResultLoading = true),
                switchMap(term => this.esriLocationService.getAddresses(term).pipe(
                    catchError(() => of([])), // empty list on error
                    tap(() => this.locatorResultLoading = false)
                ))
            )
        );
    }

    onLocatorChange(locatorResult: EsriLocatorResult) {
        this.esriLocationService.getLocationFromAddress(locatorResult);
    }

    onNextClick() {
         let prevPage = this.page;
        let checkForCalculate = false;
        switch (prevPage) {
            default:
                this.goToPage(this.page + 1);
                break;
        }
    }

    onBackClick() {
        this.goToPage(this.page - 1);
        this.updateUrl();
        this.pageChange.emit(this.page);
    }

    // Helps set the visibility, styling, and function of Next button
    goToPage(page: number) {
        switch (page) {
            case this.constants.PAGE_EV_SCREEN:
            if (page > this.page)
                this.applyFilters();
                break;
            case this.constants.PAGE_LOCATION:
            default:
                this.nextText = 'Next';
                break;
        }
        if (page === this.constants.PAGE_CALCULATE) {
            setTimeout(() => {
                this.updatePageAndUrl(page);
            }, 500);
        } else
            this.updatePageAndUrl(page);
        this.isValidating = false;
    }

    updatePageAndUrl(page: number) {
        this.page = page;
        this.updateUrl();
        this.pageChange.emit(this.page);
    }

    updateUrl() {
        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: {
                step: this.page
            },
            replaceUrl: true
        });
    }

    initiateFeatureSelectControl() {
        let tabs = document.querySelectorAll('.tabs li');
        tabs.forEach(tab => {
            tab.addEventListener('click', event => {
                let htmlElement = event.target as HTMLElement;
                let ulElement = htmlElement.closest('ul');
                let liElement = htmlElement.closest('li');

                ulElement.querySelectorAll('li').forEach(element => {
                    element.classList.remove('is-active');
                });
                setTimeout(() => {
                    liElement.classList.add('is-active');
                }, 100);
            });
        });
    }

    onMapSourceChanged() {
        setTimeout(() => {
            this.initiateFeatureSelectControl();
        }, 500);
    }

    singleSiteRenderComplete() {
        this.mapInteractionService.initializePinDrop();
    }

    selectByPolygon() {
        this.mapInteractionService.updateFeatureSelectorType(FeatureSelectorType.Shape);
    }

    selectByRectangle() {
        this.mapInteractionService.updateFeatureSelectorType(FeatureSelectorType.Box);
    }

    selectByPoint() {
        this.mapInteractionService.updateFeatureSelectorType(FeatureSelectorType.Single);
    }

    onClearClick() {
        // Clear map selection
        this.mapInteractionService.clearSelection();
        // Clear address search
        this.selectedLocatorResults = null;
        // Clear and initialize site filters
        this.siteFilterList = [];
        this.addNewSiteFilter();

        // Restore Performance Filters defaults and clear filters
        this.onDefaultsClick();
        this.mapInteractionService.resetAllFilters();

        // Zoom out to full extent
        this.mapInteractionService.mapGoTo(null);
    }

    addNewSiteFilter() {
        let filter = new EvSiteFilter()
        let attr = new FilterAttribute();
        attr.fieldName = ""
        filter.filterAttribute = attr;
        this.siteFilterList.push(filter);
    }

    removeSiteFilter(index: number) {
        this.siteFilterList.splice(index, 1);
    }

    onFilterByChange(filter: EvSiteFilter) {
        filter.filterValue = "";
    }

    applyFilters() {
        let locationFilters = this.siteFilterList.filter(x => this.locationAttributes.includes(x.filterAttribute.fieldName?.toLowerCase()));
        if (this.siteFilterList.length === 0 || (this.siteFilterList.length === 1 && this.siteFilterList[0].comparisonType === undefined)) {
            this.mapInteractionService.filterPoints([0], 'attribute', false, true);
            return;
        }
        const scoreFilter: EvSiteFilter = this.siteFilterList.find(filter => filter.filterAttribute.fieldName === "finalScore");
        const newSiteFilterList: EvSiteFilter[] = this.siteFilterList.filter(filter => filter.filterAttribute.fieldName !== "finalScore");
        let filters = newSiteFilterList.map(filter => { return filter.toDto()})
        const filterSitesByAttrRequest: FilterSitesByAttributeRequest = {filters: filters, evSiteFilters: locationFilters, scoreFilter: scoreFilter};

        this._evLogicService.filteredSitesByAttributes(filterSitesByAttrRequest);
    }

    onFileChange(fileInput: any) {
        if (fileInput.target.files && fileInput.target.files[0]) {
            this.filename = fileInput.target.files[0].name.toLowerCase();
            let uploadStatus = document.getElementById('upload-status');
            let uploader = document.getElementById('uploader');
            if (this.filename.indexOf(".zip") !== -1) {//is file a zip - if not notify user
                uploadStatus.classList.remove('is-danger');
                uploader.classList.remove('is-danger');
                this.mapInteractionService.uploadShapefile(this.filename);
            }
            else {
                uploadStatus.classList.add('is-danger');
                uploader.classList.add('is-danger');
                uploadStatus.innerHTML = 'Add shapefile as .zip file';
            }
        }
    }

    loadAgencyConfig() {
        this.agencyService.configUpdated$.subscribe(config => {
            this.agencyConfig = config;
            this.isNestingAllowed = this.agencyConfig.agencyName !== 'Phillips66';
            this.loadAgencySpecificData();
            this.themeLayerService.themeLayers = this.agencyConfig.themeLayers;
        });
    }

    async loadAgencySpecificData() {
    }

    camelToTitle(camelCase) {
        return camelCase.replace(/([A-Z])/g, (match) => ` ${match}`)
        .replace(/^./, (match) => match.toUpperCase())
        .trim();
    }

    getScreenInputs() {
        this._screenInputLogicService.getScreenInputs();
    }

    onEvaluateClick() {
        let conditions: Condition[] = [];
        this.screenInputs.filter(x => x.isSelected).forEach(i => {
            let stepId = 1;
            let inputValue = i.primaryStep;
            if(i.secondaryStepValues.length) {
                stepId = i.primaryStepValues.indexOf(i.primaryStep) + 1;
                inputValue = i.secondaryStep;
            }
            conditions.push({
                conditionId: i.primaryConditionId,
                stepId: stepId,
                inputValue: inputValue
            });
        });
        if (conditions.length === 0) {
            this.notificationService.showNotification(NotificationType.Warning,
                'No Performance filters are selected.', NotificationCloseType.Self);
            this.mapInteractionService.filterPoints([], 'performance', false, true);
            return;
        }
        this.loaderText = 'Filtering Out Sites';
        let loader = document.getElementById('evPageLoader');
        loader.classList.add('is-active');
        this._evLogicService.setConditionsAndAdjustedParameters(conditions);
        this._evLogicService.getAdjustedSiteScores();
    }

    onDefaultsClick() {
        this.screenInputs.forEach(input => {
            input.restoreDefaults();
            input.isSelected = true;
        });
        this.isNested = false;
    }

    siteDetailsPageBackClick() {
        this.goToPage(this.prevousPage);
    }

    getScoreForBlockGroup(groupInfo) {
        this._evLogicService.getScoresAndValuesForPinDrop(groupInfo);
    }

    onNestedSwitchChange(e) {
        this._evLogicService.model.isNested = this.isNested;
    }
}
