import { IStrategyInput } from "./tdm.model";
import { IPresumption } from 'src/app/models/vmt/presumption.model';

export class VmtSettings {
    public iteCodes: ITECode[];
    public userId: string;
    public agencyId: string;
    public cityId: string;
    public projectId: string;
    public projectName: string;
    public projectDescription?: string;
    public address: string;
    public projectContextSetting: string;
    public TAZ: TAZ[];
    public projectContentSetting: string;
    public analysisYear: number;
    public county: string;
    public highlightFeatures: HighlightFeature[];
    public presumptionIDs: number[];
    public cityVmtPerServicePopulation: number;
    public cityVtPerServicePopulation: number;
}

export class ITECode {
    public ITECode: string;
    public NoOfUnits: number;
    public tdmUserInputs: IStrategyInput[];
    public isTotalVmt: boolean;
    public internalCaptureRate: number;
    public presumptionIDs: number[];
}

export class TAZ {
    public id: number;
    public percentage: number;
}

export class AirQuality {
    public CO2: number;
    public ROG: number;
    public NOX: number;
    public CO: number;
    public SOX: number;
    public PM10: number;
    public PM2_5: number;

    public CO2Mobile: number;
    public ROGMobile: number;
    public NOXMobile: number;
    public COMobile: number;
    public SOXMobile: number;
    public PM10Mobile: number;
    public PM2_5Mobile: number;

    public MitigationCO2: number;
    public MitigationROG: number;
    public MitigationNOX: number;
    public MitigationCO: number;
    public MitigationSOX: number;
    public MitigationPM10: number;
    public MitigationPM2_5: number;

    constructor() {
        this.reset();
    }

    public reset() {
        this.CO2 = 0;
        this.ROG = 0;
        this.NOX = 0;
        this.SOX = 0;
        this.CO = 0;
        this.PM10 = 0;
        this.PM2_5 = 0;
        this.MitigationCO2 = 0;
        this.MitigationROG = 0;
        this.MitigationNOX = 0;
        this.MitigationSOX = 0;
        this.MitigationCO = 0;
        this.MitigationPM10 = 0;
        this.MitigationPM2_5 = 0;
        this.CO2Mobile = 0;
        this.ROGMobile = 0;
        this.NOXMobile = 0;
        this.SOXMobile = 0;
        this.COMobile = 0;
        this.PM10Mobile = 0;
        this.PM2_5Mobile = 0;
    }
}

export class HighlightFeature {
    public layerId: string;
    public geometry: string;
}

export interface IOutput {
    landUseType: string;
    capitaProject: number;
    capitaReduction: number;
    capitaProjectWithMitigation: number;
    dailyTripsProject: number;
    dailyTripsReduction: number;
    dailyTripsProjectWithMitigation: number;
    airQuality: AirQuality;
    pedAccessibility: number;
    vmtPerServicePop: number;
    vmtPerServicePopMitigation: number;
    vtPerServicePop: number;
    vtPerServicePopMitigation: number;
    vmtThreshold: number;
    vtThreshold: number;
    pedThreshold: number;
    mitigationPercent: number;
    totalVmt: number;
    totalVmtMitigation: number;
    isPreexisting: boolean;
    iteCode: string;
    attributableVmt: number;
    attributableVt: number;

    isTotalVmt: boolean;
    average: number;
    threshold: number;
    thresholdSource: string;
    significance: string;
    index: number;
    presumptions: IPresumption[];
    tdmResults: TdmResult[];
}

export class BankInput {
    public vmtOutput: any;
    public conversionFactor: number;
    public feasibleMitigationPercent: number;
    public bankingCost: number;
}

export class TdmResult {
    public TDMID: number;
    public GroupID : number;
    public AgencyMaxVMTReduction: number;
    public StandardMaxVMTReduction: number;
    public Formula1Results: number;
    public Formula2Results: number;
    public DailyTripReductions: number;
    public VMTCapitaReductions: number;
    public EffectiveGroupReduction: number;
    public EffectiveProjectReduction: number;
}
