import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { SiteThemeLayerInfo, ThemeLayer, ThemeLayerControlIconInfo, ThemeLayerInfo } from 'src/app/models/shared/theme-layer.model';
import { BaseService } from './base-service.service';
import { NotificationService } from './notification.service';

@Injectable({
    providedIn: 'root'
})
export class ThemeLayerService extends BaseService {
    private themeLayersSubject = new BehaviorSubject<ThemeLayer[]>([]);
    private cache = new Map<number, SiteThemeLayerInfo[]>();
    private allThemeLayerDataExportRequested = new Subject<void>();
    private allThemeLayerDataExportPosted = new Subject<Map<number, SiteThemeLayerInfo[]>>();
    private allThemeLayerDataPosted = new Subject<SiteThemeLayerInfo[]>();

    allThemeLayerDataPosted$ = this.allThemeLayerDataPosted.asObservable();
    allThemeLayerDataExportRequested$ = this.allThemeLayerDataExportRequested.asObservable();
    allThemeLayerDataExportPosted$ = this.allThemeLayerDataExportPosted.asObservable();

    themeLayerData: Map<number, SiteThemeLayerInfo[]> = new Map<number, SiteThemeLayerInfo[]>();

    allThemeLayerDataGathered: boolean = false;

    currentSiteThemeLayerData: SiteThemeLayerInfo[] = [];

    constructor(http: HttpClient, notificationService: NotificationService) {
        super(http, notificationService);
    }

    set themeLayers(value: ThemeLayer[]) {
        this.themeLayersSubject.next(value);
    }

    get themeLayers(): ThemeLayer[] {
        return this.themeLayersSubject.value;
    }

    private setSiteThemeLayerMetadata(info: SiteThemeLayerInfo, themeLayer: ThemeLayer) {
        info.layer = new ThemeLayerInfo();
        info.layer.layerName = themeLayer.title;
        info.layer.layerLabel = themeLayer.label;
        info.layer.detailsText = themeLayer.detailsText;
        info.layer.tooltipText = themeLayer.tooltipText;
        info.icon = new ThemeLayerControlIconInfo();
        info.icon.iconClass = themeLayer.iconClass;
        info.icon.iconClass2 = themeLayer.iconClass2;
        info.icon.matIconText = themeLayer.matIconText;
        info.icon.imgUrl = themeLayer.imgUrl;
    }

    layerList(): Observable<ThemeLayer[]> {
        return this.themeLayersSubject.asObservable();
    }

    removePinDropFromCache(): void {
        this.cache.delete(-1);
    }

    updateLayerData(siteId: number, themeLayer: ThemeLayer, siteValue: string) {
        siteValue = (siteValue == "" || siteValue == "Phase") ? "-" : siteValue;
        let infoList = this.cache.get(siteId);
        if (infoList) {
            const existing = infoList.find(i => i.layer.layerName === themeLayer.title);
            if (existing) {
                if (themeLayer.title === "Alt Fuel Corridors") {
                    const oldVal = existing.siteValue;
                    const newVal = `Phase ${siteValue}`;
                    existing.siteValue = [oldVal, newVal].join(", ");
                } else {
                    existing.siteValue = siteValue;
                }
            } else {
                const newInfo = new SiteThemeLayerInfo();
                newInfo.siteId = siteId;
                this.setSiteThemeLayerMetadata(newInfo, themeLayer);
                newInfo.siteValue = siteValue;
                if (themeLayer.title === "Alt Fuel Corridors")
                    newInfo.siteValue = `Phase ${siteValue}`;
                if (themeLayer.title === "Multi-Family Housing")
                    newInfo.siteValue = parseFloat(siteValue).toFixed(2);
                infoList.push(newInfo);
            }
        } else {
            siteValue = (siteValue == "") ? "-" : siteValue;
            infoList = [];
            const newInfo = new SiteThemeLayerInfo();
            newInfo.siteId = siteId;
            this.setSiteThemeLayerMetadata(newInfo, themeLayer);
            newInfo.siteValue = siteValue;
            if (themeLayer.title === "Alt Fuel Corridors")
                newInfo.siteValue = (siteValue == "-") ? "-" :`Phase ${siteValue}`;
            infoList.push(newInfo);
            this.cache.set(siteId, infoList);
        }
        this.setCurrentSiteThemeLayerData(siteId);
        this.setThemeLayerData(this.cache);
    }

    setCurrentSiteThemeLayerData(siteId: number) {
        this.currentSiteThemeLayerData = this.cache.get(siteId);
    }

    updateLayerAttributes(siteId: number, themeLayer: ThemeLayer, fields: any, attributes: any) {
        let infoList = this.cache.get(siteId);
        if (!infoList) return;
        const existing = infoList.find(i => i.layer.layerName === themeLayer.title);
        if (!existing) return;
        existing.attributes = attributes;
        existing.fields = fields;
    }

    trySendSiteDataForAllLayers(siteId: number) : boolean {
        let siteExists = false;
        let dataExists = false;
        const list = this.themeLayersSubject.value;
        if (this.cache.has(siteId) && siteId > 0) {
            siteExists = true;
            const cachedInfo = this.cache.get(siteId);
            if (cachedInfo && cachedInfo.length == list.length) {
                dataExists = true;

                this.allThemeLayerDataPosted.next(cachedInfo);
                return true;
            }
        }

        return false;
    }

    requestAllThemeLayerDataExport() {
        this.allThemeLayerDataExportRequested.next();
    }

    postAllThemeLayerDataExport(siteIds: number[]) {
        const map = new Map<number, SiteThemeLayerInfo[]>();
        siteIds.forEach(siteId => {
            const cachedInfo = this.cache.get(siteId);
            if (cachedInfo) {
                map.set(siteId, cachedInfo);
            }
        });
        this.setThemeLayerData(map);
        this.setAllThemeLayerDataGathered(true);
        this.allThemeLayerDataExportPosted.next(map);
    }

    setThemeLayerData(data: Map<number, SiteThemeLayerInfo[]>): void {
        this.themeLayerData = data;
    }

    setAllThemeLayerDataGathered(value: boolean): void {
        this.allThemeLayerDataGathered = value;
    }

}
