import { DecimalPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { EvSiteRequest } from 'src/app/models/ev/SiteValueRequest.model';
import { Condition } from 'src/app/models/ev/condition.model';
import { EvSite, SiteDataForExport } from 'src/app/models/ev/evSite.model';
import { FilterSitesByAttributeRequest } from 'src/app/models/ev/filter-sites-by-attribute-request';
import { EvSiteFilter, FilterComparisonEnum, comparisonFunctions } from 'src/app/models/ev/filter.model';
import { SiteProperty } from 'src/app/models/ev/siteProperty';
import { SiteThemeLayerInfo } from 'src/app/models/shared/theme-layer.model';
import { CapitalizeWithSpacesPipe } from 'src/app/pipes/capitalize-with-spaces.pipe';
import { insertAtIndex } from 'src/app/utils/array-utils';
import { convertJsonToMap } from 'src/app/utils/map-utils';
import { checkForUndefinedParameters } from 'src/app/utils/parameter-utils';
import { AgencyService } from '../../shared/agency.service';
import { MapInteractionService } from '../../shared/map-interaction.service';
import { EvDataService } from '../data-services/ev-data.service';
import { FilterAttributeLogicService } from './filter-attribute-logic.service';
import { EvLogicServiceModel } from './models/ev-logic-service-model';

@Injectable({
  providedIn: 'root'
})
export class EvLogicService {
    public model: EvLogicServiceModel;
    public showSiteDetailsPage$: Subject<boolean> = new Subject<boolean>();
    public allSitesComplete$: Subject<Map<number,EvSite>> = new Subject<Map<number,EvSite>>();
    public chartDataAcquired$: Subject<boolean> = new Subject<boolean>();

    get agencyName(): string {
        return this._agencyService.model.agencyName;
    }

    constructor(
        private _evDataService: EvDataService,
        private _filterAttributeService: FilterAttributeLogicService,
        private _capitilizeWithSpacesPipe: CapitalizeWithSpacesPipe,
        private _decimalPipe: DecimalPipe,
        private _mapInteractionService: MapInteractionService,
        private _agencyService: AgencyService,
    ) {
        this.initModel()
    }

    initModel(): void {
        if (this.model === undefined) {
            this.model = new EvLogicServiceModel();
        }
    }

    getAllSites(): void {
        this._evDataService.getAllSites()
        .subscribe({
            next: (sites) => {
                this.setSites(convertJsonToMap(sites))},
            error: (error) => console.log(error),
            complete: () => {
                this.getPresetSiteValues();
                this.allSitesComplete$.next(this.model.sites);
            }
        });
    }
    getPresetSiteValues(): void {
        this._evDataService.getPresetSiteValues(this.model.censusIds, this.model.blockIds)
        .subscribe({
            next: (collection) => {
                this.setSiteValuesAndScoresFromBlockGroup(convertJsonToMap(collection.blockGroupScores), false);
                if (this.agencyName !== "Phillips66")
                    this.setSiteValuesAndScoresFromBlock(convertJsonToMap(collection.blockScores), false);
                else
                    this.setSiteValuesAndScoresFromSite(convertJsonToMap(collection.siteProximityScores), false);
            },
            error: (err) => console.log(err),
            complete: () => {
                this.calculateAllSiteAverages();
                this.countAdjustedAndPresetOccurences();
            }
        });
    }

    getAdjustedSiteScores(): void {
        const request = {conditions: this.model.conditions, censusIds: this.model.censusIds, blockIds: this.model.blockIds, isNested: this.model.isNested}
        if (this.agencyName === "Phillips66") {
            if (this.model.filteredSites.size > 0)
                request["siteIds"] = Array.from(this.model.filteredSites.keys());
            else
                request["siteIds"] = Array.from(this.model.sites.keys());
        }
        this._evDataService.getAdjustedSiteScores(request)
        .subscribe({
            next: (collection) => {
                this.setSiteValuesAndScoresFromBlockGroup(convertJsonToMap(collection.blockGroupScores), true);
                if (this.agencyName !== "Phillips66")
                    this.setSiteValuesAndScoresFromBlock(convertJsonToMap(collection.blockScores), true);
                else
                    this.setSiteValuesAndScoresFromSite(convertJsonToMap(collection.siteProximityScores), true);
            },
            error: (err) => console.log(err),
            complete: () => {
                this.countAdjustedOccurences();
                this.calculateStateWideAverages();
                this.getFilteredSites(this.model.conditions);
            }
        })
    }
// here
    getScoresAndValuesForPinDrop(groupInfo): void {
        const request: EvSiteRequest = {conditions: this.model.conditions, censusIds: [groupInfo.id], blockIds: [groupInfo.blockId], isNested: this.model.isNested}
        this._evDataService.getScoresAndValuesForPinDrop(request)
        .subscribe({
            next: (siteValuesAndScores) => this.addPinSite(groupInfo, siteValuesAndScores),
            error: (err) => {throw new Error(err)}
        })
    }

    getFilteredSites(conditions: Condition[]): void {
        let blockGroupIds = [];
        let blockIds = [];
        let siteIds = [];
        if (this.model.filteredSites.size > 0) {
            for (let site of this.model.filteredSites.values()) {
                blockGroupIds.push(site.geoId);
                blockIds.push(site.blockGeoId);
                siteIds.push(site.siteId);
            }
        } else {
            // Send all block IDs and census IDs if no sites are filtered to limit SQL searching
            blockGroupIds = this.model.censusIds;
            blockIds = this.model.blockIds;
            siteIds = Array.from(this.model.sites.keys());
        }
        const request = {conditions: conditions, censusIds: blockGroupIds, blockIds: blockIds, isNested: this.model.isNested}
        if (this.agencyName === "Phillips66")
            request["siteIds"] = siteIds;
        this._evDataService.getFilteredSites(request)
        .subscribe({
            next: (filterCollection: any) =>{
                this.removeEvPageLoader()
                this.setFilteredSitesFromGeoIdsBlockIds(filterCollection.blockGroupIds, filterCollection.blockIds, filterCollection.siteIds)
            },
            error: (err) => console.log(err)
        })
    }

    getFilteredSitesByAttributes(request: FilterSitesByAttributeRequest): void {
        this._evDataService.getFilteredSitesByAttributes(request.filters)
        .subscribe({
            next: (siteIds: number[]) => this.handleFilterResults(siteIds, request),
            error: (err) => { throw new Error(err); }
        });
    }

    filteredSitesByAttributes(request: FilterSitesByAttributeRequest): void {
        if (this.shouldOnlyFilterBasedOnScore(request)) return; else this.getFilteredSitesByAttributes(request);
    }

    shouldOnlyFilterBasedOnScore(request: FilterSitesByAttributeRequest): boolean {
        if (request.filters.length > 0) return false;
        const siteIds = Array.from(this.model.sites.keys());
        this.setFilteredSitesBySiteIds(siteIds, request.evSiteFilters, request.scoreFilter);
        return true;
    }

    handleFilterResults(siteIds: number[], request: FilterSitesByAttributeRequest): void {
        this.removeEvPageLoader();
        this.setFilteredSitesBySiteIds(siteIds, request.evSiteFilters, request.scoreFilter);
    }

    removeEvPageLoader(): void {
        let loader = document.getElementById('evPageLoader');
        loader.classList.remove('is-active');
    }

    setCensusIds(): void {
        const sites = this.model.sites;
        this.model.censusIds = Array.from(sites.keys()).map((key) => sites.get(key)!.geoId);
        this.model.blockIds = Array.from(sites.keys()).map((key) => sites.get(key)!.blockGeoId);
    }

    setSites(sites: Map<number, EvSite>): void {
        this.model.sites = sites;
        this.model.site = sites.entries().next().value[1];
        this.setCensusIds();
        this.configureFilterAttributes(this.model.sites);
        this.setGeoToSiteIdMap();
    }

    configureFilterAttributes(sites: Map<number, EvSite>): void {
        this._filterAttributeService.configureFilterAttributes(sites);
    }

    setFilteredSitesBySiteIds(siteIds: number[], evSiteFilters: EvSiteFilter[], scoreFilter: EvSiteFilter): void {
        let filteredSiteIds = scoreFilter ? this.filterSiteIdsOnFinalScore(siteIds, scoreFilter) : siteIds;
        this.resetFilteredSitesAndExcelExportSites();
        this.addExcelExportSites(filteredSiteIds);
        this.addFilteredSites(filteredSiteIds);
        this.esriMapFilterPoints(filteredSiteIds, 'attribute', evSiteFilters.length > 0, filteredSiteIds.length > 0);
    }

    filterSiteIdsOnFinalScore(siteIds: number[], scoreFilter: EvSiteFilter): number[] {
        const comparison = scoreFilter.comparisonType;
        const score: number = parseInt(scoreFilter.filterValue);
        let filteredSiteIds: number[] = [];
        siteIds.forEach((siteId) => {
            const site = this.model.sites.get(siteId);
            if (this.sitePassesScoreFilter(site, comparison, score)) filteredSiteIds.push(siteId);
        })
        return filteredSiteIds;
    }

    sitePassesScoreFilter(site: EvSite, comparison: FilterComparisonEnum, score: number): boolean {
        const comparisonFunction = comparisonFunctions[comparison];
        return comparisonFunction ? comparisonFunction(site.finalScoreNum, score) : false;
      }

    addFilteredSites(siteIds: number[]): void {
        siteIds.forEach((siteId: number) => {
            this.addFilteredSite(siteId);
        })
    }

    addExcelExportSites(siteIds: number[]): void {
        siteIds.forEach((siteId: number) => {
            this.addExcelExportSite(siteId);
        })
    }

    addExcelExportSite(siteId: number): void {
        const site = this.model.sites.get(siteId)
        if (site) this.model.excelExportSites.set(siteId, site);
    }

    addFilteredSite(siteId: number): void {
        const site = this.model.sites.get(siteId)
        if (site) this.model.filteredSites.set(siteId, site);
    }

    resetFilteredSitesMap(): void {
        this.model.filteredSites = new Map<number, EvSite>();
    }

    resetExcelExportSitesMap(): void {
        this.model.excelExportSites = new Map<number, EvSite>();
    }

    resetFilteredSitesAndExcelExportSites(): void {
        this.resetFilteredSitesMap();
        this.resetExcelExportSitesMap();
    }

    setFilteredSitesFromGeoIdsBlockIds(geoIds: number[], blockIds: number[], apiSiteIds: number[]): void {
        checkForUndefinedParameters({geoIds, blockIds});
        let siteIds: number[] = this.getSiteIdsFromGeoIds(geoIds);
        if (this.agencyName === "Phillips66")
            siteIds = siteIds.concat(apiSiteIds);
        else
            siteIds = siteIds.concat(this.getSiteIdsFromBlockIds(blockIds));
        this.resetFilteredSitesAndExcelExportSites();
        siteIds = this.filterOutSiteIdsWithScoreLessThan5(siteIds);
        this.addExcelExportSites(siteIds);
        this.esriMapFilterPoints(siteIds, 'performance', false, siteIds.length > 0);
    }

    filterOutSiteIdsWithScoreLessThan5(siteIds: number[]): number[] {
        return siteIds.filter(siteId => this.model.sites.get(siteId)!.adjustedScoreNum === 5);
    }

    getSiteIdsFromGeoIds(geoIds: number[]): number[] {
        checkForUndefinedParameters({geoIds});
        return this.getAndAddFilteredSitesFromMap(geoIds, this.model.geoToSiteIdMap);
    }

    getSiteIdsFromBlockIds(blockIds: number[]): number[] {
        checkForUndefinedParameters({blockIds});
        return this.getAndAddFilteredSitesFromMap(blockIds, this.model.blockToSiteIdMap);
    }

    getAndAddFilteredSitesFromMap(ids: number[], siteIdMap: Map<number, number[]>): number[] {
        let siteIds: number[] = [];
        ids.forEach((id) => {
            const siteIdsFromMap: number[] = siteIdMap.get(id);
            if (!siteIdsFromMap) return;
            siteIdsFromMap.forEach((siteId) => {
                siteIds.push(siteId);
                this.addFilteredSite(siteId);
            });
        })
        return siteIds;
    }

    esriMapFilterPoints(includedIds: number[], filterType: string, goToExtent: boolean, hasMatches: boolean): void {
        checkForUndefinedParameters({includedIds, filterType, goToExtent});
        this._mapInteractionService.filterPoints(includedIds, filterType, goToExtent, hasMatches);
    }

    setSite(site: EvSite): void {
        const oldSite: EvSite = this.model.site;
        const oldState: string = oldSite.state;
        let oldDma: string;
        if (oldSite) {
            oldDma = oldSite.additionalProperties.get("dma")?.propertyValue;
        }
        this.model.site = site;
        const newDma: string = this.model.site.additionalProperties.get("dma")?.propertyValue;
        if (oldState !== site.state) this.calculateStateWideAverages();
        if (this.agencyName === "Phillips66" && oldDma !== newDma) this.calculateDmaSiteAverages();
        this.setSiteValueColors();
        this.showSiteDetailsPage$.next(true)
    }

    getSiteById(id: number): void {
        if (this.model.sites.has(id)) {
            this.setSite(this.model.sites.get(id));
        }
    }

    setGeoToSiteIdMap(): void {
        for (const [siteId, site] of this.model.sites) {
            if (this.model.geoToSiteIdMap.has(site.geoId))
                this.model.geoToSiteIdMap.get(site.geoId).push(siteId)
            else
                this.model.geoToSiteIdMap.set(site.geoId, [siteId]);
            if (this.model.blockToSiteIdMap.has(site.blockGeoId))
                this.model.blockToSiteIdMap.get(site.blockGeoId).push(siteId);
            else
                this.model.blockToSiteIdMap.set(site.blockGeoId, [siteId]);
        }
    }

    setSiteValuesAndScoresFromBlockGroup(geoValuesAndScores: Map<number, Map<string, number[]>>, filtersAdjusted: boolean): void {
        for (const[geoId, valuesAndScores] of geoValuesAndScores) {
            const siteIds = this.model.geoToSiteIdMap.get(geoId);
            if (siteIds) {
                let [finalScoreNum, adjustedScoreNum, siteValues] = [valuesAndScores["presetFinalScore"][0], valuesAndScores["adjustedFinalScore"][0], this.formatScoreArray(valuesAndScores["siteValues"])];
                let finalScore = `${finalScoreNum}:${this.model.scoreDescriptions.get(finalScoreNum)}`;
                let adjustedScore = filtersAdjusted ? `${adjustedScoreNum}:${this.model.scoreDescriptions.get(adjustedScoreNum)}` : '-';
                siteIds.forEach(siteId => {
                    let site = this.model.sites.get(siteId);
                    site = {...this.model.sites.get(siteId), finalScore: finalScore, adjustedScore: adjustedScore, siteValues: siteValues, finalScoreNum: finalScoreNum, adjustedScoreNum: adjustedScoreNum};
                    this.model.sites.set(siteId, site);
                })
            }
        }
    }

    setSiteValuesAndScoresFromBlock(blockScores: Map<number, Map<string, number[]>>, filtersAdjusted: boolean) {
        for (const[geoId, valuesAndScores] of blockScores) {
            const siteIds = this.model.blockToSiteIdMap.get(geoId);
            if (siteIds) {
                let [finalScoreNum, adjustedScoreNum, siteValues] = [valuesAndScores["presetFinalScore"][0], valuesAndScores["adjustedFinalScore"][0], valuesAndScores["siteValues"]];
                siteIds.forEach(siteId => {
                    let site = this.model.sites.get(siteId);
                    const updatedFinalScore = site.finalScoreNum + finalScoreNum;

                    const updatedAdjScore = site.adjustedScoreNum + adjustedScoreNum;
                    let finalScore = `${updatedFinalScore}:${this.model.scoreDescriptions.get(updatedFinalScore)}`;
                    let adjustedScore = filtersAdjusted ? `${updatedAdjScore}:${this.model.scoreDescriptions.get(updatedAdjScore)}` : "-";
                    site.siteValues = site.siteValues.concat(siteValues);
                    site = {...this.model.sites.get(siteId), finalScore: finalScore, adjustedScore: adjustedScore, siteValues: site.siteValues, finalScoreNum: updatedFinalScore, adjustedScoreNum: updatedAdjScore};
                    this.model.sites.set(siteId, site);
                })
            }
        }
    }

    setSiteValuesAndScoresFromSite(siteScores: Map<number, Map<string, number[]>>, filtersAdjusted: boolean) {
        for (const[siteId, valuesAndScores] of siteScores) {
            let [finalScoreNum, adjustedScoreNum, siteValues] = [valuesAndScores["presetFinalScore"][0], valuesAndScores["adjustedFinalScore"][0], valuesAndScores["siteValues"]];
            let site = this.model.sites.get(siteId);
            const updatedFinalScore = site.finalScoreNum + finalScoreNum;

            const updatedAdjScore = site.adjustedScoreNum + adjustedScoreNum;
            let finalScore = `${updatedFinalScore}:${this.model.scoreDescriptions.get(updatedFinalScore)}`;
            let adjustedScore = filtersAdjusted ? `${updatedAdjScore}:${this.model.scoreDescriptions.get(updatedAdjScore)}` : "-";
            site.siteValues = site.siteValues.concat(siteValues);
            site = {...this.model.sites.get(siteId), finalScore: finalScore, adjustedScore: adjustedScore, siteValues: site.siteValues, finalScoreNum: updatedFinalScore, adjustedScoreNum: updatedAdjScore};
            this.model.sites.set(siteId, site);
        }
    }

    addPinSite(siteInfo: any, update: any) {
        const pinSite = new EvSite();
        pinSite.address = siteInfo.address;
        pinSite.city = siteInfo.city;
        pinSite.state = siteInfo.state;
        pinSite.zip = siteInfo.zip;
        const blockGroupScores = update.blockGroupScores[siteInfo.id];
        const blockScores = update.blockScores[siteInfo.blockId];
        const finalScoreNum = blockGroupScores.presetFinalScore[0] + blockScores.presetFinalScore[0];
        const adjustedScoreNum = blockGroupScores.adjustedFinalScore[0] + blockScores.adjustedFinalScore[0];
        pinSite.finalScore = `${finalScoreNum}:${this.model.scoreDescriptions.get(finalScoreNum)}`;
        pinSite.adjustedScore = `${adjustedScoreNum}:${this.model.scoreDescriptions.get(adjustedScoreNum)}`;
        pinSite.siteValues = this.formatScoreArray(blockGroupScores.siteValues).concat(blockScores.siteValues);
        pinSite.additionalProperties = new Map<string, SiteProperty>();
        if (this.agencyName === "Phillips66") {
            let tempProperty = new SiteProperty();
            tempProperty.propertyType = 'string';
            tempProperty.propertyValue = '';
            pinSite.additionalProperties.set('mrn', tempProperty);

            tempProperty = new SiteProperty();
            tempProperty.propertyType = 'string';
            tempProperty.propertyValue = '';
            pinSite.additionalProperties.set('dma', tempProperty);
        }

        this.setSite(pinSite);
    }

    getSitesByState(): EvSite[] {
        if (this.model.site.state === undefined ) this.model.site = this.model.sites.entries().next().value[1];
        return Array.from(this.model.sites.values()).filter(site => site.state.includes(this.model.site.state))
    }

    setConditionsAndAdjustedParameters(conditions: Condition[]): void {
        conditions.forEach(condition => {
            this.setCondition(condition);
            this.setAdjustedParameter(condition);
        });
    }

    setAdjustedParameter(condition: Condition): void {
        switch(condition.conditionId-1) {
            case(0):
                this.model.siteCharacteristics[0].threshold2 = "≥ " + this.formatNumber(parseInt(condition.inputValue, 10)).toLocaleString();
                break;
            case(1):
                let incomes = ["$60k","$75k","$85k","$100k","$120k"]
                this.model.siteCharacteristics[1].threshold2 = "≥ " + incomes[condition.stepId-1];
                this.model.siteCharacteristics[2].threshold2 = "≥ " + this.formatNumber(parseInt(condition.inputValue, 10)).toLocaleString();
                break;
            case(2):
                let miles = ["5","10","15","20","25"]
                this.model.siteCharacteristics[3].threshold2 = "≥ " + miles[condition.stepId-1];
                this.model.siteCharacteristics[4].threshold2 = "≥ " + this.formatNumber(parseInt(condition.inputValue, 10)).toLocaleString();
                break;
            case(3):
                this.model.siteCharacteristics[5].threshold2 = "≤ " + condition.inputValue.toLocaleString();
                break;
            case(4):
                this.model.siteCharacteristics[6].threshold2 = "≤ " + condition.inputValue.toLocaleString();
                break;
        }
    }

    formatNumber(numberToFormat: number): string {
        return this._decimalPipe.transform(numberToFormat, '1.0-2');
    }

    setCondition(condition: Condition): void {
        this.model.conditions[condition.conditionId-1]["inputValue"] = condition.inputValue;
        this.model.conditions[condition.conditionId-1]["stepId"] = condition.stepId;
    }

    setSiteValueColors(): void {
        for(let i = 0; i < this.model.site.siteValues.length; i++) {
            if (this.model.siteCharacteristics[i].threshold2 !== "-") {
                        let conditionValue = this.model.siteCharacteristics[i].threshold2.substring(this.model.siteCharacteristics[i].threshold2.indexOf(" ") + 1).split(',').join('');
                        if (this.model.siteCharacteristics[i].siteCharacteristic !== "Maximum Proximity to Highway Interchange" &&
                            this.model.siteCharacteristics[i].siteCharacteristic !== "Maximum Proximity to High Traffic Roads" ) {
                                this.model.siteCharacteristics[i].thresholdPassed  = this.model.site.siteValues[i] > Number(conditionValue);
                            } else {
                                this.model.siteCharacteristics[i].thresholdPassed  = this.model.site.siteValues[i] <= Number(conditionValue);
                            }
                    } else {
                        let conditionValue = this.model.siteCharacteristics[i].threshold1.substring(this.model.siteCharacteristics[i].threshold1.indexOf(" ") + 1).split(',').join('');
                        if (this.model.siteCharacteristics[i].siteCharacteristic !== "Maximum Proximity to Highway Interchange" &&
                        this.model.siteCharacteristics[i].siteCharacteristic !== "Maximum Proximity to High Traffic Roads" ) {
                            this.model.siteCharacteristics[i].thresholdPassed  = this.model.site.siteValues[i] > Number(conditionValue);
                            } else {
                                this.model.siteCharacteristics[i].thresholdPassed  = this.model.site.siteValues[i] <= Number(conditionValue);
                            }
                    }
        }
    }

    calculateStateWideAverages(): void {
        this.resetStateWideAverages();
        let stateWideSites = this.getSitesByState();
        let sitesWithFSVPresent = stateWideSites.length;
        stateWideSites.forEach(site => {
            const fsv = site.additionalProperties.get('fuelSalesVolume')?.propertyValue;
            if (fsv === "-") {sitesWithFSVPresent -= 1 };
            this.model.fsvStateAvg += (fsv !== "-") ? parseInt(site.additionalProperties.get('fuelSalesVolume')?.propertyValue) : 0;
            for(let i = 0; i<7; i++) {
                this.model.stateWideAvgScores[i] =  this.model.stateWideAvgScores[i] + site["siteValues"][i];
            }
        });
        for (let i = 0; i<7; i++) {
            this.model.stateWideAvgScores[i] = this.model.stateWideAvgScores[i] !== 0 ? Math.round(this.model.stateWideAvgScores[i]/stateWideSites.length) : null;
        }
        this.model.fsvStateAvg = Math.round(this.model.fsvStateAvg/sitesWithFSVPresent);
    }

    calculateAllSiteAverages(): void {
        this.model.sites.forEach((site) => {
            for(let i = 0; i<7; i++) {
                this.model.allSiteAvgScores[i] = this.model.allSiteAvgScores[i] + site["siteValues"][i];
            }
        });
        for (let i = 0; i<7; i++) {
            this.model.allSiteAvgScores[i] = this.model.allSiteAvgScores[i] !== 0 ? Math.round(this.model.allSiteAvgScores[i]/this.model.sites.size) : null;
        }
    }

    calculateDmaSiteAverages(): void {
        this.resetDmaAverages();
        const dmaSites = this.getSitesByDma();
        let sitesWithFSVPresent = dmaSites.length;
        dmaSites.forEach((site) => {
            const fsv = site.additionalProperties.get('fuelSalesVolume')?.propertyValue;
            if (fsv === "-") {sitesWithFSVPresent -= 1 };
            this.model.fsvDmaAvg += (fsv !== "-") ? parseInt(site.additionalProperties.get('fuelSalesVolume')?.propertyValue) : 0;
            for(let i = 0; i<7; i++) {
                this.model.dmaAvgScores[i] =  this.model.dmaAvgScores[i] + site["siteValues"][i];
            }
            for (let i = 0; i<7; i++) {
                this.model.dmaAvgScores[i] = this.model.dmaAvgScores[i] !== 0 ? Math.round(this.model.dmaAvgScores[i]/dmaSites.length) : null;
            }
        })
        this.model.fsvDmaAvg = Math.round(this.model.fsvDmaAvg/sitesWithFSVPresent);
    }

    getSitesByDma(): EvSite[] {
        if (this.model.site === undefined ) this.model.site = this.model.sites.entries().next().value[1];
        const dma: string = this.model.site.additionalProperties.get("dma").propertyValue;
        return Array.from(this.model.sites.values()).filter(site => site.additionalProperties.get("dma").propertyValue.includes(dma));
    }

    resetDmaAverages(): void {
        this.model.dmaAvgScores = [0,null,0,null,0,0,0];
        this.model.fsvDmaAvg = 0;
    }

    resetStateWideAverages(): void {
        this.model.stateWideAvgScores = [0,null,0,null,0,0,0];
        this.model.fsvStateAvg = 0;
    }

    formatScoreArray(arr: number[]): number[] {
        arr = insertAtIndex(arr, 1, null);
        arr = insertAtIndex(arr, 3, null);
        return arr;
    }

    countAdjustedAndPresetOccurences(): void {
        this.countPresetOccurences();
        this.countAdjustedOccurences();
        this.chartDataAcquired$.next(true);
    }

    countAdjustedOccurences(): void {
        this.resetAdjustedScoreBarChart();
        this.model.sites.forEach((site => {
            let key = site.adjustedScore;
            if (this.model.adjustedScoreBarChart.has(key)) {
                let currentValue = this.model.adjustedScoreBarChart.get(key);
                this.model.adjustedScoreBarChart.set(key, currentValue + 1)
            } else {
                this.model.adjustedScoreBarChart.set(key,1);
            }
        }))
        this.chartDataAcquired$.next(true);
    }

    countPresetOccurences(): void {
        this.model.sites.forEach((site => {
            let key = site.finalScore;
            if (this.model.presetScoreBarChart.has(key)) {
                let currentValue = this.model.presetScoreBarChart.get(key);
                this.model.presetScoreBarChart.set(key, currentValue + 1)
            }
        }))
        this.chartDataAcquired$.next(true);
    }

    resetAdjustedScoreBarChart(): void {
        this.model.adjustedScoreBarChart = new Map<string,number>([
            ["0:Low",0],
            ["1:Medium-Low",0],
            ["2:Medium",0],
            ["3:Medium-High",0],
            ["4:High",0],
            ["5:Top Site",0],
        ]);
    }

    formatSiteDataForExport(themeLayerData: Map<number, SiteThemeLayerInfo[]>): SiteDataForExport[] {
        checkForUndefinedParameters({themeLayerData});
        if (this.model.excelExportSites.size === 0) this.model.excelExportSites = this.model.sites;
        const formattedData = [];

        this.model.excelExportSites.forEach((site, siteId) => {
            const siteData = this.createSiteDataForExport(site);
            this.appendAdditionalPropertiesToExport(siteData, site.additionalProperties);
            this.appendThemeLayerDataToExport(siteData, themeLayerData.get(siteId));

            formattedData.push(siteData);
        });
        return formattedData;
    }

    private createSiteDataForExport(site: EvSite): SiteDataForExport  {
        return {
            'Site Id': site.siteId,
            Address: site.address,
            City: site.city,
            County: site.county,
            State: site.state,
            'Zip Code': site.zip,
            'Preset Score': site.finalScore,
            'Adjusted Final Score': site.adjustedScore
        };
    }

    private appendAdditionalPropertiesToExport(siteData: SiteDataForExport, additionalProperties: Map<string, SiteProperty>): void {
        additionalProperties.forEach((siteProperty: SiteProperty, key: string) => {
            siteData[this._capitilizeWithSpacesPipe.transform(key)] = siteProperty.propertyValue;
        });
    }

    private appendThemeLayerDataToExport(siteData: SiteDataForExport, themeLayerInfo: SiteThemeLayerInfo[]): void {
        for (let themeLayer of themeLayerInfo) {
            if (this.isThemeLayerHousing(siteData, themeLayer)) continue;
            const displayName: string = themeLayer.layer.tooltipText;
            const value: string = themeLayer.siteValue;
            this.setSiteDataProperty(siteData, displayName, value);
        }
    }

    private isThemeLayerHousing(siteData: SiteDataForExport, themeLayerInfo: SiteThemeLayerInfo): boolean {
        if(themeLayerInfo.layer.layerName !== 'Multi-Family Housing') return false;

        for (let i = 3; i < themeLayerInfo.fields.length-4; i++) {
            const field = themeLayerInfo.fields[i];
            const displayName: string = `Multifamily Housing ${field.alias}`;
            const value: string = themeLayerInfo.attributes[field.name];
            this.setSiteDataProperty(siteData, displayName, value);
        }
        return true;
    }

    private setSiteDataProperty(siteData: SiteDataForExport, propName: string, value: string | number): void {
        siteData[propName] = value ? value : '-' ;
    }

}
