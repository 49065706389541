import { Injectable } from '@angular/core';
import { from, of, Subject } from 'rxjs';
import * as locator from "@arcgis/core/rest/locator";
import Point from '@arcgis/core/geometry/Point';
import { NotificationService, NotificationType, NotificationCloseType } from './notification.service';

export interface EsriLocatorResult {
    isCollection: boolean;
    magicKey: string;
    text: string;
}

@Injectable({
    providedIn: 'root'
})
export class EsriLocationService {
    private locationUpdateSource = new Subject<Point>();

    locatorUrl = "https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer";
    locationUpdated$ = this.locationUpdateSource.asObservable();

    constructor(private notificationService: NotificationService) {
    }

    getAddresses(term: string = null) {
        if (term) {
            return from(locator.suggestLocations(this.locatorUrl, {text: term, categories: ['address'], location: null }));
        } else {
            return of([]);
        }
    }

    getLocationFromAddress(result: EsriLocatorResult) {
        if (!result) return;
        let that = this;
        locator.addressToLocations(this.locatorUrl, {address: result.text, magicKey: result.magicKey})
            .then(response => {
                if (response.length) {
                    that.locationUpdateSource.next(response[0].location)
                }
            })
            .catch(err => {
                this.notificationService.showNotification(NotificationType.Error,
                    "Could not get address. Please try again.",
                    NotificationCloseType.DeleteButton | NotificationCloseType.Self);
            });
    }

    getLocationFromCoordinates(point: Point, attribute: string) : Promise<string> {
        if (!point) return Promise.resolve('Not a point');
        return locator.locationToAddress(this.locatorUrl, {location: point, locationType: 'street'})
            .then(response => {
                if (response) {
                    return new Promise<string>(resolve => {
                        if (attribute === null)
                            resolve(response.attributes);
                        else resolve(response.attributes[attribute]);
                    });
                } else {
                    return new Promise<string>(resolve => {
                        resolve('Error');
                    });
                }
            }).catch(() => {
                this.notificationService.showNotification(NotificationType.Error,
                    "Could not get county.",
                    NotificationCloseType.DeleteButton | NotificationCloseType.Self);
                return new Promise<string>(resolve => {
                    resolve('Error');
                });
            });
    }
}
