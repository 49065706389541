export function checkForUndefinedParameters(params: { [key: string]: any }): void {
    const undefinedParams = [];

    for (const key in params) {
        if (params[key] === undefined) {
            undefinedParams.push(key);
        }
    }

    if (undefinedParams.length > 0) {
        throw new Error(`The following required parameter(s) is/are undefined: ${undefinedParams.join(', ')}`);
    }
}
