import { Component, OnInit } from '@angular/core';
import Chart from 'chart.js/auto';
import { Subject, takeUntil } from 'rxjs';
import { EvLogicService } from 'src/app/services/ev/logic-services/ev-logic.service';

@Component({
  selector: 'app-site-details-chart',
  templateUrl: './site-details-chart.component.html',
  styleUrls: ['./site-details-chart.component.scss']
})
export class SiteDetailsChartComponent implements OnInit {
    private _componentDestroyed$: Subject<boolean> = new Subject<boolean>();
    public chart: any;

    get presetChartScores() {
        return this._evLogicService.model.presetScoreBarChart;
    }

    get adjustedChartScores() {
        return this._evLogicService.model.adjustedScoreBarChart;
    }

    constructor(private _evLogicService: EvLogicService) { }

    ngOnInit(): void {
        this.createChart();
        this._evLogicService.chartDataAcquired$.pipe(takeUntil(this._componentDestroyed$))
        .subscribe((chartDataAcquired) => {
            this.chart.data.datasets[0].data = Array.from(this.presetChartScores.values());
            this.chart.data.datasets[1].data = Array.from(this.adjustedChartScores.values());
            this.chart.update();
        })

    }

    ngOnDestory() {
        this._componentDestroyed$.next(true);
        this._componentDestroyed$.complete();
    }

    createChart(){
        this.chart = new Chart("MyChart", {
          type: 'bar',

          data: {
            labels: ["0: Low", "1: Medium Low", "2: Medium","3: Medium-High", "4: High", "5: Top Site"],
               datasets: [
              {
                label: "Based on Preset Thresholds",
                data: Array.from(this.presetChartScores.values()),
                backgroundColor: 'rgba(204,206,95,255)',
              },
              {
                label: "Based on Adjusted Thresholds",
                data: Array.from(this.adjustedChartScores.values()),
                backgroundColor: 'rgba(143,179,195,255)',
              }
            ]
          },
          options: {
            hover: {mode: null},
            responsive: true,
            maintainAspectRatio: true,
            aspectRatio:1.75,
            plugins: {
                title: {
                    display: true,
                    text: 'Number of U.S. Sites by EV Charging Site Score',
                    padding: {
                        top: 10,
                        bottom: 10
                    },
                    font: {
                        weight: 'bold',
                        family: "'Roboto' ,sans-serif",
                        size: 14
                    },
                    color: '#000000',
                },
                legend: {
                    labels: {
                        boxWidth: 10,
                        boxHeight: 10,
                        font: {
                            family: "'Roboto' ,sans-serif",
                        }
                    }
                }
            },
            scales: {
                y: {
                    title : {
                        display: true,
                        text: 'Number of Sites',
                        color: "#000000",
                        font: {
                            weight: 'bold',
                            family: "'Roboto' ,sans-serif",
                            size: 14
                        }
                    }
                },
                x: {
                    title: {
                        display: true,
                        text: 'EV Charging Site Score',
                        color: "#000000",
                        font: {
                            weight: 'bold',
                            family: "'Roboto' ,sans-serif",
                            size: 14
                        }
                    },
                }
            }
          }
        });
      }
}
