import { Injectable } from '@angular/core';
import { interval, Subject, } from 'rxjs';
import { filter, map, mapTo, switchMap, takeUntil, } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

    public resetTimer$: Subject<boolean> = new Subject<boolean>();
    public startTimer$: Subject<boolean> = new Subject<boolean>();
    public showMessage$: Subject<boolean> = new Subject<boolean>();

    constructor() {
        this.startTimer$.pipe(
            filter(Boolean),
            switchMap(() => interval(1000).pipe(
                takeUntil(this.resetTimer$),
                map(n => 20 * 60 * 1000 - n * 1000),
                filter(n => n <= 0),
                mapTo(true)
            ))
        ).subscribe(this.showMessage$)
     }

    getAccessToken(): string {
        return sessionStorage.getItem('accessToken')
    }

    setAccessToken(accessToken: string): void {
        sessionStorage.setItem('accessToken', accessToken)
        this.startSessionTimer();
    }

    setIdToken(idToken: string): void {
        sessionStorage.setItem('idToken', idToken)
    }

    getIdToken(): string {
        return sessionStorage.getItem('idToken')
    }

    resetIdToken() {
        sessionStorage.removeItem('idToken')
    }

    resetAccessToken() {
        sessionStorage.removeItem('accessToken')
    }

    startSessionTimer() {
        this.startTimer$.next(true);
    }

    stopSessionTimer() {
        this.startTimer$.next(false);
    }

    resetSessionTimer() {
        this.resetTimer$.next(true);
    }

    sessionSignout() {
        this.resetIdToken();
        this.resetAccessToken();
        this.stopSessionTimer();
    }
}
