import { CustomStepDefinition, Options } from '@angular-slider/ngx-slider';
import { Injectable } from '@angular/core';
import { ScreenInput } from 'src/app/models/ev/screen-input.model';
import { ScreenInputDataService } from '../data-services/screen-input-data.service';
import { ScreenInputLogicModel } from './models/screen-input-logic-model';

@Injectable({
  providedIn: 'root'
})
export class ScreenInputLogicService {
    public model: ScreenInputLogicModel;


    constructor(private _screenInputDataService: ScreenInputDataService) {
        this.initModel();
    }

    initModel(): void {
        if (this.model === undefined) {
            this.model = new ScreenInputLogicModel();
        }
    }

    getScreenInputs(): any {
        this._screenInputDataService.getScreenInputs()
        .subscribe({
            next: (screenInputs: ScreenInput[]) => this.setScreenInputs(screenInputs),
            error: (err) => console.log(err)
        })
    }

    setScreenInputs(screenInputs: ScreenInput[]): void {
        screenInputs.forEach(input => {
            this.createScreenInput(input);
        });
    }

    createScreenInput(screenInput: ScreenInput) {
        screenInput = new ScreenInput(screenInput);
        screenInput.isSelected = true;
        switch(screenInput.inputType) {
            case 'Slider':
                this.createSlider(screenInput);
                break;
            case 'SliderCombo':
                this.createSliderCombo(screenInput);
                break;
            case 'Number':
                screenInput.primaryStepAsNum = parseInt(screenInput.primaryStep);
                break;
        }
        this.model.screenInputs.push(screenInput);
    }

    createSlider(screenInput: ScreenInput): void {
        screenInput.primaryStepAsNum = parseInt(screenInput.primaryStep);
        let options: number[] = this.createOptionsFromPrimaryStepValues(screenInput);
        let ceiling: number = Math.max(...options);
        let stepsArray = screenInput.primaryStepValues.map((step: string): CustomStepDefinition => {
            return { value: screenInput.stepToNum(step)};
        })
        screenInput.sliderOptions = this.createSliderOptions(screenInput, options, ceiling, stepsArray);
    }

    createSliderCombo(screenInput: ScreenInput): void {
        screenInput.secondaryStepAsNum = parseInt(screenInput.secondaryStep);
        let options = this.createOptionsFromSecondaryStepValues(screenInput);
        let ceiling = Math.max(...screenInput.secondaryStepValues.map(step => {
            return screenInput.stepToNum(step);
        }));
        let stepsArray = screenInput.secondaryStepValues.map((step: string): CustomStepDefinition => {
            return { value: screenInput.stepToNum(step)};
        });
        screenInput.sliderOptions = this.createSliderOptions(screenInput, options, ceiling, stepsArray);
        if (screenInput.primaryUnits === '$') {
            screenInput.primaryStepValues = screenInput.primaryStepValues.map(val => {
                return screenInput.formatStepValue(1, val);
            });
        }
    }

    createOptionsFromPrimaryStepValues(screenInput: ScreenInput): number[] {
        let options: number[] = screenInput.primaryStepValues.map(val => {
            return screenInput.primaryStepValues.indexOf(val);
        });
        return options
    }

    createOptionsFromSecondaryStepValues(screenInput: ScreenInput): number[] {
        let options: number[] = screenInput.secondaryStepValues.map(val => {
            return screenInput.secondaryStepValues.indexOf(val);
        });
        return options
    }

    createSliderOptions(screenInput: ScreenInput, options: number[], ceiling: number, stepsArray: CustomStepDefinition[]): Options {
        screenInput.sliderOptions = {
            floor: 0,
            ceil: ceiling,
            ticksArray: options,
            stepsArray: stepsArray,
            translate: (value: number, label): string => {
                return screenInput.stepToString(value);
            },
            showTicks: true,
            showSelectionBar: true,
        }
        return screenInput.sliderOptions
    }
}
