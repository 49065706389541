import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BaseService } from '../shared/base-service.service';
import { NotificationService } from '../shared/notification.service';

interface IApiIteLandUse {
    ITECode: string,
    LandUseType: string,
    LandUseDescription: string,
    IndependentVariable: string,
    SettingLocation: string,
    NoOfUnits: number,
    DailyRate: number,
    ConvFactor: number,
    DailyTrips: number,
    LandUseA: string,
    WarningMessage: string,
    Enabled: boolean,
    DateCreated: string
}

export interface IIteLandUse {
    id: string;
    iteCode: string;
    name: string;
    category?: string;
    displayName: string;

    intensity?: number;
    defaultUnit?: string;
    metricDisplay?: string;
    isTotalVmt?: boolean;
    conversionFactor: number;

    vmt?: number;
    vmtWithMitigation?: number;
    totalVmt?: number;
    threshold?: number;
    significantImpact?: string;

    trips?: number;
    internalTrips?: number;
    internalCapture?: number;

    averageTripLength?: number;
}

export interface ILandUseRow extends IIteLandUse {
    rowId: number;
}

@Injectable({
    providedIn: 'root'
})
export class IteDataService extends BaseService {
    landUses: IIteLandUse[];
    landUses$: Observable<IIteLandUse[]>;
    constructor(http: HttpClient, notificationService: NotificationService) {
        super(http, notificationService);
        this.landUses = [];
    }

    async getLandUses(agencyID: string, term: string = null) {
        if (this.landUses.length === 0) {
            let allLandUses = await this.http.get<IApiIteLandUse[]>(`${environment.api.baseURL}/libLandUse/${agencyID}`).toPromise();
            this.landUses = allLandUses.map(x => {
                return { id: x.ITECode,
                    iteCode: x.ITECode,
                    name: x.LandUseA,
                    category: x.LandUseType,
                    displayName: `${x.ITECode} - ${x.LandUseA}`,
                    defaultUnit: x.IndependentVariable.trim(),
                    conversionFactor: x.ConvFactor
                };
            });
            this.landUses$ = of(this.landUses);
            return this.searchTerm(term);

        } else {
            return this.searchTerm(term);
        }
    }

    searchTerm(term: string = null) {
        if (term) {
            let items = this.landUses.filter(landUse => landUse.iteCode.includes(term) ||
                landUse.name.toLowerCase().includes(term.toLowerCase()));
            return of(items);
        } else {
            return this.landUses$;
        }
    }

    hasAirQuality(countyID: string, iteCode: string) {
        return this.http.get<boolean>(`${environment.api.baseURL}/libLandUse/airQualityExists/${countyID}/${iteCode}`)
            .pipe(catchError(this.handleError<boolean>('Could not determine emissions for this land use.', false)));
    }

}
