import { Injectable, SecurityContext } from '@angular/core';
import { SitePhotoLogicModel } from './models/site-photo-logic-model';
import { SitePhotoDataService } from '../data-services/site-photo-data.service';
import { checkForUndefinedParameters } from 'src/app/utils/parameter-utils';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BlobFileInfo, FileInfo } from 'src/app/models/ev/blob-file-info';

@Injectable({
  providedIn: 'root'
})
export class SitePhotoLogicService {
    public model: SitePhotoLogicModel;

    constructor(
        private _sitePhotoDataService: SitePhotoDataService,
        private _domSanitizer: DomSanitizer
    ) {
        this.initModel();
    }

    initModel(): void {
        if (this.model === undefined) this.model = new SitePhotoLogicModel();
    }

    toggleImage(isNext: boolean): void {
        const increment: number = isNext ? 1 : -1;
        this.setCurrentImageIndex(increment);
        this.setCurrentImage(this.model.currentImageIndex);
    }

    openImageModal(index: number): void {
        this.setCurrentImage(index);
        this.showSitePhotoModal();
    }

    setCurrentImage(index: number): void {
        checkForUndefinedParameters({index});
        this.model.currentImageUrl = this.model.files[index].url
        this.model.currentImageIndex = index;
    }

    setCurrentImageIndex(increment: number): void {
        checkForUndefinedParameters({increment});
        this.model.currentImageIndex = (this.model.currentImageIndex + increment + this.model.files.length) % this.model.files.length;
    }

    showSitePhotoModal(): void {
        this.model.showImageModal = !this.model.showImageModal;
    }

    getSitePhotoUrlsBySiteId(siteId: number): void {
        this._sitePhotoDataService.getSitePhotoUrlsBySiteId(siteId)
        .subscribe({
            next: (blobFileInfoArr: BlobFileInfo[]) => this.setSitePhotoUrls(blobFileInfoArr),
            error: (err) => {throw new Error(err)},
        })
    }

    setSitePhotoUrls(blobFileInfoArr: BlobFileInfo[]): void {
        checkForUndefinedParameters({blobFileInfoArr});
        this.resetImageUrls();
        blobFileInfoArr.forEach((blobFileInfo) => {
            const url = this._domSanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,' + blobFileInfo.data);
            const fileInfo = new FileInfo(blobFileInfo.fileName, url);
            this.model.files.push(fileInfo);
        })
    }

    downloadFile() {
        const file = this.model.files[this.model.currentImageIndex];
        const url = this._domSanitizer.sanitize(SecurityContext.URL, file.url);
        this.fetchAndDownload(url, file.fileName);
    }

    downloadAllFiles(selectedFileIndexes: Set<number>) {
        selectedFileIndexes.forEach((index) => {
            const file = this.model.files[index];
            const url = this._domSanitizer.sanitize(SecurityContext.URL, file.url);
            this.fetchAndDownload(url, file.fileName);
        })
    }

    fetchAndDownload(url: string, fileName): void {
        fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          this.downloadBlob(blob, fileName);
        })
        .catch((error) => {
          console.error('Error fetching the URL content:', error);
        });
    }

    downloadBlob(blob: Blob, fileName: string): void {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
        window.URL.revokeObjectURL(link.href);
    }

    resetImageUrls(): void {
        this.model.files = [];
    }
}
