import { Condition } from "src/app/models/ev/condition.model";
import { EvSite, SiteScoreRow } from "src/app/models/ev/evSite.model";

export class EvLogicServiceModel {
    sites: Map<number, EvSite> = new Map<number, EvSite>();
    censusIds: number[] = [];
    blockIds: number[] = [];
    geoToSiteIdMap = new Map<number,number[]>();
    blockToSiteIdMap = new Map<number,number[]>();
    filteredSites: Map<number, EvSite> = new Map<number, EvSite>();
    excelExportSites: Map<number, EvSite> = new Map<number, EvSite>();
    site: EvSite = new EvSite();
    isNested: boolean;
    adjustedScoreBarChart: Map<string, number> = new Map<string,number>([
        ["0:Low",0],
        ["1:Medium-Low",0],
        ["2:Medium",0],
        ["3:Medium-High",0],
        ["4:High",0],
        ["5:Top Site",0],
    ]);
    presetScoreBarChart: Map<string, number> = new Map<string,number>([
        ["0:Low",0],
        ["1:Medium-Low",0],
        ["2:Medium",0],
        ["3:Medium-High",0],
        ["4:High",0],
        ["5:Top Site",0],
    ]);
    allSiteAvgScores: number[] = [0,null,0,null,0,0,0];
    stateWideAvgScores: number[] = [0,null,0,null,0,0,0];
    dmaAvgScores: number[] = [0,null,0,null,0,0,0];
    fsvDmaAvg: number = 0;
    fsvStateAvg: number = 0;
    conditions: Condition[] = [
        {conditionId: 1, stepId: 1, inputValue: "1000"},
        {conditionId: 2, stepId: 2, inputValue: "1000"},
        {conditionId: 3, stepId: 3, inputValue: "1000"},
        {conditionId: 4, stepId: 1, inputValue: "1"},
        {conditionId: 5, stepId: 1, inputValue: "1"},
    ];
    baseConditions: Condition[] = [
        {conditionId: 1, stepId: 1, inputValue: "1000"},
        {conditionId: 2, stepId: 2, inputValue: "1000"},
        {conditionId: 3, stepId: 3, inputValue: "1000"},
        {conditionId: 4, stepId: 1, inputValue: "1"},
        {conditionId: 5, stepId: 1, inputValue: "1"},
    ];
    siteCharacteristics: SiteScoreRow[] = [
        {siteCharacteristic: "Minimum Density of Non-Work Trips", unit: "(trips/sq mi/day)", threshold1: "≥ 1,000", siteValue: 9229, threshold2: "-", allSiteAverage: 5168, stateAverage: 12064, thresholdPassed: false},
        {siteCharacteristic: "Minimum Driver Income", unit: "(USD)", threshold1: "≥ $75k", siteValue: null, threshold2: "-", allSiteAverage: null, stateAverage: null, thresholdPassed: false},
        {siteCharacteristic: "Minimum Density of Trips by Minimum Driver Income", unit: "(trips/sq mi/day)", threshold1: "≥ 1,000", siteValue: 9229, threshold2: "-", allSiteAverage: 5075, stateAverage: 12064, thresholdPassed: false},
        {siteCharacteristic: "Minimum Trip Distance", unit: "(miles)", threshold1: "≥ 15", siteValue: null, threshold2: "-", allSiteAverage: null, stateAverage: null, thresholdPassed: false},
        {siteCharacteristic: "Density of Trips by Minimum Trip Distance",unit: "(trips/sq mi/day)", threshold1: "≥ 1,000", siteValue: 9229, threshold2: "-", allSiteAverage: 5075, stateAverage: 12064, thresholdPassed: false},
        {siteCharacteristic: "Maximum Proximity to High Traffic Roads", unit: "(miles)", threshold1: "≤ 1", siteValue: 9229, threshold2: "-", allSiteAverage: 27, stateAverage: 12064, thresholdPassed: false},
        {siteCharacteristic: "Maximum Proximity to Highway Interchange", unit: "(miles)", threshold1: "≤ 1", siteValue: 9229, threshold2: "-", allSiteAverage: 4, stateAverage: 12064, thresholdPassed: false}
    ]
    scoreDescriptions: Map<number, string> = new Map<number, string>([
        [0,"Low"],
        [1,"Medium-Low"],
        [2,"Medium"],
        [3,"Medium-High"],
        [4,"High"],
        [5,"Top Site"],
    ]);
}
