import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/services/shared/authentication.service';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';

@Component({
    selector: 'app-login',
    templateUrl: 'login.component.html',
    styleUrls: ['login.component.scss'],
})
export class LoginComponent {
    constructor(
        private dialog: MatDialog,
        public dialogRef: MatDialogRef<LoginComponent>,
        private formBuilder: FormBuilder,
        private AuthenticationService: AuthenticationService
    ) {}
    hide = true;
    public registerPage: boolean = true;
    public phoneSMSPage: boolean = false;
    public phoneToggle: boolean;

    public loginForm = this.formBuilder.group({
        email: ['', [Validators.email, Validators.required]],
        password: ['', Validators.required],
    });

    public phoneSMSForm = this.formBuilder.group({
        phone: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
    });

    public phoneLoginForm = this.formBuilder.group({
        sms: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
    });

    public registerForm = this.formBuilder.group({
        firstname: ['', Validators.required],
        lastname: ['', Validators.required],
        userEmail: ['', [Validators.required, Validators.email]],
        password: ['', Validators.required],
        phone: [''],
    });

    login = () => {
        // let form = this.loginForm.value;
        // let email = form.email;
        // let password = form.password;

        // this.AuthenticationService.authenticate(form).then(result =>{
        //     if(result){
        //         this.dialogRef.close();
        //     }
        // }).catch(error => {
        // });
    };

    //generate phone sms code
    phoneSMS = () => {
        // let form = this.phoneSMSForm.value;
        // let phone = form.phone;

        // this.AuthenticationService.phoneSMS(form).then(result =>{
        //     this.phoneSMSPage = true;
        // }).catch(error => {
        // });
    };

    //authenticate with phone login
    phoneLogin = () => {
    //     let phone = this.phoneSMSForm.value.phone;
    //     let sms = this.phoneLoginForm.value.sms;

    // this.AuthenticationService.phoneLogin(phone, sms).then(result =>{
    //     console.log(result);
    //     if (result) this.dialogRef.close();
    // }).catch(error => {
    // });
    // this.phoneSMSPage = true;
    };

    //register user in DB
    register = () => {
        let form = this.registerForm.value;

        // this.AuthenticationService.register(form).then(result =>{
        //     if(result){
        //         this.dialogRef.close();
        //     }
        // }).catch(error => {
        // });
        // this.AuthenticationService.register(form);
        // this.dialogRef.close();
    };

    onClose = () => {
        this.dialogRef.close();
    };

    registerClick = () => {
        this.registerPage = true;
    };

    registerCancel = () => {
        this.registerPage = false;
    };
}
