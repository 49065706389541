<div id="site-photos">
    <div class="site-photos-grid-container">
        <div class="grid1-item">
            <div class="title-tooltip">
                <p class="ml-2"><strong>Site Photos</strong></p>
                <i class="fas fa-info-circle"
                    tooltip="Click on a photo to enlarge it."
                    tooltipPosition="right"
                ></i>
            </div>
            <ng-container *ngIf="this.files.length > 0">
                <i class="fas fa-xl fa-download"
                    [matTooltipPosition]="'above'"
                    matTooltip="Download selected files"
                    (click)="this.downloadFiles()"></i>
            </ng-container>
        </div>
        <div class="grid1-item">
            <label *ngIf="this.files.length > 0" class="checkbox">
                <mat-checkbox class="mat-checkbox-flex" [(ngModel)]="selectAllState" (change)="onSelectAllChange($event)">Select all</mat-checkbox>
            </label>
        </div>
        <div class="grid1-item">
            <div class="gallery-grid-container" [ngStyle]="{'min-height': this.files.length === 0 ? '100px' : '0'}">
                <ng-container *ngFor="let file of this.files, let i = index">
                    <div class="grid2-item">
                        <label *ngIf="this.files.length > 0" class="checkbox">
                            <mat-checkbox class="mat-checkbox-flex" [(ngModel)]="checkboxStates[i]" (change)="onCheckboxChange($event, i)"></mat-checkbox>
                        </label>
                        <img [src]="file.url" [class.selected]="isSelected(i)" (click)="this.openImageModal(i)">
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="modal" [ngClass]="{'is-active': showImageModal}">
        <div class="modal-background" (click)="this.showSitePhotoModal()"></div>
        <div class="image-container">
            <div class="modal-content">
                <img [src]="this.currentImageUrl" alt="image">
            </div>
            <button class="modal-icon arrow left-arrow" (click)="this.toggleImage(true)">
                <i class="fas fa-chevron-left"></i>
            </button>
            <button class="modal-icon arrow right-arrow" (click)="this.toggleImage(false)">
                <i class="fas fa-chevron-right"></i>
            </button>
        </div>
        <button class="modal-close is-large" (click)="this.showSitePhotoModal()" aria-label="close"></button>
    </div>
</div>




