export class VmtInfo
    {
        public projectName: string;
        public projectDescription: string;
        public location: string;
        public projectContextSetting: string;
        public county: string;
        public imageString: string;
        public analysisYear: number;
        public landUses: IteLandUse[] = [];
        public presumptions: Presumption[] = [];
        public emissions: Emission[] = [];
        public priorityAreas: PriorityArea[] = [];
        public agencyId: string;
        public isNchrp: boolean;
        public citywideVmt: number;
        public citywideVt: number;

        public totalCO2: number;
        public totalROG: number;
        public totalNOX: number;
        public totalCO: number;
        public totalSOX: number;
        public totalPM10: number;
        public totalPM2_5: number;
    }

    export class IteLandUse
    {
        public code: string;
        public intensity: number;
        public unit: string;
        public chartImageString: string;
        public regionalAverage: number;
        public threshold: number;
        public vtThreshold: number;
        public pedAccessibilityThreshold: number;
        public pedAccessibilityScore: number;
        public isSignificant: string;

        public CO2: number;
        public ROG: number;
        public NOX: number;
        public CO: number;
        public SOX: number;
        public PM10: number;
        public PM2_5: number;

        public COMitigation: number;
        public CO2Mitigation: number;
        public ROGMitigation: number;
        public NOXMitigation: number;
        public SOXMitigation: number;
        public PM2_5Mitigation: number;
        public PM10Mitigation: number;

        public COWithMitigation: number;
        public CO2WithMitigation: number;
        public ROGWithMitigation: number;
        public NOXWithMitigation: number;
        public SOXWithMitigation: number;
        public PM2_5WithMitigation: number;
        public PM10WithMitigation: number;

        public MobileCO2: number;
        public MobileROG: number;
        public MobileNOX: number;
        public MobileCO: number;
        public MobileSOX: number;
        public MobilePM10: number;
        public MobilePM2_5: number;

        public vmtName: string;
        public vtName: string;
        public vmtProject: number;
        public vtProject: number;
        public vmtMitigation: number;
        public vtMitigation: number;
        public vmtProjectWithMitigation: number;
        public vtProjectWithMitigation: number;
        public totalVmt: number;
        public totalVt: number;

        public tripsName: string;
        public trips: number;
        public tripsMitigation: number;
        public tripsWithMitigation: number;

        public IsAffordableHousing: boolean;
        public IsLocalServing: boolean;

        public InternalCaptureRate: number;
        public tdmAsString?: string;
    }

    export class Presumption
    {
        public name: string;
        public isChecked: boolean;
    }

    export class Emission
    {
        public name: string;
        public mobile: number;
        public withMitigation: number;
        public nonMobile: number;
    }

    class PriorityArea
    {
        public title: string;
        public description: string;
        public coverage: number;
        public explanation: string;
        public metric: string;
        public threshold: string;
    }
