import { Observable, from, of, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { NotificationService, NotificationType, NotificationCloseType } from './notification.service';

export class BaseService {
    constructor(protected http: HttpClient, protected notificationService: NotificationService) {

    }

    protected handleError<T>(operation = 'operation', result?: T, needsNotify: boolean = true) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.log(error); // log to console instead

            if (needsNotify) {
                this.notificationService.showNotification(NotificationType.Error,
                    `${operation} failed: ${error.name}`,
                    NotificationCloseType.Self | NotificationCloseType.DeleteButton)
            }

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }
}
