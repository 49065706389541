<div class="box-shadow">
    <div class="columns is-gapless m-0 ">
        <div class="column h-37-5px is-flex is-align-items-center grey is-full">
            <strong class="p-1 pl-2">Theme Layers</strong>
        </div>
    </div>
    <ng-container *ngFor="let layer of siteThemeLayerData; let i = index">
        <div class="item-pair columns is-gapless m-0" [class.even]="i % 2 === 0">
            <div class="item column h-37-5px is-flex is-align-items-center is-justify-content-flex-start color is-half">
                <span class="icon ml-1 mt-1">
                    <div class="icon-template" *ngIf="layer.icon.imgUrl != null;else fontIcon">
                        <svg [ngClass]="{'california-icon' : layer.layer.layerName === 'CA Priority Populations'}">
                            <use [attr.href]="layer.icon.imgUrl" />
                        </svg>
                    </div>
                    <ng-template #fontIcon>
                            <i [ngClass]="layer.icon.iconClass">{{layer.icon.matIconText}}</i>
                            <i [ngClass]="layer.icon.iconClass2"></i>
                    </ng-template>
                </span>
                <p class="pl-2">
                    <strong>{{layer.layer.layerName}}</strong>
                </p>
                <img
                    class="mb-2 ml-1"
                    tooltip="{{layer.layer.tooltipText}}"
                    tooltipPosition="right"
                    src="../../../../assets/images/info-outlined.svg"
                    alt="Info Icon"
                >
            </div>
            <div class="item column h-37-5px is-flex is-align-items-center color is-half">
                <p class="p-2">{{this.isLayerSiteValueNumber(layer.layer.layerName) ? (layer.siteValue | number) : layer.siteValue}}</p>
            </div>
        </div>
    </ng-container>
</div>

