<div id="site-score-grid">
    <div class="grid-container">
        <div class="item header1 grey"><strong>Site Characteristics</strong></div>
        <div class="item light-green"><strong>Preset Threshold</strong></div>
        <div class="item navy"><strong>Adjusted Threshold</strong></div>
        <div class="item grey">
            <img
                    class="mb-2 ml-6"
                    tooltip="Compared to Preset or Adjusted Threshold as relevant."
                    tooltipPosition="right"
                    src="../../../../assets/images/info-outlined.svg"
                    alt="Info Icon"
            >
            <strong>Site Value</strong>
        </div>
        <div class="item grey"><strong>Averages</strong></div>
        <div class="item grey"><strong>{{this.agencyName === 'Phillips66' ? "DMA" : "All Sites"}}</strong></div>
        <div class="item grey"><strong>Statewide</strong></div>
        <ng-container *ngFor="let characteristics of siteCharacteristics; let i=index">
            <div class="dataItem first">
                <p>{{characteristics.siteCharacteristic}}</p>
                <p>{{characteristics.unit}}</p>
            </div>
            <div class="dataItem">{{characteristics.threshold1}}</div>
            <div class="dataItem">{{characteristics.threshold2}}</div>
            <div class="dataItem" [ngClass]="characteristics.thresholdPassed ? 'green': 'red'">{{site.siteValues[i] | number:'1.0-2'}}</div>
            <div class="dataItem">{{(this.agencyName === 'Phillips66' ? dmaAvgs[i] : allSiteAvgs[i]) | number}}</div>
            <div class="dataItem">{{stateWideSiteAvg[i] | number}}</div>
        </ng-container>
    </div>
</div>


