<div class="main" [class.is-flex]="calculatorPage < constants.PAGE_CALCULATE">
    <aside id="sidebar" class="aside-left hide" [class.aside-left-project]="calculatorPage > constants.PAGE_LOCATION && calculatorPage < constants.PAGE_CALCULATE"
        [class.aside-left-results]="calculatorPage === constants.PAGE_CALCULATE">
        <app-calculator-input-form id="calculator" (pageChange)="onCalculatorPageChange($event)"></app-calculator-input-form>
    </aside>
    <div id="main-content" class="is-flex is-flex-direction-column main-content-col"
        [class.small]="calculatorPage > constants.PAGE_LOCATION" [class.hide]="calculatorPage >= constants.PAGE_CALCULATE">
        <div class="map-container">
            <app-map-widget></app-map-widget>
        </div>
    </div>
</div>
