import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'percent'
})
export class PercentDisplayPipe implements PipeTransform {
    constructor() {}
    private NA = 'N/A';

    transform(floatString: string): any {
        if (floatString === this.NA || floatString === `'${this.NA}'`)
            return 'N/A';
        let float = parseFloat(floatString) * 100;
        return `${(Math.round(float * 100 ) / 100).toFixed(2)}%`;
    }
}
