// import { Injectable } from '@angular/core';
// @Injectable()

export class UserInfo {
    public accessToken: string;
    public address: string;
    public cellPhone: string;
    public dateCreated: Date;
    public firstName: string;
    public lastName: string;
    public phoneCode: string;
    public phoneCodeExpiration: Date;
    public roleId: string;
    public showWelcomePage: boolean;
    public userEmail: string;
    public userId: string;
    public userRole: string;
    public workPhone: string;
    public message: string;
    public password: string;
    public status: string;

    constructor(
        accessToken?: string,
        address?: string,
        cellPhone?: string,
        dateCreated?: Date,
        firstName?: string,
        lastName?: string,
        phoneCode?: string,
        phoneCodeExpiration?: Date,
        roleId?: string,
        showWelcomePage?: boolean,
        userEmail?: string,
        userId?: string,
        userRole?: string,
        workPhone?: string,
        message?: string,
        password?: string,
        status?: string,

    ) {
        this.accessToken = accessToken;
        this.address = address;
        this.cellPhone = cellPhone;
        this.dateCreated = dateCreated;
        this.firstName = firstName;
        this.lastName = lastName;
        this.phoneCode = phoneCode;
        this.phoneCodeExpiration = phoneCodeExpiration;
        this.roleId = roleId;
        this.showWelcomePage = showWelcomePage;
        this.userEmail = userEmail;
        this.userId = userId;
        this.userRole = userRole;
        this.workPhone = workPhone;
        this.message = message;
        this.password = password;
        this.status = status;
    }
}

export class User {
    userId: string;
    accessToken: string;
    firstName: string;
    lastName: string;
    email: string;
    agencyId: string;
}

export interface IProject {
    id: string,
    name: string,
    description: string,
    isHidden: boolean,
    url: string,
    content?: any
}
