import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'keyValue'
})
export class KeyValuePipe implements PipeTransform {
    constructor() {}

    transform(key: any, collection: any[], keyName: string, valueName: string): any {
        let found = collection.find(x => x[keyName] === key);
        if (found) {
            return found[valueName];
        } else {
            return null;
        }
    }
}
