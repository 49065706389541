import { Component } from '@angular/core';
import { SiteProperty } from 'src/app/models/ev/siteProperty';
import { EvLogicService } from 'src/app/services/ev/logic-services/ev-logic.service';

@Component({
  selector: 'app-site-details-grid',
  templateUrl: './site-details-grid.component.html',
  styleUrls: ['./site-details-grid.component.scss']
})
export class SiteDetailsGridComponent {

    get siteDetails(): Map<string, SiteProperty> {
        return this._evLogicService.model.site.additionalProperties;
    }

    constructor(private _evLogicService: EvLogicService) { }

    getHalfSize(): number {
        return Math.floor(this.siteDetails.size/2);
    }
}
