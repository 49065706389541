import { Component, OnInit, EventEmitter, Output, ViewChildren, QueryList, Input } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { IPresumption, IPresumptionUpdate } from 'src/app/models/vmt/presumption.model';
import { AgencyService } from 'src/app/services/shared/agency.service';
import { PresumptionService } from 'src/app/services/vmt/presumption.service';

@Component({
    selector: 'app-presumptions-widget',
    templateUrl: './presumptions-widget.component.html',
    styleUrls: ['./presumptions-widget.component.scss']
})
export class PresumptionsWidgetComponent implements OnInit {
    selectedPresumptionIds: number[] = [];
    presumptions: IPresumption[] = [];

    @Output() checkboxChange = new EventEmitter<IPresumptionUpdate>();
    @Input() showCheckbox: boolean = true;
    @Input() showTitle: boolean = true;
    @ViewChildren(MatCheckbox) checkboxes: QueryList<MatCheckbox>;
    constructor(private presumptionService: PresumptionService, ) {
        this.presumptionService.presumptionListUpdated$.subscribe(() => {
            this.presumptionService.getPresumptions(null).subscribe(x => {
                this.presumptions = x;
            });
        });
        this.presumptionService.presumptionUpdated$.subscribe(idAndChecked => {
            this.checkboxes.forEach((element, index) => {
                if (index === idAndChecked.id - 1) {
                    element.checked = idAndChecked.isChecked;
                }
            })
        });
    }

    ngOnInit(): void {
    }

    onCheckboxClick($event, id) {
        this.checkboxChange.emit({ id: id, isSelected: $event.checked });
    }


}
