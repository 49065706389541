<div id="p66-ev-site-details" class="container">
    <div class="flex-row">
        <div class="flex-col">
            <ng-container *ngIf="this.site"><strong>{{this.formatAddress()}}</strong></ng-container>
            <p>MRN: {{siteDetails.get("mrn")?.propertyValue}}</p>
        </div>
        <div class="address-score-container p-4">
            <div class="address-score-item preset-score-box">
                <p>Preset Score:</p>
                <p><strong>{{site.finalScore}}</strong></p>
            </div>
            <div class="address-score-item adjusted-score-box">
                <p>Adjusted Score: </p>
                <p><strong>{{site.adjustedScore}}</strong></p>
            </div>
            <div class="address-score-item mystery-score-box">
                <p>Mystery Shop Score: </p>
                <p><strong>{{siteDetails.get("mysteryShopScore")?.propertyValue}}</strong></p>
            </div>
        </div>
    </div>
    <div class="p-4">
        <app-site-score-grid></app-site-score-grid>
    </div>
    <div class="p-4">
        <app-site-details-chart></app-site-details-chart>
    </div>
    <!-- <div class="p-4">
        <app-site-fsv-grid></app-site-fsv-grid>
    </div> -->
    <div class="p-4">
        <app-theme-layer-details-grid></app-theme-layer-details-grid>
    </div>
    <div class="p-4">
        <app-site-details-grid></app-site-details-grid>
    </div>
    <app-site-photos></app-site-photos>
</div>

