import { SafeResourceUrl } from "@angular/platform-browser";

export class BlobFileInfo {
    public fileName: string;
    public data: Uint8Array;
}

export class FileInfo {
    public fileName: string;
    public url: SafeResourceUrl;

    constructor(fileName: string, url: SafeResourceUrl) {
        this.fileName = fileName;
        this.url = url;
      }
}
