export function convertJsonToMap<K extends keyof any, V>(jsonObj: Record<K, V>): Map<K, V> {
    const keyValuePairs: [K, V][] = Object.entries(jsonObj).map(([key, value]) => {
      let convertedKey: K;

      if (typeof key === 'string' && !isNaN(parseFloat(key))) {
        const numKey = parseFloat(key);
        convertedKey = numKey as unknown as K;
      } else {
        convertedKey = key as K;
      }

      const convertedValue = convertNestedAdditionalProperties(value);

      return [convertedKey, convertedValue as V];
    });

    return new Map<K, V>(keyValuePairs);
  }

function convertNestedAdditionalProperties(value: any): any {
    if (value instanceof Object && 'additionalProperties' in value) {
        const site = value;
        site.additionalProperties = convertJsonToMap<string, string>(site.additionalProperties);
    }
    return value;
}

