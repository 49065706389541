import { Component } from '@angular/core';
import { SiteProperty } from 'src/app/models/ev/siteProperty';
import { EvSite } from 'src/app/models/ev/evSite.model';
import { EvLogicService } from 'src/app/services/ev/logic-services/ev-logic.service';
import { AgencyService } from 'src/app/services/shared/agency.service';
import { SitePhotoLogicService } from 'src/app/services/ev/logic-services/site-photo-logic.service';

@Component({
  selector: 'app-ev-site-screen',
  templateUrl: './ev-site-screen.component.html',
  styleUrls: ['./ev-site-screen.component.scss']
})
export class EvSiteScreenComponent {

    get site(): EvSite { return this._evLogicService.model.site;}
    get siteDetails(): Map<string, SiteProperty> { return this._evLogicService.model.site.additionalProperties; }
    get agencyName(): string { return this._agencyService.model.agencyName; }

    constructor(
        private _evLogicService: EvLogicService,
        private _sitePhotoLogicService: SitePhotoLogicService,
        private _agencyService: AgencyService
    ) {}

    ngOnInit(): void {
        this.getSitePhotoUrls();
    }

    getSitePhotoUrls(): void {
        this._sitePhotoLogicService.getSitePhotoUrlsBySiteId(this.site.siteId);
    }

    formatAddress(): string {
        const existingValues = this.getNonEmptyStrings();
        switch (existingValues.length) {
            case 0:
                return "";
            case 1:
                return existingValues[0];
            default:
                return existingValues.join(', ');
        }
    }

    getNonEmptyStrings(): string[] {
        return [this.site.address, this.site.city, this.site.state].filter(value => this.isNotEmptyStringNullOrUndefined(value)) as string[];
    }

    isNotEmptyStringNullOrUndefined(value: string): boolean {
        return value != "" && value != null && value != undefined;
    }


}
