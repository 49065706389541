import { Component } from '@angular/core';
import { EvLogicService } from 'src/app/services/ev/logic-services/ev-logic.service';

@Component({
  selector: 'app-site-fsv-grid',
  templateUrl: './site-fsv-grid.component.html',
  styleUrls: ['./site-fsv-grid.component.scss']
})
export class SiteFsvGridComponent {

    get fsv(): number { return parseInt(this._evLogicService.model.site.additionalProperties.get('fuelSalesVolume')?.propertyValue); }
    get fsvDmaAvg(): number { return this._evLogicService.model.fsvDmaAvg; }
    get fsvStateAvg(): number { return this._evLogicService.model.fsvStateAvg; }

    constructor(private _evLogicService: EvLogicService) {}
}
