<div id="ev-site-details" class="container">
    <div class="address-score-container p-4">
        <div class="address-score-item">
            <ng-container *ngIf="this.site"><strong>{{this.formatAddress()}}</strong></ng-container>
        </div>
        <div class="address-score-item preset-score-box">
            <p>Preset Score:</p>
            <p><strong>{{site.finalScore}}</strong></p>
        </div>
        <div class="address-score-item adjusted-score-box">
            <p>Adjusted Score: </p>
            <p><strong>{{site.adjustedScore}}</strong></p>
        </div>
        <div class="address-score-item">
            <ng-container *ngIf="this.agencyName === 'Phillips66'"><p>MRN: {{siteDetails.get("mrn").propertyValue}}</p></ng-container>
        </div>
    </div>
    <div class="p-4">
        <app-site-score-grid></app-site-score-grid>
    </div>
    <div class="p-4">
        <app-site-details-chart></app-site-details-chart>
    </div>
    <div class="p-4">
        <app-theme-layer-details-grid></app-theme-layer-details-grid>
    </div>
    <div class="p-4">
        <app-site-details-grid></app-site-details-grid>
    </div>
    <div *ngIf="this.agencyName !== 'Demo'" class="p-4">
        <app-site-photos></app-site-photos>
    </div>
</div>

