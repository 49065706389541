import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';

@Injectable({
providedIn: 'root'
})
export class ExcelExportService {

constructor() { }

exportToExcel(data: any[], fileName: string): void {
    this.closeExcelExportLoadingScreen();
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    XLSX.writeFile(wb, fileName);
}

initiateExcelExportLoadingScreen(): void {
    let loader = document.getElementById('excelExportLoader');
    loader.classList.add('is-active');
}

private closeExcelExportLoadingScreen(): void {
    let loader = document.getElementById('excelExportLoader');
    loader.classList.remove('is-active');
}


}
