import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app/app.constants';
import { CalculatorInputFormComponent } from 'src/app/components/calculator-input-form/calculator-input-form.component';
import { IPresumptionUpdate } from 'src/app/models/vmt/presumption.model';
import { MapInteractionService } from 'src/app/services/shared/map-interaction.service';
import { NavigationService } from 'src/app/services/shared/navigation.service';

@Component({
    selector: 'app-main-component',
    templateUrl: './main-component.component.html',
    styleUrls: ['./main-component.component.scss']
})
export class MainComponentComponent implements OnInit {
    @ViewChild(CalculatorInputFormComponent) calculator: CalculatorInputFormComponent;
    clientSite: string;
    calculatorPage: number = 0;

    constructor(private mapInteractionService: MapInteractionService,
        private navigationService: NavigationService, private router: Router,
        public constants: AppConstants) {
        navigationService.appStarted$.subscribe(x => {
            this.onStartClicked();
        });
        navigationService.clientUpdated$.subscribe(clientSite => this.clientSite = clientSite);
    }

    ngOnInit(): void {

    }

    onPresumptionsChanged(update: IPresumptionUpdate) {
        if (!this.calculator) return;
        this.calculator.updatePresumption(update);
    }

    onStartClicked() {
        this.router.navigate(['sites', this.clientSite, 'calculator'],
        {
            queryParams: {
                step: 1
            },
        });

        //this.mapInteractionService.toggleWidgets();
    }

    onCalculatorPageChange(page) {
        setTimeout(() => {
            this.calculatorPage = page;
        }, 100);

    }
}
