<div #mapViewNode class="map-view" data-drop-id="1"></div>
<div id="basemapGalleryNode"></div>
<div id="evStats" class="is-hidden card" >
    <div class="card-content card-map-widget">
        <table class="table is-narrow is-fullwidth is-borderless">
            <tbody>
                <tr>
                    <td class="has-text-weight-bold is-size-6" style="vertical-align: bottom;">
                        Number of Sites
                    </td>
                    <td>
                        <button (click)="this.exportEvSiteDataToExcel()" class="button is-small is-rounded is-light">
                            <span class="icon">
                              <i class="fas fa-xl fa-download"></i>
                            </span>
                          </button>
                    </td>
                </tr>
                <tr class="has-text-primary">
                    <td>Included:</td>
                    <td class="has-text-right">{{evStats.remainder | number}}</td>
                </tr>
                <tr class="text-excluded">
                    <td>Excluded:&nbsp;</td>
                    <td class="has-text-right">{{evStats.total - evStats.remainder | number}}</td>
                </tr>
                <tr>
                    <td>Total:</td>
                    <td class="has-text-right">{{evStats.total | number}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div id="evThemeLayerWidget" class="is-hidden card" (mousemove)="onThemeWidgetMove($event)">
    <div class="card-content card-map-widget">
        <div class="is-flex is-align-items-stretch is-flex-grow-1 is-flex-shrink-0">
            <p class="has-text-weight-bold is-justify-content-flex-start mr-2">Theme Layers</p>
            <span class="is-small is-justify-content-flex-end ml-auto icon-expander has-text-accent
            is-collapsed fa-rotate-by"
            (click)="toggleEvThemeExpander()">
            </span>
        </div>
        <div id="evThemeList" class="is-hidden mt-2">
            <div class="field is-horizontal" *ngFor="let g of evThemeLayerGroups">
                <div class="field-body field-body-btn">
                    <div *ngFor="let ctrl of g" class="field">
                        <p class="control">
                            <button class="button btn-ev-theme-icon is-primary is-outlined is-justify-content-center" (click)="onEvThemeLayerClick($event, ctrl)">
                                <span class="icon is-small">
                                    <div class="icon-template" *ngIf="ctrl.imgUrl != null;else fontIcon">
                                        <svg>
                                            <use [attr.href]="ctrl.imgUrl" />
                                        </svg>
                                    </div>
                                    <ng-template #fontIcon>
                                        <i [ngClass]="ctrl.iconClass">{{ctrl.matIconText}}</i>
                                        <i [ngClass]="ctrl.iconClass2"></i>
                                    </ng-template>
                                </span>
                            </button>
                        </p>
                        <p class="ev-theme-name has-text-centered">{{ctrl.label}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="excelExportLoader" class="pageloader is-right-to-left fullheight-exclude-footer"><span class="title">{{loaderText}}</span></div>
