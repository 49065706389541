import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { IPresumption } from 'src/app/models/vmt/presumption.model';
import { BaseService } from '../shared/base-service.service';
import { NotificationService } from '../shared/notification.service';
import { environment } from 'src/environments/environment';

interface IApiPresumption {
    PresumptionID: number,
    Presumption: string,
    Description: string,
    OPRPageNum: number,
    IsProject: boolean
}

@Injectable({
    providedIn: 'root'
})
export class PresumptionService extends BaseService {
    private presumptions: IPresumption[];
    private getCount = 0;
    private togglePresumptionSource = new Subject<{id: number, isChecked: boolean }>();
    private presumptionListSource = new Subject<void>();

    presumptionUpdated$ = this.togglePresumptionSource.asObservable();
    presumptionListUpdated$ = this.presumptionListSource.asObservable();

    constructor(http: HttpClient, notificationService: NotificationService) {
        super(http, notificationService);
        this.presumptions = [];
    }

    getPresumptions(agencyID: string): Observable<IPresumption[]> {
        if (this.presumptions.length > 0 || agencyID === null)
            return of(this.presumptions);
        else {
            this.getCount++;
            return this.http.get<IApiPresumption[]>(`${environment.api.baseURL}/libPresumptions/${agencyID}`)
            .pipe(map((data: IApiPresumption[]) => {
                let presumptions = [];
                if (!Array.isArray(data))
                    return presumptions;
                data.forEach(d => {
                    presumptions.push({
                        id: d.PresumptionID,
                        title: d.Presumption,
                        text: d.Description,
                        oprPageNum: d.OPRPageNum,
                        isProject: d.IsProject
                    });
                });
                this.presumptions = presumptions;
                this.presumptionListSource.next();
                return presumptions;
            }), catchError(this.handleError<IPresumption[]>('Presumptions', [], this.getCount === 1)));
        }
    }

    togglePresumption(idAndChecked: {id: number, isChecked: boolean }) {
        this.togglePresumptionSource.next(idAndChecked);
    }
}
