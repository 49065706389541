import { CustomStepDefinition, Options } from '@angular-slider/ngx-slider';

export class ScreenInput {
    private _primaryStepAsNum: number;
    private _secondaryStepAsNum: number;
    screenInputId: number;
    inputType: string;
    displayName: string;
    secondaryDisplayName: string;
    primaryConditionId: number;
    secondaryConditionId: number;
    primaryStepValues: string[];
    secondaryStepValues: string[];
    primaryUnits: string;
    secondaryUnits: string;
    isPrepend: boolean;

    // Properties for display
    isSelected: boolean;
    primaryStep: string;
    secondaryStep: string;
    sliderOptions: Options;
    primaryDefault: string;
    secondaryDefault: string;

    get primaryStepAsNum(): number {
        return this._primaryStepAsNum;
    }

    set primaryStepAsNum(step: number) {
        if (step === null || step === undefined) return;
        this._primaryStepAsNum = step;
        this.primaryStep = step.toString();
    }

    get secondaryStepAsNum(): number {
        return this._secondaryStepAsNum;
    }

    set secondaryStepAsNum(step: number) {
        if (step === null || step === undefined) return;
        this._secondaryStepAsNum = step;
        this.secondaryStep = step.toString();
    }

    constructor(input: ScreenInput) {
        this.screenInputId = input.screenInputId;
        this.inputType = input.inputType;
        this.displayName = input.displayName;
        this.secondaryDisplayName = input.secondaryDisplayName;
        this.primaryConditionId = input.primaryConditionId;
        this.secondaryConditionId = input.secondaryConditionId;
        this.primaryStepValues = input.primaryStepValues;
        this.secondaryStepValues = input.secondaryStepValues;
        this.primaryUnits = input.primaryUnits;
        this.secondaryUnits = input.secondaryUnits;
        this.isPrepend = input.isPrepend;

        // Properties for display
        this.isSelected = input.isSelected;
        this.primaryStep = this.formatStepValue(1, input.primaryStep);
        this.primaryStepAsNum = input.primaryStepAsNum;
        this.secondaryStep = this.formatStepValue(2, input.secondaryStep);;
        this.secondaryStepAsNum = input.secondaryStepAsNum;
        this.sliderOptions = input.sliderOptions;

        this.primaryDefault = this.primaryStep;
        this.secondaryDefault = this.secondaryStep;
    }

    stepToNum(stepAsString: string) {
        return parseInt(stepAsString);
    }

    stepToString(stepAsNum: number) {
        return stepAsNum.toLocaleString();
    }

    formatStepValue(order: number, step: string) : string {
        let units = order === 1 ? this.primaryUnits : this.secondaryUnits;
        switch(units) {
            case '$':
                return '$' + parseInt(step).toLocaleString();
            default:
                return step;
        }
    }

    restoreDefaults() {
        switch (this.inputType) {
            case 'Slider':
                this.primaryStepAsNum = parseInt(this.primaryDefault);
                break;
            case 'SliderCombo':
                this.primaryStep = this.primaryDefault;
                this.secondaryStepAsNum = parseInt(this.secondaryDefault);
                break;
            case 'Number':
                this.primaryStepAsNum = parseInt(this.primaryDefault);
                break;
        }
    }
}

