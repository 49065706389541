export enum FilterComparisonEnum {
    LessThan = "<",
    LessThanEqualTo = "≤",
    Equal = "=",
    GreaterThan = ">",
    GreaterThanEqualTo = "≥"
}

export type ComparisonFn = (siteScore: number, score: number) => boolean;

export const comparisonFunctions: Record<FilterComparisonEnum, ComparisonFn> = {
  [FilterComparisonEnum.Equal]: (siteScore, score) => siteScore === score,
  [FilterComparisonEnum.GreaterThan]: (siteScore, score) => siteScore > score,
  [FilterComparisonEnum.LessThan]: (siteScore, score) => siteScore < score,
  [FilterComparisonEnum.GreaterThanEqualTo]: (siteScore, score) => siteScore >= score,
  [FilterComparisonEnum.LessThanEqualTo]: (siteScore, score) => siteScore <= score,
};

export enum FilterResultType {
    Included,
    Excluded
}

export class EvSiteFilter {
    private _filterAttribute: FilterAttribute;

    get filterAttribute(): FilterAttribute {
        return this._filterAttribute;
    }
    set filterAttribute(attr: FilterAttribute) {
        this._filterAttribute = attr;
        if(!attr) return;
        if (!attr.fieldType) return;
        switch(attr.fieldType) {
            case 'number':
                this.comparisonTypes = [
                    FilterComparisonEnum.LessThan,
                    FilterComparisonEnum.LessThanEqualTo,
                    FilterComparisonEnum.Equal,
                    FilterComparisonEnum.GreaterThan,
                    FilterComparisonEnum.GreaterThanEqualTo
                ];
                break;
            case 'string':
                this.comparisonTypes = [
                    FilterComparisonEnum.Equal
                ];
                this.comparisonType = FilterComparisonEnum.Equal;
                break;
        }
    }

    comparisonType: FilterComparisonEnum;
    filterValue: string;
    comparisonTypes: FilterComparisonEnum[];

    toDto() {
        let result = {};
        for (let key in this) {
            if (key === 'filterValue' && this._filterAttribute.fieldName === 'finalScore')
                result[key.replace('_', '')] = this[key].toString().substring(0, 1);
            else
                result[key.replace('_', '')] = this[key];
        }
        return result;
    }
}

export class FilterAttribute {
    fieldName: string;
    displayName: string;
    fieldType: string;
}

export class EvPerformanceFilter {
    label: string;
    primaryUnits: string;
    secondaryUnits: string;
    isEnabled: boolean;
    primaryValue: number
}

