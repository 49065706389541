<div class="columns columns-no-margin">
    <div class="column app-fullheight" [ngClass]="{'is-6': page >= constants.PAGE_CALCULATE}" >
        <div class="is-flex is-flex-direction-column fullheight">
            <div [ngSwitch]="page" class="col-left" style="flex: 1;" [class.tdm-column]="page === constants.PAGE_TDM">
                <div *ngSwitchCase="constants.PAGE_EV_START" >
                    <p class="title is-6 mb-2">
                        <span class="icon-text">
                            <span class="icon">
                                <i class="fas fa-map-marker-alt" aria-hidden="true"></i>
                            </span>
                            <span>Geography Filter</span>
                        </span>
                    </p>
                    <div class="mx-5 mb-5">
                        <div class="field radio-list">
                            <input class="is-checkradio is-accent" id="radioSingle" type="radio" name="radioMap"
                                [(ngModel)]="mapSource" [value]="1" (change)="onMapSourceChanged()">
                            <label for="radioSingle">Single Site Analysis</label>
                            <input class="is-checkradio is-accent" id="radioMultiple" type="radio" name="radioMap"
                                [(ngModel)]="mapSource" [value]="2" (change)="onMapSourceChanged()">
                            <label for="radioMultiple">Multiple Site Analysis</label>
                        </div>
                    </div>
                    <div *ngIf="mapSource == 1" class="is-toggle is-fullwidth" (after-if)="singleSiteRenderComplete()">
                        <div class="field mx-5">
                            <label class="label">Address</label>
                            <ng-select [items]="locatorResult$ | async" bindLabel="text" [addTag]="false"
                                [multiple]="false" [hideSelected]="true" [trackByFn]="locatorTrackByFn"
                                [minTermLength]="2" [loading]="locatorResultLoading"
                                typeToSearchText="Please enter 2 or more characters to search"
                                [typeahead]="locatorResultInput$" [(ngModel)]="selectedLocatorResults"
                                (change)="onLocatorChange($event)" placeholder="Search">
                            </ng-select>
                        </div>
                        <div class="separator mx-5 my-3">or</div>
                        <div class="field mx-5">
                            <label class="label">Drop a Pin</label>
                            <p class="is-size-6_5">Drag the pin icon onto the map to select the location.</p>
                            <div>
                                <img id="pin" class="pin" data-drop-id="1" src="../../assets/images/pin.svg" style="height: 40px">
                            </div>
                        </div>
                    </div>
                    <div *ngIf="mapSource == 2" class="columns ml-1">
                        <div class="column pl-0">
                            <label class="label">
                                <span class="icon-text">
                                    <span class="icon">
                                        <i class="fas fa-filter" aria-hidden="true"></i>
                                    </span>
                                    <span>Site Filters</span>
                                </span>
                            </label>
                            <button class="button is-fullwidth is-primary is-outlined" (click)="addNewSiteFilter()">
                                <span class="icon-text">
                                    <span class="icon">
                                        <i class="fas fa-plus" aria-hidden="true"></i>
                                    </span>
                                    <span>Add Site Filter</span>
                                </span>
                            </button>
                        </div>
                    </div>
                    <div *ngIf="mapSource == 2" class="is-fullwidth ml-5">
                        <div *ngFor="let filter of siteFilterList; let i = index">
                            <div class="field is-grouped" [ngClass]="{'mt-3': i > 0}">
                                <p class="control is-expanded">
                                    <label class="label">Site Filter {{i + 1}}</label>
                                </p>
                                <p class="control">
                                    <i class="table-action table-action-delete far fa-trash-alt"
                                    (click)="removeSiteFilter(i)"></i>
                                </p>
                              </div>
                                <div class="field">
                                    <ng-select [items]="this.filterAttributes" bindLabel="displayName" [loading]="attributeLoading" [clearable]="false"
                                        [(ngModel)]="filter.filterAttribute" placeholder="Select filter" (change)="onFilterByChange(filter)">
                                    </ng-select>
                                </div>
                                <div class="columns">
                                    <div class="column is-nested"
                                    [ngClass]="{'is-5': filter.filterAttribute.fieldType == 'number', 'is-1': filter.filterAttribute.fieldType == 'string',
                                    'is-offset-1': filter.filterAttribute.fieldType == 'number'}">
                                        <label *ngIf="filter.filterAttribute.fieldType == 'number'" class="label mt-2">Selection</label>
                                        <div class="field" >
                                            <ng-select *ngIf="filter.filterAttribute.fieldType == 'number'" [items]="filter.comparisonTypes"
                                                class="no-search"
                                                [(ngModel)]="filter.comparisonType" placeholder="Compare" [searchable]="false" [clearable]="false">
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div class="column is-nested" [ngClass]="{'is-6': filter.filterAttribute.fieldType == 'number'}">
                                        <label class="label mt-2" [ngClass]="{'is-invisible': filter.filterAttribute.fieldType == 'number'}">Selection</label>
                                        <ng-select [items]="getAttributeValues(filter.filterAttribute.fieldName)"
                                            [(ngModel)]="filter.filterValue" placeholder="Value">
                                        </ng-select>
                                    </div>
                                </div>
                            <hr *ngIf="i != siteFilterList.length - 1" class="navbar-divider" style="background-color:lightgray">
                        </div>
                    </div>
                </div>
                <div *ngSwitchCase="constants.PAGE_EV_SCREEN" class="custom-slider">
                    <p class="title is-6 mb-4">
                        <span class="icon-text">
                            <span class="icon mr-2">
                                <i class="fas fa-sliders-h" aria-hidden="true"></i>
                            </span>
                            <span>Site Performance Filters</span>
                        </span>
                    </p>
                    <!-- NESTING (REMOVED) -->
                    <!-- <div *ngIf="isNestingAllowed" class="columns">
                        <div class="column py-1">
                            <input id="switchNested" type="checkbox" name="switchNested" class="switch is-rounded is-small" [(ngModel)]="isNested" (change)="onNestedSwitchChange($event)">
                            <label for="switchNested" style="font-size: 1rem;">Nest Filters</label>
                        </div>
                    </div> -->
                    <!-- UI Inputs for screening sites -->
                    <div *ngFor="let input of screenInputs; let i = index">
                        <div class="columns col-input">
                            <div class="column" [ngClass]="{'pt-1': i === 0 && isNestingAllowed, 'is-8': input.secondaryConditionId > 0, 'is-9': input.inputType === 'Number'}">
                                <p class="has-text-weight-bold ml-5">{{input.displayName}}</p>
                                <p class="units ml-5 mb-2">{{input.primaryUnits}}</p>
                                <div class="mx-5">
                                    <ngx-slider *ngIf="input.inputType === 'Slider'" [(value)]="input.primaryStepAsNum" [options]="input.sliderOptions"></ngx-slider>
                                </div>
                            </div>
                            <div class="column" *ngIf="input.secondaryConditionId > 0 || input.inputType === 'Number'" [ngClass]="{'is-4': input.secondaryConditionId > 0, 'is-3': input.inputType === 'Number'}">
                                <ng-select *ngIf="input.secondaryConditionId > 0" appendTo="body" [(ngModel)]="input.primaryStep" [clearable]="false" [items]="input.primaryStepValues"
                                    [searchable]="false">
                                </ng-select>
                                <input *ngIf="input.inputType === 'Number'" class="input screen-input" type="number" [(ngModel)]="input.primaryStepAsNum" min="0" max="9999">
                            </div>
                        </div>
                        <div *ngIf="input.inputType === 'SliderCombo'" class="columns col-input">
                            <div class="column py-1 is-11 ml-5">
                                <label class="has-text-weight-bold">{{input.secondaryDisplayName}} by {{input.displayName}}</label>
                                <p class="units mb-2">{{input.secondaryUnits}}</p>
                                <ngx-slider [(value)]="input.secondaryStepAsNum" [options]="input.sliderOptions"></ngx-slider>
                            </div>
                        </div>
                        <hr *ngIf="i != screenInputs.length - 1" class="navbar-divider" style="background-color:lightgray">
                    </div>
                    <!-- end UI Inputs for screening sites -->
                </div>
                <div *ngSwitchCase="constants.PAGE_EV_SITE" >
                    <div class="flex-container">
                        <div class="top-grid">
                            <div class="top-grid-item">
                                <div class="circle" (click)="this.siteDetailsPageBackClick()">
                                    <i class="fas fa-chevron-left"></i>
                                </div>
                            </div>
                            <div class="top-grid-item">
                                <p class="title is-6 mb-2">
                                    <span class="icon-text">
                                        <span class="icon">
                                            <i class="fas fa-sliders-h" aria-hidden="true"></i>
                                        </span>
                                        <span>Site Details</span>
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="this.agencyName === 'Phillips66'; then p66 else general"></div>
                    <ng-template #p66><app-p66-ev-site-screen></app-p66-ev-site-screen></ng-template>
                    <ng-template #general><app-ev-site-screen></app-ev-site-screen></ng-template>
                </div>
            </div>
            <div class="is-flex is-justify-content-center p-1">
                <button *ngIf="page > constants.PAGE_EV_START && page < constants.PAGE_EV_SITE" class="button mw-120 mx-2 is-rounded" (click)="onBackClick()">
                    <span class="icon-text">
                        <span class="icon">
                            <i class="fas fa-arrow-left"></i>
                        </span>
                        <span>Back</span>
                    </span>
                </button>
                <button *ngIf="page === constants.PAGE_EV_SCREEN" class="button mw-120 mx-2 is-rounded" (click)="onDefaultsClick()">Reset</button>
                <button *ngIf="page === constants.PAGE_EV_START" class="button is-rounded mw-120" (click)="onClearClick()">Clear</button>
                <button *ngIf="page === constants.PAGE_EV_SCREEN" class="button is-primary mw-120 mx-2 is-rounded" (click)="onEvaluateClick()">Evaluate</button>
                <button *ngIf="page < constants.PAGE_EV_SCREEN" class="button mw-120 mx-2 is-rounded" (click)="onNextClick()"
                    [class.is-primary]="nextText === 'Next' || nextText === 'Calculate'" [class.is-accent]="nextText === 'Mitigate VMT' || nextText === 'VMT Bank'"
                    [class.is-hidden]="nextText === ''">
                    <span class="icon-text">
                        <span class="icon">
                            <i class="fas fa-arrow-right"></i>
                        </span>
                        <span>{{nextText}}</span>
                    </span>
                </button>
            </div>
        </div>
    </div>
    <div class="column" [ngClass]="{'is-hidden': page < constants.PAGE_CALCULATE, 'is-6': page >= constants.PAGE_CALCULATE}"></div>

</div>
<div id="evPageLoader" class="pageloader is-right-to-left fullheight-exclude-footer"><span class="title">{{loaderText}}</span></div>
