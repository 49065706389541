import { Injectable } from '@angular/core';
import { Router, NavigationEnd, Event as NavigationEvent } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ICity, IAgencyConfig, IAnalysisYear } from 'src/app/models/shared/agency-data.model';
import { ITDMGroupStrategy } from 'src/app/models/vmt/tdm.model';
import { environment } from 'src/environments/environment';
import { NotificationService } from './notification.service';
import { BaseService } from './base-service.service';
import { tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AgencyServiceModel } from './logic-services/models/agency-service-model';


@Injectable({
    providedIn: 'root'
})
export class AgencyService extends BaseService {
    public model: AgencyServiceModel;
    protected config: IAgencyConfig;
    private configUpdateSource = new Subject<IAgencyConfig>();

    clientSite: string;
    configUpdated$ = this.configUpdateSource.asObservable();

    constructor(http: HttpClient, notificationService: NotificationService, private router: Router) {
        super(http, notificationService);

        this.initModel();

        // TODO: use NavigationService?
        this.router.events.subscribe((event: NavigationEvent) => {
            if (event instanceof NavigationEnd) {
                let urlTree = router.parseUrl(this.router.url);
                let segments = urlTree.root.children['primary'].segments;
                let subSegment = '';
                if (segments.length > 0)
                    subSegment = segments[0].path;
                if (segments.length >= 2 && (subSegment === 'sites' || subSegment === 'ev')) {
                    let path = segments[1].path;
                    if (this.clientSite != path) {
                        this.clientSite = segments[1].path;
                        this.getConfig();
                    }
                }
            }
        });
    }

    initModel(): void {
        if (this.model === undefined) this.model = new AgencyServiceModel();
    }

    getCities() {
        return this.http.get<ICity[]>(`${environment.api.baseURL}/libCitiesByAgency/${this.config.agencyID}`)
            .pipe(catchError(this.handleError<ICity[]>('Cities', []))
            );
    }

    getTdm(cityID: string) {
        let settings = {
            agencyID: this.config.agencyID,
            cityID: cityID
        }
        return this.http.post(`${environment.api.baseURL}/libTDMByAgency`, settings)
            .pipe(catchError(this.handleError<ITDMGroupStrategy[]>('TDM', [])));
    }

    getAnalysisYears() {
        return this.http.get<IAnalysisYear[]>(`${environment.api.baseURL}/libAnalysisYear`)
            .pipe(catchError(this.handleError<ICity[]>('Analysis Years', [])));
    }

    private getConfig() {
        this.http.get<IAgencyConfig>(`${environment.api.baseURL}/agency/${this.clientSite}`)
            .pipe(tap(config => {
                this.config = config;
            }))
            .pipe(catchError(this.handleError<IAgencyConfig>('Configuration', null))
            )
            .subscribe(config => {
                this.configUpdateSource.next(config);
                this.model.agencyName = config.agencyName;
            });
    }

    getAgencyId() {
        return this.config.agencyID;
    }
}
