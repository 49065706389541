import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS  } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Import MSAL and MSAL browser libraries.
import { MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent } from '@azure/msal-angular';
import { PublicClientApplication } from '@azure/msal-browser/dist/app/PublicClientApplication';
import { InteractionType } from '@azure/msal-browser/dist/utils/BrowserConstants';

// Import the Azure AD B2C configuration
import { msalConfig, protectedResources } from './auth-config';

import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';

import { NgSelectModule } from '@ng-select/ng-select';


import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {MatTooltipModule} from '@angular/material/tooltip';


import { NgxSliderModule } from '@angular-slider/ngx-slider';

import { HeaderComponent } from './components/header/header.component';
import { MapWidgetComponent } from './components/map-widget/map-widget.component';
import { CalculatorInputFormComponent } from './components/calculator-input-form/calculator-input-form.component';
import { PresumptionsWidgetComponent } from './components/presumptions-widget/presumptions-widget.component';
import { UserProjectsComponent } from './components/user-projects/user-projects.component';
import { LoginComponent } from './components/login/login.component';
import { AuthenticationService } from './services/shared/authentication.service';
import { UserInfo } from './models/shared/user.model';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { KeyValuePipe } from './pipes/key-value.pipe';
import { PercentDisplayPipe } from './pipes/percent-display.pipe';
import { MainComponentComponent } from './components/main-component/main-component.component';
import { ResourcesComponent } from './components/resources/resources.component';
import { AltFuelComponent } from './components/alt-fuel/alt-fuel.component';
import { EvScreenComponent } from './components/ev-screen/ev-screen.component';
import { EvSiteScreenComponent } from './components/ev-site-details/ev-site-screen/ev-site-screen.component';
import { SiteScoreGridComponent } from './components/ev-site-details/site-score-grid/site-score-grid.component';
import { SiteDetailsGridComponent } from './components/ev-site-details/site-details-grid/site-details-grid.component';
import { SiteDetailsChartComponent } from './components/ev-site-details/site-details-chart/site-details-chart.component';
import { TokenInterceptor } from './interceptors/token-interceptor.interceptor';
import { SessionTimeoutComponent } from './components/session-timeout/session-timeout.component';
import { SitePhotosComponent } from './components/ev-site-details/site-photos/site-photos.component';
import { AfterIfDirective } from './directives/after-if.directive';
import { CapitalizeWithSpacesPipe } from './pipes/capitalize-with-spaces.pipe';
import { DecimalPipe } from '@angular/common';
import { P66EvSiteScreenComponent } from './components/p66-ev-site-details/p66-ev-site-screen/p66-ev-site-screen.component';
import { SiteFsvGridComponent } from './components/p66-ev-site-details/site-fsv-grid/site-fsv-grid.component';
import { ThemeLayerDetailsGridComponent } from './components/ev-site-details/theme-layer-details-grid/theme-layer-details-grid.component';
import { TooltipDirective } from './directives/tooltip.directive';

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        MapWidgetComponent,
        CalculatorInputFormComponent,
        LoginComponent,
        PresumptionsWidgetComponent,
        UserProjectsComponent,
        MainComponentComponent,
        ResourcesComponent,
        AltFuelComponent,
        EvScreenComponent,
        EvSiteScreenComponent,
        SiteScoreGridComponent,
        SiteDetailsGridComponent,
        SiteDetailsChartComponent,
        SessionTimeoutComponent,
        SitePhotosComponent,
        ThemeLayerDetailsGridComponent,


       // Pipes
       KeyValuePipe,
       PercentDisplayPipe,
       CapitalizeWithSpacesPipe,

       // directives
       AfterIfDirective,
         P66EvSiteScreenComponent,
         SiteFsvGridComponent,
         TooltipDirective,

    ],
    imports: [
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        FormsModule,
        HttpClientModule,
        NgSelectModule,
        ReactiveFormsModule,



        //Material
        MatDialogModule,
        MatInputModule,
        MatButtonModule,
        MatCardModule,
        MatFormFieldModule,
        MatIconModule,
        MatExpansionModule,
        MatCheckboxModule,
        MatSlideToggleModule,
        MatTabsModule,
        MatTooltipModule,

        // Third Party
        NgxSliderModule,

        // Import the HTTP client.
        HttpClientModule,

        // Initiate the MSAL library with the MSAL configuration object
        MsalModule.forRoot(new PublicClientApplication(msalConfig),
        {
            // The routing guard configuration.
            interactionType: InteractionType.Redirect,
        },
        {
            // MSAL interceptor configuration.
            // The protected resource mapping maps your web API with the corresponding app scopes. If your code needs to call another web API, add the URI mapping here.
            interactionType: InteractionType.Redirect,
            protectedResourceMap: new Map([])
        })
    ],
providers: [
    CapitalizeWithSpacesPipe,
    DecimalPipe,
    AuthenticationService,
    {
        provide: HTTP_INTERCEPTORS,
        useClass: TokenInterceptor,
        multi: true
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: MsalInterceptor,
        multi: true
    },
      MsalGuard
    ],
    bootstrap: [
        AppComponent,
        MsalRedirectComponent
    ],
})
export class AppModule {}
