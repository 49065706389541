import { Component, OnInit } from '@angular/core';
import { EvSite, SiteScoreRow } from 'src/app/models/ev/evSite.model';
import { EvLogicService } from 'src/app/services/ev/logic-services/ev-logic.service';
import { AgencyService } from 'src/app/services/shared/agency.service';

@Component({
  selector: 'app-site-score-grid',
  templateUrl: './site-score-grid.component.html',
  styleUrls: ['./site-score-grid.component.scss']
})
export class SiteScoreGridComponent {

    get siteCharacteristics(): SiteScoreRow[] {
        return this._evLogicService.model.siteCharacteristics;
    }

    get site(): EvSite {
        return this._evLogicService.model.site;
    }

    get allSiteAvgs(): number [] {
        return this._evLogicService.model.allSiteAvgScores;
    }

    get dmaAvgs(): number [] {
        return this._evLogicService.model.dmaAvgScores;
    }

    get stateWideSiteAvg(): number [] {
        return this._evLogicService.model.stateWideAvgScores;
    }

    get agencyName(): string {
        return this._agencyService.model.agencyName
    }

    constructor(
    private _evLogicService: EvLogicService,
    private _agencyService: AgencyService
    ) { }

}
