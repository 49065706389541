import { Injectable } from '@angular/core';
import { Observable, from, of, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { NotificationService } from '../shared/notification.service';
import { BaseService } from '../shared/base-service.service';
import { environment } from 'src/environments/environment';
import { VmtSettings } from 'src/app/models/vmt/vmt-settings.model';

export interface IImage {
    data: ImageData;
    dataUrl: string;
}

@Injectable({
    providedIn: 'root'
})
export class ImageService extends BaseService {
    constructor(http: HttpClient, notificationService: NotificationService) {
        super(http, notificationService);
    }

    uploadImage(image: IImage) {
        return this.http.post(`${environment.api.baseURL}/UploadImage`, image.dataUrl)
            .pipe(catchError(this.handleError('Upload Image', [], false))
        );
    }
}
