import { Component } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';
import { AuthenticationService } from 'src/app/services/shared/authentication.service';
import { NavigationService } from './services/shared/navigation.service';
import { AgencyService } from './services/shared/agency.service';

interface IFeature {
    title: string,
    isActive: boolean
}

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    logo = 'TREDLite_VMT_color.svg';
    title = 'TREDLite';
    disclaimerTitle = "Trip Reduction Environmental Dashboard";
    disclaimer = `<b>TREDLite (Trip Reduction and Environmental Dashboard)</b>
        is a transportation and environmental planning tool that evaluates the sustainability of projects.
        This tool allows for an assessment of Vehicle Miles Traveled (VMT), Greenhouse Gas (GHG) emissions, and other important
        environmentally sensitive indicators in addition to providing the ability to evaluate the effectiveness of various mitigation
        solutions to offset identified impacts.  Broadly, this planning tool provides the following analysis and information for projects:`;

    legal = `<p>By clicking "Accept" below, I hereby agree to the
        <a href="https://www.kimley-horn.com/khts-terms-of-use" target="_blank">Terms of Use</a> for use of
        TREDLIte and agree to the disclaimer above.
        TREDLite is the sole property of KHTS and unauthorized use and copying is strictly prohibited. All trademarks used in
        TREDLite are the property of their respective owners.</p>`;

    features: IFeature[] = [
        { title: 'Institute of Transportation Engineers (ITE) Trip Generation', isActive: true },
        { title: 'Transit Priority Area (TPA) evaluation', isActive: true },
        { title: 'NCHRP 684 Internal Capture Analysis', isActive: true },
        { title: 'VMT Threshold Analysis', isActive: true },
        { title: 'GHG Estimation of Mobile and Non-Mobile Sources', isActive: true },
        { title: 'Criteria Pollutants Analysis', isActive: true },
        { title: 'Transportation Demand Management (TDM) Evaluation', isActive: true },
        { title: 'VMT Fee-Based Mitigation Analysis (VMT Banking)', isActive: false }
    ];

    disclaimerContinue = `In many instances, this analysis tool is sufficient to use as the basis for determining environmental compliance.
        However, as with any planning tool, its application has limitations both in terms of the appropriate size and types of
        project for which it should be relied on as the sole basis of analysis.`;
    year: number;

    constructor(public httpClient: HttpClient,
        public AuthenticationService: AuthenticationService,
	    private navigationService: NavigationService,
        private agencyService: AgencyService,
        ) {
            let sub = this.agencyService.configUpdated$.subscribe(config => {
                let modal = document.getElementById('disclaimer');
                modal.classList.add('is-active');

                if (this.features.length)
                    this.features[this.features.length - 1].isActive = config.hasHomeBasedWork;
                sub.unsubscribe();
            });

            this.navigationService.mainRouteUpdated$.subscribe(route => {
                switch(route) {
                    case 'ev':
                        this.logo = 'TREDLite_EV_color.svg';
                        this.disclaimer = '<b>TREDLite EV</b> supports identification of favorable locations for public EV charging infrastructure.'
                        this.disclaimerContinue = null;
                        this.features = [];
                        break;
                    case 'sites':
                        this.logo = 'TREDLite_VMT_color.svg';
                        break;
                }
            });
        }

    ngOnInit() {
        this.year = new Date().getFullYear();
        if(!this.AuthenticationService.getLoggedIn() && sessionStorage.getItem('_userInfo')){
            this.AuthenticationService.getUserFromStorage();

            // TODO: don't show splash on redirect from login. Check logged in time?
        }else{

        }

        if (this.navigationService.clientUpdated$) {
            this.logo = 'TREDLite_EV_color.svg'
        }
    }

    onModalClose() {
        let modal = document.getElementById('disclaimer');
        modal.classList.remove('is-active');

        this.navigationService.startApp();
    }
}
