import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenService } from '../services/shared/token.service';
import { tap } from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor( private _tokenService: TokenService) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const accessToken = this._tokenService.getAccessToken();
        if (this.nullAndUndefinedCheck(accessToken)) {
            const modifiedReq = request.clone({
                headers: new HttpHeaders()
                .set('Content-Type','application/json')
                .set('Authorization', 'Bearer ' + accessToken)
            });
            return next.handle(modifiedReq).pipe(
                tap(httpEvent => {
                    if(httpEvent.type === 0) {
                        return;
                    }
                    if (httpEvent instanceof HttpResponse) {
                        if(httpEvent.headers.has('newaccesstoken') && httpEvent.headers.get('newaccesstoken') != "") {
                            this.updateAccessToken(httpEvent.headers.get('newaccesstoken'));
                        }
                    }
                })
            );
        }
        return next.handle(request);
    }

    nullAndUndefinedCheck(accessToken: string) {
        if (accessToken !== null && accessToken !== "undefined") {
            return true;
        }
        return false;
    }

    updateAccessToken(accessToken) {
        this._tokenService.setAccessToken(accessToken)
    }
}
