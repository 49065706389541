<div id="site-details-grid">
    <div class="grid-container">
        <div class="header grey"><strong>Site Details</strong></div>
        <ng-container *ngFor="let item of siteDetails | keyvalue | slice:0:(getHalfSize())">
            <div class="item"><strong>{{item.key | capitalizeWithSpaces}}</strong></div>
            <div class="item">{{item.value.propertyValue}}</div>
        </ng-container>
    </div>
    <div class="grid-container">
        <div class="header grey"></div>
        <ng-container *ngFor="let item of siteDetails | keyvalue | slice:(getHalfSize())">
            <div class="item"><strong>{{item.key | capitalizeWithSpaces}}</strong></div>
            <ng-container *ngIf="item.key === '2022 Fuel Sales Volume'; else noNumber">
                <div class="item">{{item.value.propertyValue | number}}</div>
            </ng-container>
            <ng-template #noNumber><div class="item">{{item.value.propertyValue}}</div></ng-template>
        </ng-container>
    </div>
</div>
