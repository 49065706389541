<div class="columns">
    <div class="column">
        <h2 class="title is-2 has-text-centered">Resources</h2>

        <div class="columns">
            <div class="colum is-6">
                <h3>Analyzing Vehicle Miles Traveled for CEQA Compliance</h3>
                <p>SB 743 Implementation guidelines for the County of Santa Cruz</p>
            </div>
            <div class="colum is-6">
                <figure class="image is-3by4">
                    <img src="../../../assets/images/resource1_cover.png" width="286" height="370">
                </figure>
            </div>
        </div>
    </div>
</div>
