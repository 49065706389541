import { CommonModule } from '@angular/common';
import { Component, ViewEncapsulation } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EvSite } from 'src/app/models/ev/evSite.model';
import { ThemeMapLayerDetail } from 'src/app/models/ev/theme-map-layer-detail.model';
import { SiteThemeLayerInfo } from 'src/app/models/shared/theme-layer.model';
import { EvLogicService } from 'src/app/services/ev/logic-services/ev-logic.service';
import { ThemeLayerService } from 'src/app/services/shared/theme-layer.service';

@Component({
  selector: 'app-theme-layer-details-grid',
  templateUrl: './theme-layer-details-grid.component.html',
  styleUrls: ['./theme-layer-details-grid.component.scss'],
})
export class ThemeLayerDetailsGridComponent {

    constructor(
        private _themeLayerService: ThemeLayerService,
        private _evLogicService: EvLogicService
        ) { }

    get site(): EvSite {
        return this._evLogicService.model.site;
    }

    get siteThemeLayerData(): SiteThemeLayerInfo[] {
        return this._themeLayerService.currentSiteThemeLayerData;
    }

    isLayerSiteValueNumber(layerName: string): boolean {
        return layerName === "BEV Sales by State";
    }

}
