<div class="px-2 py-2">
    <div *ngIf="projects.length === 0">
        <div class="content has-text-centered">
            <p class="title is-4">No projects have been saved yet.<p>
        </div>
    </div>

    <div *ngFor="let g of projectGroups" class="tile is-ancestor is-flex-wrap-wrap">
        <div *ngFor="let p of g" class="tile is-parent" [class.is-invisible]="p.isHidden">
            <article class="tile is-child box">
                <div>
                    <a class="is-size-3 has-text-weight-bold" [routerLink]="['/sites', this.clientSite, 'calculator']"
                        [queryParams]="{step:3, projectId:p.id}" queryParamsHandling="merge">{{p.name}}
                    </a>
                </div>
                <p class="subtitle">{{p.description}}</p>
                <figure class="image is-4by3">
                    <img src="{{p.url}}" alt="Project map image goes here">
                </figure>
            </article>
        </div>
    </div>
</div>
