export const environment = {
    production: true,
    baseURL: 'https://vmtstorage.z21.web.core.windows.net/',
    api: {
        baseURL: 'https://tredlite-dev.kimley-horn.com/api',
        rootURL: 'https://tredlite-dev.kimley-horn.com',
    },
    b2c: {
        authority: 'https://kimleyhorntechsolutionsdev.b2clogin.com/kimleyhorntechsolutionsdev.onmicrosoft.com/B2C_1_KimleyHornTechnologySolutions_SignUpSignIn',
        authorityDomain: 'kimleyhorntechsolutionsdev.b2clogin.com',
        clientId: 'e55efbfd-ac7e-4c0f-b172-0067f0bdb1a7',
        b2cPolicies: {
            signIn: 'B2C_1_KimleyHornTechnologySolutions_SignUpSignIn',
        },
        postLogoutRedirectUri: 'https://tredlite-dev.kimley-horn.com'
    }
};
