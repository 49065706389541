import { Component, OnInit, Output, EventEmitter, OnDestroy, AfterViewChecked } from '@angular/core';
import { ActivatedRoute, NavigationEnd, ParamMap, Router } from '@angular/router';
import { concat, from, Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';
import Chart from 'chart.js/auto';
import { isGuid } from 'src/app/utils/string-utils';

import { EsriLocationService, EsriLocatorResult } from 'src/app/services/shared/esri-location.service';
import { IteDataService, IIteLandUse, ILandUseRow } from 'src/app/services/vmt/ite-data.service';
import { MapInteractionService, FeatureSelectorType } from 'src/app/services/shared/map-interaction.service';
import { NotificationService, NotificationType, NotificationCloseType } from 'src/app/services/shared/notification.service';
import { PresumptionService } from 'src/app/services/vmt/presumption.service';
import { AgencyService } from 'src/app/services/shared/agency.service';
import { CalculatorService } from 'src/app/services/vmt/calculator.service';
import { ThemeLayerService } from 'src/app/services/shared/theme-layer.service';

import { BankInput, IOutput } from 'src/app/models/vmt/vmt-settings.model';
import { IPresumption, IPresumptionUpdate } from 'src/app/models/vmt/presumption.model';
import { IAgencyConfig, ICity } from 'src/app/models/shared/agency-data.model';
import { AirQuality, HighlightFeature, ITECode, VmtSettings } from 'src/app/models/vmt/vmt-settings.model';
import { IStrategyInput, ITDMStrategy, ITDMGroupStrategy } from 'src/app/models/vmt/tdm.model';
import { IProject } from 'src/app/models/shared/user.model';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { IImage } from 'src/app/services/vmt/image.service';
import { AuthenticationService } from 'src/app/services/shared/authentication.service';
import { VmtInfo } from 'src/app/models/vmt/vmt-summary.model';
import { AppConstants } from 'src/app/app.constants';
import { ClassBreakInfo, ClassBreak } from 'src/app/models/shared/map.model';
import { MatLegacyCheckboxChange } from '@angular/material/legacy-checkbox';

interface IBank {
    perUnitCost?: number,
    peopleRate?: number,
    absoluteVmt: number,
    absoluteVt?: number,
    feasibleMitigation:number,
    feasibleMitigationVt?:number,
    totalMinMitigation:number,
    totalMinMitigationVt?:number,
    remainingMitigation: number,
    remainingMitigationVt?: number,
    remainingAbsoulteVmt: number,
    remainingAbsoulteVt?: number,
    cost: number,
    costVt?: number,
    optionalFullMitigation: number,
    optionalFullMitigationVt?: number,
    optionalFullCost: number,
    optionalFullCostVt?: number,
    requiresOverride: boolean
    requiresOverrideVt?: boolean
}

interface IPriorityResult {
    layerTitle: string;
    percent: number;
    explanation?: string;
}

interface IChangedTdm {
    groupIndex: number;
    strategyIndex: number;
}

@Component({
    selector: 'app-calculator-input-form',
    templateUrl: './calculator-input-form.component.html',
    styleUrls: ['./calculator-input-form.component.scss'],
    moduleId: 'calculator'
})
export class CalculatorInputFormComponent implements OnInit, OnDestroy, AfterViewChecked {
    private subscriptions: Subscription[] = [];
    private imageType = "data:image/png;base64,";

    currentTabIndex: number = 0;
    nextText = 'Next';
    loaderText: string;
    hasPriorityLayers = false;
    isValidating: boolean;
    landUseMax = 10;
    maxDataPoints = 4;
    page: number;
    filename: string;
    projectName: string;
    cities: ICity[];
    analysisYear: number;
    analysisYears: number[] = [];
    projectContext: string;
    projectContexts: string[] = [
        'Low Density Suburb',
        'Suburban Center'
    ];
    vmtMetric: string;
    vmtMetrics: string[] = [
        'Total VMT per Service Population'
    ];
    vmtMetricsAbbrevidated: string[] = [
        'Total VMT',
        'Home-based'
    ];

    landUseList: ILandUseRow[] = [];
    defaultPresumptions: IPresumption[] = [];

    selectedCity: string;
    averageReduction: number = 15;

    locatorResult$: Observable<EsriLocatorResult[]>;
    locatorResultLoading = false;
    locatorResultInput$ = new Subject<string>();
    selectedLocatorResults: EsriLocatorResult;
    mapSource: number = 1;
    captureMethod: number = 1;

    landUses: IIteLandUse[] = [];
    landUseLoading = false;
    selectedLandUse: IIteLandUse;
    selectedSize: number;
    resultsWarning: string = 'If your project result is higher than the threshold, we recommend clicking the <strong>Mitigate VMT</strong> button to learn and decide on ways to mitigate your transportation impact. Otherwise, click Print Results.';
    output: IOutput[] = [{
        landUseType: '',
        capitaReduction: 0,
        capitaProjectWithMitigation: 0,
        dailyTripsProject: 0,
        dailyTripsReduction: 0,
        dailyTripsProjectWithMitigation: 0,
        iteCode: ' ',
        isTotalVmt: false,
        airQuality: new AirQuality(),

        pedAccessibility: 0,
        vmtPerServicePop: 0,
        vmtPerServicePopMitigation: 0,
        vtPerServicePop: 0,
        vtPerServicePopMitigation: 0,
        mitigationPercent: 0,
        vmtThreshold: 0,
        vtThreshold: 0,
        pedThreshold: 0,
        average: 0.0,
        threshold: 0.0,
        thresholdSource: '',
        capitaProject: 0.0,
        significance: 'Yes',
        totalVmt: 0.0,
        totalVmtMitigation: 0.0,
        index: 0,
        isPreexisting: true,
        presumptions: [],
        attributableVmt: 0,
        attributableVt: 0,
        tdmResults: []
    }];
    lastOutputCount = 0;
    charts: Chart[] = [];
    latestTazData: any[];
    tdmStrategies: ITDMGroupStrategy[] = [];
    selectedTdm: ITDMGroupStrategy[] = [];
    selectedTdmReduction: number[] = [0];
    landUseTdms: Array<ITDMGroupStrategy[]> = [];
    landUseBanks: IBank[] = [];
    perUnitCost: number = 0;
    feasibleMitigation: number = 0;
    totalBankCost: number = 0;
    totalBankCostVt: number = 0;
    totalOptionalBankCost: number = 0;
    totalOptionalBankCostVt: number = 0;
    priorityResults: IPriorityResult[] = [];
    hasVt = false;
    needFloorAreaRatioInput = false;

    agencyConfig: IAgencyConfig = {
        agencyAddress: null,
        agencyContact: null,
        agencyID: null,
        agencyName: null,
        AgencyPhone: null,
        hasHomeBasedWork: false,
        isStatewide: false,
        county: null,
        parcelLayers: null,
        priorityLayers: null,
        tazIdField: null,
        tazLayer: null,
        webmapId: null,
        xMax: 0,
        xMin: 0,
        yMax: 0,
        yMin: 0,
        hasPriFloorAreaRatio: false,
        minYear: 2017,
        maxYear: 2050,
        themeLayers: null
    };
    totalAirQuality: AirQuality = new AirQuality();
    totalTrips: number;
    screenshot: IImage;
    isLoggedIn: boolean;
    selectedProject: IProject;
    projectFormDesc: string;
    lastVmtSettings: VmtSettings;
    mapHighlights: HighlightFeature[];
    loadProjectID: string;
    graphTabIndex: number;
    landUseHasAirQuality: boolean = true;
    isMixedUse: boolean = false;
    loadedIteCodes: any;
    cityVmtPerServicePopulation: number = 0;
    cityVtPerServicePopulation: number = 0;
    floorAreaRatio: number = 0;
    tdmTripReductionMessage = 'TDM T-6 is selected. TDMs T-7 through T-11 will be cleared.';
    tdmParkingMessage = 'TDM T-12 is selected. TDM T-13 will be cleared.';
    isRendererSetUp: boolean = false;
    khPrimary: string;

    @Output() pageChange: EventEmitter<number> = new EventEmitter();
    constructor(private activatedRoute: ActivatedRoute, private router: Router, private esriLocationService: EsriLocationService,
        private iteDataService: IteDataService, private notificationService: NotificationService,
        private mapInteractionService: MapInteractionService, private presumptionService: PresumptionService,
        private agencyService: AgencyService, private calculatorService: CalculatorService,
        private authenticationService: AuthenticationService, public constants: AppConstants,
        private themeLayerService: ThemeLayerService) {
        mapInteractionService.selectionCompleted$.subscribe(() => {
            let tabs = document.querySelectorAll('.tabs li');
            tabs.forEach(tab => {
                tab.classList.remove('is-active');
            });
        });
        this.subscriptions.push(mapInteractionService.selectionToTazCompleted$.subscribe((data:any[]) => {
            this.latestTazData = data;

            if (data.length > 0 && !this.agencyConfig.hasHomeBasedWork) {
                let sub = this.calculatorService.getVmtSericePopulation(this.agencyConfig.agencyID, data[0]['id']).subscribe(result => {
                    sub.unsubscribe();
                    this.cityVmtPerServicePopulation = result;
                });
            }
        }));
        this.subscriptions.push(mapInteractionService.priorityLayerUpdated$.subscribe((result: IPriorityResult) => {
            let priority = this.priorityResults.find(p => p.layerTitle === result.layerTitle)
            if (priority)
                priority.percent = result.percent
            else
                this.priorityResults.push(result);
        }));
        this.subscriptions.push(mapInteractionService.takeScreenshotCompleted$.subscribe(screenshot => {
            this.screenshot = screenshot;
        }));
        this.subscriptions.push(mapInteractionService.highlightUpdated$.subscribe(highlights => {
            this.mapHighlights = highlights;
        }));
        this.subscriptions.push(mapInteractionService.parcelVisibilityChanged$.subscribe(isVisible => {
            let zoomMsg = document.getElementById('zoomMsg');
            if (zoomMsg) {
                if (isVisible) {
                    zoomMsg.classList.remove('is-danger');
                    zoomMsg.classList.add('is-accent');
                }
                else {
                    zoomMsg.classList.add('is-danger');
                    zoomMsg.classList.remove('is-accent');
                }
            }
        }));
        this.subscriptions.push(mapInteractionService.tazVisibilityChanged$.subscribe(isVisible => {
            if (isVisible && !this.isRendererSetUp) {
                if (this.agencyConfig.isStatewide)
                    this.setUpMapRenderers();
                else if (this.agencyConfig.agencyID.toLowerCase() === 'd99c8699-01be-4bda-85da-04c16ef06f38')
                    this.setUpMapRenderers('Palmdale TAZ');
            }
        }));
        this.subscriptions.push(mapInteractionService.cityChanged$.subscribe(city => {
            let jurisdiction = this.cities.find(c => c.CityName === city);
            if (jurisdiction) {
                this.selectedCity = jurisdiction.CityId;
                this.loadTdm();
            }
        }));

        this.subscriptions.push(authenticationService.loginStatusUpdated$.subscribe(isLoggedIn => {
            this.isLoggedIn = isLoggedIn;
        }));
    }

    ngOnInit(): void {
        this.page = 1;
        this.pageChange.emit(this.page);
        this.activatedRoute.queryParamMap.subscribe((paramMap: ParamMap) => {
            if (!paramMap.keys.length) return;
            this.page = parseInt(paramMap.get('step'));
            this.pageChange.emit(this.page);
            if (this.page >= 1
                //&& this.authenticationService.getLoggedIn()
                ) {
                let sidebar = document.getElementById('sidebar');
                sidebar.classList.remove('hide');
            }
            let projectId = paramMap.get('projectId');
            if (projectId)
                this.loadProjectID = projectId;
            else
                this.loadProjectID = null;
        });
        this.loadlocatorResult();
        this.loadAgencyConfig();

        this.projectContext = this.projectContexts[0];
        this.vmtMetric = this.vmtMetrics[0];
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(x => x.unsubscribe());
        this.sumChart.destroy();
    }

    ngAfterViewInit() {
        let element = document.getElementsByClassName('is-primary')[0] as HTMLElement;
        this.khPrimary = window.getComputedStyle(element).backgroundColor;
        this.initiateFeatureSelectControl();
    }

    ngAfterViewChecked(): void {
        if (this.lastOutputCount == this.output.length)
            return;
        document.querySelectorAll('[role="tab"]').forEach((element, i) => {
            element.classList.add('button');
            if (i > 0)
                element.classList.add('is-outlined');
        });
        this.lastOutputCount = this.output.length;
    }

    onNextClick() {
        let prevPage = this.page;
        let checkForCalculate = false;
        switch (prevPage) {
            case this.constants.PAGE_INFO:
                if (this.isMixedUse) {
                    this.goToPage(this.constants.PAGE_INTERNAL_CAPTURE);
                }
                else {
                    checkForCalculate = true;
                }
                break;
            case this.constants.PAGE_INTERNAL_CAPTURE:
                checkForCalculate = true;
                break;
            default:
                this.goToPage(this.page + 1);
                break;
        }

        if (checkForCalculate) {
            let goToPage = this.hasPriorityLayers ? this.constants.PAGE_PRIORITY : this.constants.PAGE_CALCULATE;
            this.goToPage(goToPage);
        }
    }

    onBackClick() {
        if (this.page === this.constants.PAGE_CALCULATE) {
            if (this.hasPriorityLayers) {
                this.goToPage(this.constants.PAGE_PRIORITY);
            }
            else {
                this.goToPage(this.constants.PAGE_INTERNAL_CAPTURE);
                if (!this.isMixedUse)
                    this.goToPage(this.constants.PAGE_INFO);
            }
        } else {
            if (this.page === this.constants.PAGE_PRIORITY) {
                if (this.isMixedUse)
                    this.goToPage(this.constants.PAGE_INTERNAL_CAPTURE);
                else
                    this.goToPage(this.constants.PAGE_INFO);
            } else
                this.goToPage(this.page - 1);
        }

        this.updateUrl();
        this.pageChange.emit(this.page);
    }

    // Helps set the visibility, styling, and function of Next button
    goToPage(page: number) {
        // Is Next click or Back click
        let isNext = page > this.page;
        if (isNext && !this.validateInputs()) {
            return;
        }

        switch (page) {
            case this.constants.PAGE_INFO:
                this.nextText = 'Calculate';
                if (this.isMixedUse || this.hasPriorityLayers)
                    this.nextText = 'Next';
                if (isNext) {
                    this.mapInteractionService.centerOnSelection();
                    this.getTazIds();
                }
                this.mapInteractionService.togglePriorityLayers(false);
                break;
            case this.constants.PAGE_INTERNAL_CAPTURE:
                this.nextText = 'Calculate';
                if (this.hasPriorityLayers)
                    this.nextText = 'Next';
                if (isNext)
                    this.calculateInternalCapture();
                this.mapInteractionService.togglePriorityLayers(false);
                break;
            case this.constants.PAGE_PRIORITY:
                this.nextText = 'Calculate';
                // TODO: move map and show layers
                this.mapInteractionService.togglePriorityLayers(true);
                break;
            case this.constants.PAGE_CALCULATE:
                this.nextText = 'Mitigate VMT';
                if (isNext) {
                    this.loaderText = 'Calculating';
                    let loader = document.getElementById('pageLoader');
                    loader.classList.add('is-active');
                    this.mapInteractionService.takeScreenshot();
                    this.calculate(this.latestTazData);
                }
                break;
            case this.constants.PAGE_TDM:
                this.nextText = this.agencyConfig.hasHomeBasedWork ? 'VMT Bank' : "";
                if (this.agencyConfig.agencyID === 'a9a48cf6-0b97-42d2-9e77-7176e92ff82e')
                    this.nextText = "";
                break;
            case this.constants.PAGE_BANK:
                if (isNext && this.feasibleMitigation > 0 && this.perUnitCost > 0)
                    this.calculateBanking();
                break;
            case this.constants.PAGE_LOCATION:
            default:
                this.nextText = 'Next';
                break;
        }


        if (page === this.constants.PAGE_CALCULATE) {
            setTimeout(() => {
                this.updatePageAndUrl(page);
            }, 500);
        } else
            this.updatePageAndUrl(page);

        this.isValidating = false;
    }

    updatePageAndUrl(page: number) {
        this.page = page;
        this.updateUrl();
        this.pageChange.emit(this.page);
    }

    updateUrl() {
        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: {
                step: this.page
            },
            replaceUrl: true
        });
    }

    onPrintClick() {
        let vmtInfo = new VmtInfo();
        this.landUseList.forEach((lu, i) => {
            let landUseTdms = this.lastVmtSettings.iteCodes[i].tdmUserInputs.filter(x => x.IsUsed);
            let tdmList = [];
            landUseTdms.forEach(userInput => {
                let inputType = '';
                let prefix = ''
                switch(userInput.InputType) {
                    case 'Percent':
                        inputType = '%';
                        break;
                    case 'Money':
                        prefix = '$';
                        break;
                }

                this.landUseTdms[i].filter(g => g.Strategy.find(s => {
                    let isMatch = s.TDMID === userInput.TDMID;
                    if (isMatch)
                        tdmList.push(`${s.TDM} - ${(prefix)}${(userInput.Input)}${inputType} - ${(s.Reduction).toFixed(2)}%`);
                    return isMatch;
                }));
            });
            vmtInfo.landUses.push({
                code: lu.displayName,
                intensity: lu.intensity,
                unit: lu.defaultUnit,
                chartImageString: '',
                CO: this.output[i].airQuality.CO,
                CO2: this.output[i].airQuality.CO2,
                NOX: this.output[i].airQuality.NOX,
                ROG: this.output[i].airQuality.ROG,
                SOX: this.output[i].airQuality.SOX,
                PM2_5: this.output[i].airQuality.PM2_5,
                PM10: this.output[i].airQuality.PM10,
                MobileCO: this.output[i].airQuality.COMobile,
                MobileCO2: this.output[i].airQuality.CO2Mobile,
                MobileNOX: this.output[i].airQuality.NOXMobile,
                MobileROG: this.output[i].airQuality.ROGMobile,
                MobileSOX: this.output[i].airQuality.SOXMobile,
                MobilePM2_5: this.output[i].airQuality.PM2_5Mobile,
                MobilePM10: this.output[i].airQuality.PM10Mobile,

                COMitigation: this.output[i].airQuality.COMobile - this.output[i].airQuality.MitigationCO,
                ROGMitigation: this.output[i].airQuality.ROGMobile - this.output[i].airQuality.MitigationROG,
                NOXMitigation: this.output[i].airQuality.NOXMobile - this.output[i].airQuality.MitigationNOX,
                SOXMitigation: this.output[i].airQuality.SOXMobile - this.output[i].airQuality.MitigationSOX,
                PM2_5Mitigation: this.output[i].airQuality.PM2_5Mobile - this.output[i].airQuality.MitigationPM2_5,
                PM10Mitigation: this.output[i].airQuality.PM10Mobile - this.output[i].airQuality.MitigationPM10,
                CO2Mitigation: this.output[i].airQuality.CO2Mobile - this.output[i].airQuality.MitigationCO2,

                COWithMitigation: this.output[i].airQuality.MitigationCO,
                ROGWithMitigation: this.output[i].airQuality.MitigationROG,
                NOXWithMitigation: this.output[i].airQuality.MitigationNOX,
                SOXWithMitigation: this.output[i].airQuality.MitigationSOX,
                PM2_5WithMitigation: this.output[i].airQuality.MitigationPM2_5,
                PM10WithMitigation: this.output[i].airQuality.MitigationPM10,
                CO2WithMitigation: this.output[i].airQuality.MitigationCO2,

                regionalAverage: this.output[i].average,
                threshold: this.agencyConfig.hasHomeBasedWork ? this.output[i].threshold : this.output[i].vmtThreshold,
                isSignificant: this.output[i].significance,
                vmtName: this.agencyConfig.hasHomeBasedWork ? (this.output[i].landUseType === 'Residential' ? 'VMT/Capita' : 'HBW VMT/Emp') : 'VMT/Service Population',
                vmtProject: this.agencyConfig.hasHomeBasedWork ? this.output[i].capitaProject : this.output[i].attributableVmt,
                vmtMitigation: this.agencyConfig.hasHomeBasedWork ? this.output[i].capitaReduction : this.output[i].mitigationPercent,
                vmtProjectWithMitigation: this.agencyConfig.hasHomeBasedWork ? this.output[i].capitaProjectWithMitigation : this.output[i].vmtPerServicePopMitigation,
                tripsName: 'Daily Trips',
                trips: this.output[i].dailyTripsProject,
                tripsMitigation: this.output[i].dailyTripsReduction,
                tripsWithMitigation: this.output[i].dailyTripsProjectWithMitigation,
                totalVmt: this.agencyConfig.hasHomeBasedWork ? lu.totalVmt : this.output[i].totalVmt,
                IsAffordableHousing: this.output[i].presumptions[0].isSelected,
                IsLocalServing: this.output[i].presumptions[1].isSelected,
                InternalCaptureRate: lu.internalCapture,

                vtName: 'VT/Service Population',
                vtProject: this.output[i].attributableVt,
                vtMitigation: this.output[i].mitigationPercent,
                vtProjectWithMitigation: this.output[i].vtPerServicePopMitigation,
                totalVt: 0,
                vtThreshold: this.output[i].vtThreshold,
                pedAccessibilityScore: this.output[i].pedAccessibility,
                pedAccessibilityThreshold: this.output[i].pedThreshold,
                tdmAsString: tdmList.length ? tdmList.join(";") : 'No TDM strategies selected.'
            });
        });
        this.defaultPresumptions.forEach(p => {
            if (!p.isProject) return;
            vmtInfo.presumptions.push({
                name: p.title,
                isChecked: p.isSelected
            });
        });
        vmtInfo.emissions.push({name: 'CO (lb/day)', mobile: this.totalAirQuality.COMobile, withMitigation: this.totalAirQuality.MitigationCO, nonMobile: this.totalAirQuality.CO});
        vmtInfo.emissions.push({name: 'ROG (lb/day)', mobile: this.totalAirQuality.ROGMobile, withMitigation: this.totalAirQuality.MitigationROG, nonMobile: this.totalAirQuality.ROG});
        vmtInfo.emissions.push({name: 'NOX (lb/day)', mobile: this.totalAirQuality.NOXMobile, withMitigation: this.totalAirQuality.MitigationNOX, nonMobile: this.totalAirQuality.NOX});
        vmtInfo.emissions.push({name: 'SOX (lb/day)', mobile: this.totalAirQuality.SOXMobile, withMitigation: this.totalAirQuality.MitigationSOX, nonMobile: this.totalAirQuality.SOX});
        vmtInfo.emissions.push({name: 'PM2.5 (lb/day)', mobile: this.totalAirQuality.PM2_5Mobile, withMitigation: this.totalAirQuality.MitigationPM2_5, nonMobile: this.totalAirQuality.PM2_5});
        vmtInfo.emissions.push({name: 'PM10 (lb/day)', mobile: this.totalAirQuality.PM10Mobile, withMitigation: this.totalAirQuality.MitigationPM10, nonMobile: this.totalAirQuality.PM10});
        vmtInfo.emissions.push({name: 'CO2 (mt/year)', mobile: this.totalAirQuality.CO2Mobile, withMitigation: this.totalAirQuality.MitigationCO2, nonMobile: this.totalAirQuality.CO2});

        if (this.hasPriorityLayers)
        {
            this.priorityResults.forEach((result, i) => {
                vmtInfo.priorityAreas.push(
                    {
                        description: result.layerTitle,
                        coverage: result.percent,
                        explanation: result.percent === 100 ? 'N/A' : result.explanation,
                        title: '',
                        metric: this.agencyConfig.priorityLayers[i].metric,
                        threshold: this.agencyConfig.priorityLayers[i].threshold
                    }
                );
            });
        }

        vmtInfo.projectName = this.projectName;
        vmtInfo.projectDescription = this.selectedProject? this.selectedProject.description : '';
        vmtInfo.citywideVmt = this.cityVmtPerServicePopulation;
        vmtInfo.citywideVt = this.cityVtPerServicePopulation;

        let city = this.cities.find(c => c.CityId === this.selectedCity);
        if (city)
            vmtInfo.location = city.CityName;
        else
            vmtInfo.location = this.selectedLocatorResults.text;

        vmtInfo.agencyId = this.agencyConfig.agencyID;
        vmtInfo.analysisYear = this.analysisYear;
        vmtInfo.projectContextSetting = this.projectContext,
        vmtInfo.county = '';
        vmtInfo.imageString = this.screenshot.dataUrl.substring(this.imageType.length);
        vmtInfo.totalCO = this.totalAirQuality.CO;
        vmtInfo.totalCO2 = this.totalAirQuality.CO2;
        vmtInfo.totalNOX = this.totalAirQuality.NOX;
        vmtInfo.totalROG = this.totalAirQuality.ROG;
        vmtInfo.totalSOX = this.totalAirQuality.SOX;
        vmtInfo.totalPM2_5 = this.totalAirQuality.PM2_5;
        vmtInfo.totalPM10 = this.totalAirQuality.PM10;
        vmtInfo.isNchrp = this.captureMethod == 1,

        this.loaderText = 'Getting the report';
        let loader = document.getElementById('pageLoader');
        loader.classList.add('is-active');

        this.calculatorService.getReport('vmt', vmtInfo).subscribe((response: any) => {
            if (response.success) {
                let url = `data:application/pdf;base64,${response.fileContents}`;
                fetch(url).then(res => res.blob()).then(decodedPdf => {
                    let blob = new Blob([decodedPdf], {type: response.mimeType});
                    let url = URL.createObjectURL(blob);
                    window.open(url, '_blank');
                });
            };
            loader.classList.remove('is-active');
            setTimeout(() => {
                loader.classList.remove('is-active');
            }, 1000);
        });
    }

    onSaveClick() {
        // Existing project
        if (this.selectedProject && this.selectedProject.id) {
            this.saveProject();
            return;
        }

        // New project - need more info
        let modal = document.getElementById('saveModal');
        modal.classList.add('is-active');
    }

    onNewSaveClick() {
        this.lastVmtSettings.projectName = this.projectName;
        this.lastVmtSettings.projectDescription = this.projectFormDesc;
        this.saveProject();
        this.projectFormDesc = null;
        this.onSaveModalClose();
    }

    onSaveModalClose() {
        let modal = document.getElementById('saveModal');
        modal.classList.remove('is-active');
    }

    saveProject() {
        if (this.authenticationService.IsLoggedIn)
            this.lastVmtSettings.userId = this.authenticationService.getUser().userId;
        this.lastVmtSettings.presumptionIDs = [];
        this.defaultPresumptions.forEach(p => {
            if (p.isSelected && p.isProject)
                this.lastVmtSettings.presumptionIDs.push(p.id);
        });

        this.output.forEach((output, i) => {
            output.presumptions.forEach(p => {
                if (p.isSelected)
                    this.lastVmtSettings.iteCodes[i].presumptionIDs.push(p.id);
            });
        });

        let userProject = {
            image: this.screenshot.dataUrl,
            calculateObject: this.lastVmtSettings
        };
        this.calculatorService.saveResults(userProject).subscribe((projectId: string) => {
            if (!projectId.length) {
                this.notificationService.showNotification(NotificationType.Error, 'An error occurred while saving the project.',
                    NotificationCloseType.Self);
                return;
            }
            this.selectedProject = {
                id: projectId,
                name: this.projectName,
                isHidden: false,
                url: null,
                description: this.projectFormDesc
            };
            this.lastVmtSettings.projectId = this.selectedProject.id;
            this.lastVmtSettings.projectName = this.selectedProject.name;
            this.notificationService.showNotification(NotificationType.Success, 'Project saved.', NotificationCloseType.Self);
            return;
        })
    }

    onFileChange(fileInput: any) {
        if (fileInput.target.files && fileInput.target.files[0]) {
            this.filename = fileInput.target.files[0].name.toLowerCase();
            let uploadStatus = document.getElementById('upload-status');
            let uploader = document.getElementById('uploader');
            if (this.filename.indexOf(".zip") !== -1) {//is file a zip - if not notify user
                uploadStatus.classList.remove('is-danger');
                uploader.classList.remove('is-danger');
                this.mapInteractionService.uploadShapefile(this.filename);
            }
            else {
                uploadStatus.classList.add('is-danger');
                uploader.classList.add('is-danger');
                uploadStatus.innerHTML = 'Add shapefile as .zip file';
            }
        }
    }

    locatorTrackByFn(item: EsriLocatorResult) {
        return item.magicKey;
    }

    onLocatorChange(locatorResult: EsriLocatorResult) {
        this.esriLocationService.getLocationFromAddress(locatorResult);
    }

    onCityChange(newCity: ICity) {
        if (newCity.Latitude && newCity.Longitude)
            this.mapInteractionService.mapGoTo([newCity.Latitude, newCity.Longitude]);
        this.loadTdm();
    }

    loadlocatorResult() {
        this.locatorResult$ = concat(
            of([]), // default items
            this.locatorResultInput$.pipe(
                distinctUntilChanged(),
                tap(() => this.locatorResultLoading = true),
                switchMap(term => this.esriLocationService.getAddresses(term).pipe(
                    catchError(() => of([])), // empty list on error
                    tap(() => this.locatorResultLoading = false)
                ))
            )
        );
    }

    landUseTrackByFn(item: EsriLocatorResult) {
        return item.magicKey;
    }

    async loadLandUses() {
        this.landUseLoading = true;
        let getLandUses = await this.iteDataService.getLandUses(this.agencyConfig.agencyID);
        getLandUses.subscribe(x => {
            this.landUses = x;
            this.landUseLoading = false;
        });
    }

    loadCities() {
        this.agencyService.getCities().subscribe(cities => {
            this.cities = cities;
            if (cities.length === 1) {
                this.selectedCity = cities[0].CityId;
                this.onCityChange(cities[0]);
            }
        });
    }

    loadTdm() {
        this.agencyService.getTdm(this.selectedCity).subscribe((tdmStrategies: ITDMGroupStrategy[]) => {
            this.tdmStrategies = tdmStrategies;
            this.tdmStrategies.forEach(group => {
                group.CurrentReduction = 0;
                group.Strategy.forEach(strategy => {
                    strategy.Reduction = 0;
                });
            });

            let defaultTdm = JSON.parse(JSON.stringify(this.tdmStrategies));
            this.landUseTdms.push(defaultTdm);
            defaultTdm = JSON.parse(JSON.stringify(this.tdmStrategies));
            this.selectedTdm = defaultTdm;
        });
    }

    async loadAgencySpecificData() {
        try {
            await this.loadLandUses();
        } catch (error) {
            // TODO: something with land use error
        }
        this.loadCities();
        this.loadAnalysisYears();
        this.startCharts(0);
        if (this.loadProjectID)
            this.loadProjectSettings(this.loadProjectID);
        if (this.agencyConfig.agencyID.toLowerCase() === 'b66c8459-8236-4dd2-b9c5-0a89dfc7f9cf')
            this.averageReduction = 13;
        else if (this.agencyConfig.agencyID.toLowerCase() === 'd99c8699-01be-4bda-85da-04c16ef06f38')
            this.averageReduction = 16.8;

        this.subscriptions.push(this.presumptionService.getPresumptions(this.agencyConfig.agencyID).subscribe(x => {
            this.defaultPresumptions = x;
            this.defaultPresumptions.forEach(p => {
                if (p.isProject) return;
                this.output[0].presumptions.push({
                    id: p.id,
                    title: p.title,
                    text: p.text,
                    oprPageNum: p.oprPageNum,
                    isSelected: false,
                    isProject: false
                });
            });
        }));
    }

    loadAnalysisYears() {
        this.agencyService.getAnalysisYears().subscribe(years => {
            years.forEach(config => {
                if (config.Year >= this.agencyConfig.minYear && config.Year <= this.agencyConfig.maxYear)
                    this.analysisYears.push(config.Year);
            });
            this.analysisYear = this.analysisYears[0];
        });
    }

    loadAgencyConfig() {
        this.agencyService.configUpdated$.subscribe(config => {
            this.agencyConfig = config;
            this.hasVt = !this.agencyConfig.hasHomeBasedWork;
            if (this.agencyConfig.agencyID === 'd47dc593-986d-431e-b33f-3715627903fc')
                this.hasVt = false;
            this.loadAgencySpecificData();
            this.hasPriorityLayers = this.agencyConfig.priorityLayers.length > 0;
            this.themeLayerService.themeLayers = this.agencyConfig.themeLayers;
        });
        if (!this.agencyConfig.hasHomeBasedWork) {
            this.maxDataPoints = 5;
            if (!this.hasVt)
                this.maxDataPoints--;
        }
    }

    onAddLandUseClick() {
        if (!this.selectedLandUse) return;
        if (!this.selectedSize || this.selectedSize === 0) {
            let sizeInput = document.getElementById('size-input');
            let sizeHelp = document.getElementById('size-help');
            if (sizeInput)
                sizeInput.classList.add('is-danger');
            if (sizeHelp)
                sizeHelp.classList.remove('is-invisible');
            return;
        }

        if (this.landUseList.length === this.landUseMax) {
            this.notificationService.showNotification(NotificationType.Warning,
                `The maxium number of land uses is ${this.landUseMax}.`,
                NotificationCloseType.Self | NotificationCloseType.DeleteButton);
            return;
        }

        this.selectedLandUse.intensity = this.selectedSize;

        let metricIndex = this.vmtMetrics.indexOf(this.vmtMetric);
        this.selectedLandUse.metricDisplay = this.vmtMetricsAbbrevidated[metricIndex];
        // this.selectedLandUse.isTotalVmt = metricIndex == 0;
        this.selectedLandUse.isTotalVmt = false;
        let row = {};
        Object.assign(row, this.selectedLandUse);
        row['rowId'] = Date.now();
        this.landUseList.push(row as ILandUseRow);

        this.selectedSize = null;
        this.checkMixedLandUse();
    }

    onDeleteLandUseClick(rowId: number) {
        let index = this.landUseList.map(x => {
            return x.rowId
        }).indexOf(rowId);
        this.landUseList.splice(index, 1);
        this.checkMixedLandUse();
    }

    checkMixedLandUse() {
        if (this.landUseList.length === 1)
            this.isMixedUse = false;
        this.landUseList.forEach((u, i) => {
            if (i === 0) return;
            if (u.iteCode !== this.landUseList[0].iteCode){
                this.isMixedUse = true;
            }
        });
        if (!this.isMixedUse){
            this.landUseList.forEach(u => {
                u.internalCapture = 0;
            });
        }
        this.nextText = this.hasPriorityLayers ? 'Next' : 'Calculate';
    }

    onSelectedSizeChange(newSize: number) {
        this.selectedSize = newSize;
        if (newSize > 0) {
            let sizeInput = document.getElementById('size-input');
            let sizeHelp = document.getElementById('size-help');
            if (sizeInput)
                sizeInput.classList.remove('is-danger');
            if (sizeHelp)
                sizeHelp.classList.add('is-invisible');
        }
    }

    initiateFeatureSelectControl() {
        let tabs = document.querySelectorAll('.tabs li');

        tabs.forEach(tab => {
            tab.addEventListener('click', event => {
                let htmlElement = event.target as HTMLElement;
                let ulElement = htmlElement.closest('ul');
                let liElement = htmlElement.closest('li');

                ulElement.querySelectorAll('li').forEach(element => {
                    element.classList.remove('is-active');
                });
                setTimeout(() => {
                    liElement.classList.add('is-active');
                }, 100);
            });
        });
    }

    onMapSourceChanged() {
        setTimeout(() => {
            this.initiateFeatureSelectControl();
        }, 500);
    }

    selectByPolygon() {
        this.mapInteractionService.updateFeatureSelectorType(FeatureSelectorType.Shape);
    }

    selectByRectangle() {
        this.mapInteractionService.updateFeatureSelectorType(FeatureSelectorType.Box);
    }

    selectByPoint() {
        this.mapInteractionService.updateFeatureSelectorType(FeatureSelectorType.Single);
    }

    onClearClick() {
        this.mapInteractionService.clearSelection();
    }

    onUndoClick() {
        this.mapInteractionService.undoSelection();
    }

    validateInputs(): boolean {
        let isValid = true;

        switch (this.page) {
            case this.constants.PAGE_LOCATION:
                this.isValidating = true;
                isValid = this.testValidation('jurisdiction') && this.testValidation('parcel');
                break;
            case this.constants.PAGE_INFO:
                this.isValidating = true;
                isValid = this.testValidation('project-name') && this.testValidation('land-uses')
                    && (this.agencyConfig.hasHomeBasedWork || (this.testValidation('vmt-service-pop') && this.testValidation('vt-service-pop')));
                break;
            case this.constants.PAGE_INTERNAL_CAPTURE:
                this.isValidating = true;
                isValid = this.testValidation('internal-capture');
                break;
            case this.constants.PAGE_PRIORITY:
                this.isValidating = true;
                isValid = this.testValidation('priority');
                break;
        }

        return isValid;
    }

    testValidation(input: string, options: any = null): boolean {
        switch (input) {
            case 'project-name':
                return this.projectName && this.projectName !== '';
            case 'land-uses':
                return this.landUseList.length > 0;
            case 'jurisdiction':
                return this.selectedCity && this.selectedCity != '';
            case 'parcel':
                return this.mapHighlights && this.mapHighlights.length > 0;
            case 'internal-capture':
                return this.captureMethod == 1 || (this.captureMethod === 2 && ((options && this.landUseList.find(u => u.iteCode === options).internalCapture > 0) ||
                    this.landUseList.every(u => u.internalCapture > 0)));
            case 'vmt-service-pop':
                return this.cityVmtPerServicePopulation > 0;
            case 'vt-service-pop':
                return this.hasVt ? this.cityVtPerServicePopulation > 0 : true;
            case 'priority':
                if (!this.agencyConfig.hasPriFloorAreaRatio)
                    return true;
                else {
                    // FAR only required if box is checked
                    return this.needFloorAreaRatioInput ? (this.floorAreaRatio >= 0.75) : true;
                }
        }
        return false;
    }

    updatePresumption(update: IPresumptionUpdate) {
        let presumption = this.defaultPresumptions.find(p => p.id === update.id);
        if (presumption)
            presumption.isSelected = update.isSelected;
    }

    sumChart: Chart;
    doSummaryChart() {
        let capitaOptions = {
            scales: {
                y: {
                    beginAtZero: true,
                },
                x: {
                    grid: {
                        display: false
                    },
                    ticks: {
                        display: false
                    }
                }
            },
            plugins: {
                title: {
                    display: true,
                    text: this.agencyConfig.hasHomeBasedWork ? 'HBW VMT/Employee' : 'VMT & VT per Service Population'
                },
                legend: {
                    position: 'bottom',
                    labels: {
                        usePointStyle: true
                    }
                }
            },
            responsive: true,
            maintainAspectRatio: false
        };

        // change to rgba if want opaque
        let projectColor = '#005f7f';
        let project2Color = '#82abbc';
        let mitigationColor = '#b8be14';
        let mitigation2Color = '#ffc843';
        let thresholdColor = '#232323';
        let chartData = {};

        chartData = {
            labels: ['', '', '', ''],
            datasets: [
            {
                type: 'line',
                order: 1,
                label: 'VMT Threshold',
                data: [],
                backgroundColor: thresholdColor,
                borderColor: thresholdColor,
                pointRadius: 0,
                pointBorderWidth: 5,
                pointStyle: 'line'
            }, {
                type: 'bar',
                order: 3,
                label: 'VMT',
                data: [],
                backgroundColor: projectColor,
                borderColor: projectColor,
                borderWidth: 1,
                pointStyle: 'rect'
            }, {
                type: 'bar',
                order: 4,
                label: 'VMT with Mitigation',
                data: [],
                backgroundColor: mitigationColor,
                borderColor: mitigationColor,
                borderWidth: 1,
                pointStyle: 'rect'
            }]
        };

        if (this.hasVt) {
            chartData['datasets'].push({
                type: 'line',
                order: 2,
                label: 'VT Threshold',
                data: [],
                backgroundColor: thresholdColor,
                borderColor: thresholdColor,
                borderDash: [10,5],
                pointRadius: 0,
                pointStyle: 'dash'
            });
            chartData['datasets'].push({
                type: 'bar',
                order: 5,
                label: 'VT',
                data: [],
                backgroundColor: project2Color,
                borderColor: project2Color,
                borderWidth: 1,
                pointStyle: 'rect'
            });
            chartData['datasets'].push({
                type: 'bar',
                order: 6,
                label: 'VT with Mitigation',
                data: [],
                backgroundColor: mitigation2Color,
                borderColor: mitigation2Color,
                borderWidth: 1,
                pointStyle: 'rect'
            });
        }

        let context = document.getElementById(`vmtChart_total`);
        if (context) {
            let sumChart = this.sumChart;
            if (!sumChart) {
                this.sumChart = new Chart(context, {
                    data: chartData,
                    options: capitaOptions
                });
                sumChart = this.sumChart;
            }

            let thresholds = [];
            for (let i = 0; i < this.maxDataPoints; i++) {
                thresholds[i] = 1;
            }

            this.updateChart(sumChart, 0, thresholds);
            if (this.hasVt)
                this.updateChart(sumChart, 1, thresholds);

            sumChart.options.plugins.title.text = 'Total VMT/Service Population';
            this.updateChart(sumChart, 2, [null, this.cityVmtPerServicePopulation, null, null, null]);
            this.updateChart(sumChart, 3, [null, this.getSummaryVmtMitigation() , null, null, null]);
            this.updateChart(sumChart, 4, [null, null, this.cityVtPerServicePopulation, null, null]);
            this.updateChart(sumChart, 5, [null, null, this.getSummaryVtMitigation(), null, null]);

            let vmtThresholds = [];
            let vtThresholds = [];
            for (let i = 0; i < this.maxDataPoints; i++) {
                vmtThresholds[i] = this.output[0].vmtThreshold;
                vtThresholds[i] = this.output[0].vtThreshold;
            }
            this.updateChart(sumChart, 0, vmtThresholds);
            if (this.hasVt)
                this.updateChart(sumChart, 1, vtThresholds);
        }


    }

    startCharts(chartIndex: number) {
        let servicePopText = `VMT${this.hasVt ? ' & VT': ''} per Service Population`;
        let capitaOptions = {
            scales: {
                y: {
                    beginAtZero: true,
                },
                x: {
                    grid: {
                        display: false
                    },
                    ticks: {
                        display: false
                    }
                }
            },
            plugins: {
                title: {
                    display: true,
                    text: this.agencyConfig.hasHomeBasedWork ? 'HBW VMT/Employee' : servicePopText
                },
                legend: {
                    position: 'bottom',
                    labels: {
                        usePointStyle: true
                    }
                }
            },
            responsive: true,
            maintainAspectRatio: false
        };

        // change to rgba if want opaque
        let projectColor = this.khPrimary;
        let project2Color = '#82abbc';
        let mitigationColor = '#b8be14';
        let mitigation2Color = '#ffc843';
        let thresholdColor = '#232323';

        let result = this.output[chartIndex];
        let chartData = {};

        if (this.agencyConfig.hasHomeBasedWork) {
            chartData = {
                labels: ['', '', '', ''],
                datasets: [{
                    type: 'bar',
                    order: 2,
                    label: 'Project',
                    data: [],
                    backgroundColor: projectColor,
                    borderColor: projectColor,
                    borderWidth: 1,
                    pointStyle: 'rect'
                }, {
                    type: 'bar',
                    order: 3,
                    label: 'Project with Mitigation',
                    data: [],
                    backgroundColor: mitigationColor,
                    borderColor: mitigationColor,
                    borderWidth: 1,
                    pointStyle: 'rect'
                }, {
                    type: 'line',
                    order: 1,
                    label: 'Threshold',
                    data: [],
                    backgroundColor: thresholdColor,
                    borderColor: thresholdColor,
                    pointRadius: 0,
                    pointBorderWidth: 5,
                    pointStyle: 'line'
                }]
            };
        } else {
            chartData = {
                labels: ['', '', '', ''],
                datasets: [
                {
                    type: 'line',
                    order: 1,
                    label: 'VMT Threshold',
                    data: [],
                    backgroundColor: thresholdColor,
                    borderColor: thresholdColor,
                    pointRadius: 0,
                    pointBorderWidth: 5,
                    pointStyle: 'line'
                }, {
                    type: 'bar',
                    order: 3,
                    label: 'VMT',
                    data: [],
                    backgroundColor: projectColor,
                    borderColor: projectColor,
                    borderWidth: 1,
                    pointStyle: 'rect'
                }, {
                    type: 'bar',
                    order: 4,
                    label: 'VMT with Mitigation',
                    data: [],
                    backgroundColor: mitigationColor,
                    borderColor: mitigationColor,
                    borderWidth: 1,
                    pointStyle: 'rect'
                }]
            };
            if (this.hasVt) {
                chartData['datasets'].push({
                    type: 'line',
                    order: 2,
                    label: 'VT Threshold',
                    data: [],
                    backgroundColor: thresholdColor,
                    borderColor: thresholdColor,
                    borderDash: [10,5],
                    pointRadius: 0,
                    pointStyle: 'dash'
                });
                chartData['datasets'].push({
                    type: 'bar',
                    order: 5,
                    label: 'VT',
                    data: [],
                    backgroundColor: project2Color,
                    borderColor: project2Color,
                    borderWidth: 1,
                    pointStyle: 'rect'
                });
                chartData['datasets'].push({
                    type: 'bar',
                    order: 6,
                    label: 'VT with Mitigation',
                    data: [],
                    backgroundColor: mitigation2Color,
                    borderColor: mitigation2Color,
                    borderWidth: 1,
                    pointStyle: 'rect'
                });
            }
        }

        let context = document.getElementById(`vmtChart_${result.iteCode}_${result.index}`)
        if (context) {
            this.charts.push(new Chart(context, {
                data: chartData,
                options: capitaOptions
            }));
        }

        let thresholds = [];
        for (let i = 0; i < this.maxDataPoints; i++) {
            thresholds[i] = 1;
        }

        if (this.agencyConfig.hasHomeBasedWork)
            this.updateChart(this.charts[chartIndex], 2, thresholds);
        else {
            this.updateChart(this.charts[chartIndex], 0, thresholds);
            if (this.hasVt)
                this.updateChart(this.charts[chartIndex], 1, thresholds);
        }
    }

    updateCharts(chartIndex: number) {
        if (chartIndex < 0) {
            for (let i = 0; i < this.output.length; i++) {
                this.updateCharts(i);
            }
            return;
        }

        let thisOutput = this.output[chartIndex];


        if (thisOutput.landUseType === 'Residential' && this.agencyConfig.hasHomeBasedWork)
            this.charts[chartIndex].options.plugins.title.text = 'VMT/Capita';
        if (thisOutput.isTotalVmt)
            this.charts[chartIndex].options.plugins.title.text = 'Total VMT/Service Population';
        if (thisOutput.thresholdSource)
            this.charts[chartIndex].data.datasets[2].label = `${this.agencyConfig.isStatewide ? thisOutput.thresholdSource: ''} Threshold`;

        if (this.agencyConfig.hasHomeBasedWork) {
            let chartValue = parseFloat(thisOutput.capitaProject.toFixed(1));
            let chartMitigationValue = parseFloat(thisOutput.capitaProjectWithMitigation.toFixed(1));
            if (thisOutput.isTotalVmt) {
                chartValue = thisOutput.totalVmt;
                chartMitigationValue = thisOutput.totalVmtMitigation;
            }
            this.updateChart(this.charts[chartIndex], 0, [null, chartValue, null, null]);
            this.updateChart(this.charts[chartIndex], 1, [null, null, chartMitigationValue, null]);
        } else {
            this.updateChart(this.charts[chartIndex], 2, [null, parseFloat(thisOutput.attributableVmt.toFixed(1)), null, null, null]);
            this.updateChart(this.charts[chartIndex], 3, [null, parseFloat(thisOutput.vmtPerServicePopMitigation.toFixed(1)), null, null, null]);
            this.updateChart(this.charts[chartIndex], 4, [null, null, parseFloat(thisOutput.attributableVt.toFixed(1)), null, null]);
            this.updateChart(this.charts[chartIndex], 5, [null, null, parseFloat(thisOutput.vtPerServicePopMitigation.toFixed(1)), null, null]);
        }

        if (this.agencyConfig.hasHomeBasedWork) {
            let thresholds = [];
            for (let i = 0; i < this.maxDataPoints; i++) {
                thresholds[i] = thisOutput.threshold;
            }
            this.updateChart(this.charts[chartIndex], 2, thresholds);
        } else {
            let vmtThresholds = [];
            let vtThresholds = [];
            for (let i = 0; i < this.maxDataPoints; i++) {
                vmtThresholds[i] = thisOutput.vmtThreshold;
                vtThresholds[i] = thisOutput.vtThreshold;
            }
            this.updateChart(this.charts[chartIndex], 0, vmtThresholds);
            if (this.hasVt)
                this.updateChart(this.charts[chartIndex], 1, vtThresholds);
        }

        if (this.sumChart) {
            this.updateChart(this.sumChart, 2, [null, this.cityVmtPerServicePopulation, null, null, null]);
            this.updateChart(this.sumChart, 3, [null, this.getSummaryVmtMitigation() , null, null, null]);
            this.updateChart(this.sumChart, 4, [null, null, this.cityVtPerServicePopulation, null, null]);
            this.updateChart(this.sumChart, 5, [null, null, this.getSummaryVtMitigation(), null, null]);
        }
    }

    getTazIds() {
        let options = {
            hasPriority: false
        };
        if (this.agencyConfig.priorityLayers.length > 0) {
            options.hasPriority = true;
        }
        if (this.mapSource === 1)
            this.mapInteractionService.getTazFromSelection(options);
        else
            this.mapInteractionService.getTazFromShapefile();
    }

    calculate(tazData: any[]) {
        let settings = new VmtSettings();
        settings.analysisYear = this.analysisYear;
        settings.projectContextSetting = this.projectContext;
        settings.agencyId = this.agencyConfig.agencyID;
        settings.cityId = this.selectedCity;
        settings.cityVmtPerServicePopulation = this.cityVmtPerServicePopulation;
        settings.cityVtPerServicePopulation = this.cityVtPerServicePopulation;
        if (this.selectedProject)
            settings.projectId = this.selectedProject.id;
        if (this.authenticationService.IsLoggedIn)
            settings.userId = this.authenticationService.getUser().userId;
        settings.iteCodes = [];
        settings.highlightFeatures = this.mapHighlights;
        this.totalTrips = 0;
        this.totalAirQuality.reset();

        this.landUseList.forEach((landUse, i) => {
            let tdmUserInputs = [];
            let landUseTdm =  this.landUseTdms[i];

            if (!landUseTdm)
                landUseTdm = this.tdmStrategies;

            if (this.selectedTdm && i == this.currentTabIndex)
                this.copyTdm(this.selectedTdm, landUseTdm);

            landUseTdm.forEach((group, g) => {
                group.Strategy.forEach((strategy, s) => {
                    strategy.StrategyInputList.forEach((input, i) => {
                        input.IsUsed = this.tdmStrategies[g].Strategy[s].StrategyInputList[i].Input !== input.Input;
                        tdmUserInputs.push(input);
                    });
                });
            });

            settings.iteCodes.push({
                ITECode: landUse.iteCode,
                NoOfUnits: landUse.intensity,
                tdmUserInputs: tdmUserInputs,
                isTotalVmt: landUse.isTotalVmt,
                internalCaptureRate: landUse.internalCapture,
                presumptionIDs: []
            });
        });

        if (tazData.length)
            settings.county = this.agencyConfig.isStatewide ? this.agencyConfig.county: tazData[0].county;
        settings.TAZ = tazData;

        this.lastVmtSettings = settings;
        let city = this.cities.find(c => c.CityId === this.selectedCity);
        this.calculatorService.calculate(settings).subscribe((results: Array<any>) => {
            if (results.length === 0) return;
            this.landUseBanks = [];

            let i = 0;
            results.forEach(result => {
                if (this.output[i]){
                    this.output[i].capitaReduction = result.VMTCapitaReduction;
                    this.output[i].capitaProjectWithMitigation = result.VMTCapitaProjWithWithMitigation;
                    this.output[i].dailyTripsProject = result.DailyTripsProject;
                    this.output[i].dailyTripsReduction = result.DailyTripsReduction;
                    this.output[i].dailyTripsProjectWithMitigation = result.DailyTripsProjWithMitigation;
                    this.output[i].iteCode = result.ITECode.ITECode;
                    this.output[i].isTotalVmt = result.ITECode.IsTotalVMT;
                    this.output[i].landUseType = result.VMTLandUseType;

                    this.output[i].average = result.AverageHBWVMTEmpl;
                    this.output[i].threshold = result.Threshold15BelowAverage;
                    this.output[i].thresholdSource = result.ThresholdSource;
                    this.output[i].capitaProject = result.VMTCapitaProject;
                    this.output[i].significance = 'No'
                    this.output[i].airQuality = result.AirQuality
                    this.output[i].pedAccessibility = result.PedAccessibility;
                    this.output[i].vmtPerServicePop = result.VMT_SP;
                    this.output[i].vmtPerServicePopMitigation = result.VMT_SP_Mitigation;
                    this.output[i].vtPerServicePop = result.VT_SP;
                    this.output[i].vtPerServicePopMitigation = result.VT_SP_Mitigation;
                    this.output[i].pedThreshold = result.PedThreshold;
                    this.output[i].vmtThreshold = result.VmtThreshold;
                    this.output[i].vtThreshold = result.VtThreshold
                    this.output[i].mitigationPercent = result.MitigationPercent;
                    this.output[i].totalVmt = result.TotalVMT;
                    this.output[i].totalVmtMitigation = result.TotalVMTMitigation;
                    this.output[i].isPreexisting = result.IsLandUsePreexisting;
                    this.output[i].attributableVmt = result.AttributableVmt;
                    this.output[i].attributableVt = result.AttributableVt;
                    this.output[i].tdmResults = result.TdmResults;
                } else {
                    this.output.push({
                        capitaReduction: result.VMTCapitaReduction,
                        capitaProjectWithMitigation: result.VMTCapitaProjWithWithMitigation,
                        dailyTripsProject: result.DailyTripsProject,
                        dailyTripsReduction: result.DailyTripsReduction,
                        dailyTripsProjectWithMitigation: result.DailyTripsProjWithMitigation,
                        iteCode: result.ITECode.ITECode,
                        isTotalVmt: result.ITECode.IsTotalVMT,
                        landUseType: result.VMTLandUseType,

                        average: result.AverageHBWVMTEmpl,
                        threshold: result.Threshold15BelowAverage,
                        thresholdSource: result.ThresholdSource,
                        capitaProject: result.VMTCapitaProject,
                        significance: 'No',
                        airQuality: result.AirQuality,
                        pedAccessibility: result.PedAccessibility,
                        vmtPerServicePop: result.VMT_SP,
                        vmtPerServicePopMitigation: result.VMT_SP_Mitigation,
                        vtPerServicePop: result.VT_SP,
                        vtPerServicePopMitigation: result.VT_SP_Mitigation,
                        pedThreshold: result.PedThreshold,
                        vmtThreshold: result.VmtThreshold,
                        vtThreshold: result.VtThreshold,
                        mitigationPercent: result.MitigationPercent,
                        totalVmt: result.TotalVMT,
                        totalVmtMitigation: result.TotalVMTMitigation,
                        isPreexisting: result.IsLandUsePreexisting,
                        index: i,
                        presumptions: [],
                        attributableVmt: result.AttributableVmt,
                        attributableVt: result.AttributableVt,
                        tdmResults: result.TdmResults
                    });
                    this.defaultPresumptions.forEach(p => {
                        if (p.isProject) return;
                        let presumption = {
                            id: p.id,
                            title: p.title,
                            text: p.text,
                            oprPageNum: p.oprPageNum,
                            isSelected: false
                        };
                        if (this.loadedIteCodes) {
                            let landUseIte = this.loadedIteCodes[i];
                            if (landUseIte.presumptionIDs.contains(p.id))
                                presumption.isSelected = true;
                        }

                        this.output[i].presumptions.push(presumption);
                    });


                    if (!this.landUseTdms[i]) {
                        let defaultTdm = JSON.parse(JSON.stringify(this.tdmStrategies));
                        this.landUseTdms.push(defaultTdm);
                    }
                }
                if (this.landUseList[i].category === 'Retail' && this.landUseList[i].intensity < 50) {
                    let presumption = this.output[i].presumptions.find(p => p.id === 3)
                    if (presumption)
                        presumption.isSelected = true;
                }
                if (!this.agencyConfig.hasHomeBasedWork) {
                    if (this.landUseList[i].category === 'Residential' && this.landUseList[i].intensity < 50){
                        let presumption = this.output[i].presumptions.find(p => p.id === 5)
                        if (presumption)
                            presumption.isSelected = true;
                    } else if (this.landUseList[i].category !== 'Residential' && this.landUseList[i].intensity < 50) {
                        let presumption = this.output[i].presumptions.find(p => p.id === 6)
                        if (presumption)
                            presumption.isSelected = true;
                    }
                }

                let peopleRate = this.getPeopleRate(city, this.output[i].iteCode, this.output[i].landUseType);
                this.landUseList[i].vmt = this.output[i].capitaProject;
                let previousMitigation = this.landUseList[i].vmtWithMitigation;
                this.landUseList[i].vmtWithMitigation = this.output[i].capitaProjectWithMitigation;
                if (!this.agencyConfig.hasHomeBasedWork) {
                    this.landUseList[i].vmt = this.output[i].vmtPerServicePop;
                    this.landUseList[i].vmtWithMitigation = this.output[i].vmtPerServicePopMitigation;
                }
                this.landUseList[i].totalVmt = this.landUseList[i].vmt * peopleRate * this.landUseList[i].intensity;
                this.landUseList[i].threshold = this.output[i].threshold;
                this.landUseList[i].significantImpact = this.output[i].capitaProjectWithMitigation > this.output[i].threshold? 'Yes' : 'No';
                this.landUseList[i].averageTripLength = this.output[i].landUseType === 'Residential'
                    ? this.constants.RES_AVG_TRIP_LENGTH
                    : this.constants.WORK_AVG_TRIP_LENGTH;

                let tdmCount = 0;
                if (this.output[i].tdmResults) {
                    this.landUseTdms[i].forEach((group, g) => {
                        if (i === this.currentTabIndex)
                            this.selectedTdm[g].CurrentReduction = this.output[i].tdmResults[tdmCount].EffectiveGroupReduction * 100;
                        group.Strategy.forEach((strategy, s) => {
                            let reduction = this.output[i].tdmResults[tdmCount].EffectiveProjectReduction * 100;
                            strategy.Reduction = reduction;
                            if (i === this.currentTabIndex) {
                                this.selectedTdm[g].Strategy[s].Reduction = reduction;
                            }
                            tdmCount++;
                        });
                    });
                }

                if (i === this.currentTabIndex) {
                    this.selectedTdmReduction[i] = this.output[i].mitigationPercent * 100;
                }

                this.totalAirQuality.CO += result.AirQuality.CO;
                this.totalAirQuality.ROG += result.AirQuality.ROG;
                this.totalAirQuality.NOX += result.AirQuality.NOX;
                this.totalAirQuality.SOX += result.AirQuality.SOX;
                this.totalAirQuality.PM10 += result.AirQuality.PM10;
                this.totalAirQuality.PM2_5 += result.AirQuality.PM2_5;
                this.totalAirQuality.CO2 += result.AirQuality.CO2;
                this.totalAirQuality.MitigationCO += result.AirQuality.MitigationCO;
                this.totalAirQuality.MitigationROG += result.AirQuality.MitigationROG;
                this.totalAirQuality.MitigationNOX += result.AirQuality.MitigationNOX;
                this.totalAirQuality.MitigationSOX += result.AirQuality.MitigationSOX;
                this.totalAirQuality.MitigationPM10 += result.AirQuality.MitigationPM10;
                this.totalAirQuality.MitigationPM2_5 += result.AirQuality.MitigationPM2_5;
                this.totalAirQuality.MitigationCO2 += result.AirQuality.MitigationCO2;
                this.totalAirQuality.COMobile += result.AirQuality.COMobile;
                this.totalAirQuality.ROGMobile += result.AirQuality.ROGMobile;
                this.totalAirQuality.NOXMobile += result.AirQuality.NOXMobile;
                this.totalAirQuality.SOXMobile += result.AirQuality.SOXMobile;
                this.totalAirQuality.PM10Mobile += result.AirQuality.PM10Mobile;
                this.totalAirQuality.PM2_5Mobile += result.AirQuality.PM2_5Mobile;
                this.totalAirQuality.CO2Mobile += result.AirQuality.CO2Mobile;
                this.totalTrips += result.DailyTripsProject;

                i++;
            });

            let diff = this.output.length - i;
            if (diff > 0) {
                for (let j = 0; j < diff; j++) {
                    this.output.pop();
                }
            }

            if (this.totalTrips < 110) {
                // id: 4
                if (this.defaultPresumptions.length > 3)
                    this.defaultPresumptions[3].isSelected = true;
            } else {
                // id: 4
                if (this.defaultPresumptions.length > 3)
                    this.defaultPresumptions[3].isSelected = false;
            }

            this.updateCharts(-1);
        }, error => {
            console.log('Error');
        }).add(() => {
            let loader = document.getElementById('pageLoader');
            loader.classList.remove('is-active');
        });
    }

    calculateInternalCapture() {
        let settings = new VmtSettings();
        settings.analysisYear = this.analysisYear;
        settings.projectContextSetting = this.projectContext;
        settings.agencyId = this.agencyConfig.agencyID;
        settings.cityId = this.selectedCity;
        if (this.selectedProject)
            settings.projectId = this.selectedProject.id;
        if (this.authenticationService.IsLoggedIn)
            settings.userId = this.authenticationService.getUser().userId;
        settings.iteCodes = [];
        this.totalTrips = 0;

        this.landUseList.forEach((landUse, i) => {
            settings.iteCodes.push({
                ITECode: landUse.iteCode,
                NoOfUnits: landUse.intensity,
                tdmUserInputs: null,
                isTotalVmt: landUse.isTotalVmt,
                internalCaptureRate: 0,
                presumptionIDs: []
            });
        });

        this.calculatorService.calculateInternalCapture(settings).subscribe((results: Array<any>) => {
            if (results.length === 0) return;
            results.forEach((result, i) => {
                this.landUseList[i].trips = result.DailyTripsProject;
                this.landUseList[i].internalTrips = result.DailyTripsProject - result.InternalCapture?.Enter - result.InternalCapture?.Exit;
                this.landUseList[i].internalCapture = result.InternalCapture?.Rate;
            });
        }, error => {
            console.log('Error');
        }).add(() => {
            let loader = document.getElementById('pageLoader');
            loader.classList.remove('is-active');
        });
    }

    getPeopleRate(city: ICity, iteCode: string, landUseType: string) {
        let peopleRate = 1.00;
        if (city)
            peopleRate = city.EmployeeRate;
        if (landUseType !== 'Residential') {
            let landUse = this.landUses.find(x => x.iteCode === iteCode);
            peopleRate = landUse.conversionFactor;
        }
        return peopleRate;
    }

    updateChart(chartToUpdate: Chart, datasetIndex: number, data: number[]) {
        if (!chartToUpdate) return;
        if (this.agencyConfig.hasHomeBasedWork) {
            // Access by index for speed
            chartToUpdate.data.datasets[datasetIndex].data = data;
        } else {
            // Removing VT changes index
            let series = chartToUpdate.data.datasets.find(x => x.order == datasetIndex + 1);
            if (series)
                series.data = data;
        }
        chartToUpdate.update();
    }

    onTdmInputChange(groupIndex, strategyIndex) {
        try {
            let group = this.selectedTdm[groupIndex];
            let strategy = group.Strategy[strategyIndex];
            let needsMessage = false;
            let message = '';
            strategy.StrategyInputList.forEach((input, inputIndex) => {
                if (input.InputMax > 0 && parseFloat(input.Input) > input.InputMax) {
                    input.Input = input.InputMax.toString();
                }
                // proposed sidewalk length must be greater than or equal to existing
                if (group.GroupID === 4 && strategy.TDMID === 19 && inputIndex === 1) {
                    let firstInput = strategy.StrategyInputList[0];
                    if (parseFloat(input.Input) < parseFloat(firstInput.Input))
                        input.Input = firstInput.Input;
                } else if (group.GroupID === 4 && strategy.TDMID === 19 && inputIndex === 0) {
                    let secondInput = strategy.StrategyInputList[1];
                    if (parseFloat(input.Input) > parseFloat(secondInput.Input))
                        secondInput.Input = input.Input;
                } else if (group.GroupID === 2 && strategy.TDMID === 6) {
                    // If T-6 is selected then T-7 through T-11 should be zero
                    if (parseFloat(input.Input) > 0) {
                        group.Strategy.forEach((strategy, s) => {
                            if (strategy.TDMID <= 6 || strategy.TDMID > 11) return;
                            strategy.StrategyInputList.forEach(i => {
                                if(i.Input !== "0")
                                    needsMessage = needsMessage || true;
                                switch (i.InputType) {
                                    case "Number":
                                    case "Percent":
                                        i.Input = "0";
                                        break;
                                    case "DropDown":
                                        i.Input = "None";
                                        break;
                                }
                            });
                        });
                        message = this.tdmTripReductionMessage;
                    }
                } else if (group.GroupID === 2 && strategy.TDMID >= 7 && strategy.TDMID <= 11) {
                    // If T-6 is selected then T-7 through T-11 should be zero
                    let firstInput = group.Strategy.find(s => s.TDMID === 6).StrategyInputList[0];
                    if (parseFloat(firstInput.Input) > 0) {
                        input.Input = "0";
                        needsMessage = true;
                        message = this.tdmTripReductionMessage;
                    }
                } else if (group.GroupID === 2 && strategy.TDMID === 12) {
                    // if T-12 selected T-13 should be zero
                    if (parseFloat(input.Input) > 0) {
                        let nextStrategyInput = group.Strategy[strategyIndex + 1].StrategyInputList[0].Input;
                        if (nextStrategyInput !== "0")
                            needsMessage = true;
                        group.Strategy[strategyIndex + 1].StrategyInputList[0].Input = "0";
                        message = this.tdmParkingMessage;
                    }
                } else if (group.GroupID === 2 && strategy.TDMID === 13) {
                    // if T-12 selected T-13 should be zero
                    let firstInput = group.Strategy.find(s => s.TDMID === 12).StrategyInputList[0];
                    if (parseFloat(firstInput.Input) > 0) {
                        input.Input = "0";
                        needsMessage = true;
                        message = this.tdmParkingMessage;
                    }
                }
            });
            if (needsMessage) {
                this.notificationService.showNotification(NotificationType.Warning, message,
                NotificationCloseType.Self);
            }
        } catch (error) {
            // TODO:
        }

        this.calculate(this.latestTazData);
    }

    graphSelectedTabChange(tabEvent: MatTabChangeEvent){
        let isSummary = tabEvent.index >= this.output.length;
        if (isSummary) {
            this.doSummaryChart();
            return;
        }
        if (!this.charts[tabEvent.index]) {
            this.startCharts(tabEvent.index);
        }
        this.updateCharts(tabEvent.index);

        if (!isSummary) {
            switch (this.page) {
                case this.constants.PAGE_TDM:
                    this.switchSelectedTdm(tabEvent.index);
                    break;
            }
        }
        this.currentTabIndex = tabEvent.index;
    }

    graphSelectedTabFocusChange(tabEvent: MatTabChangeEvent)
    {
        document.querySelectorAll('[role="tab"]').forEach((element, i) => {
            element.classList.add('button');
            if (i == tabEvent.index) {
                element.classList.remove('is-outlined');
            } else {
                element.classList.add('is-outlined');
            }
        });
    }

    switchSelectedTdm(tdmIndex: number) {
        this.copyTdm(this.selectedTdm, this.landUseTdms[this.currentTabIndex])
        this.copyTdm(this.landUseTdms[tdmIndex], this.selectedTdm)
    }

    copyTdm(fromTdm: ITDMGroupStrategy[], toTdm: ITDMGroupStrategy[]) {
        for (let i = 0; i < fromTdm.length; i++) {
            toTdm[i].CurrentReduction = fromTdm[i].CurrentReduction;
            const strategy = fromTdm[i].Strategy;
            for (let j = 0; j < strategy.length; j++) {
                toTdm[i].Strategy[j].Reduction = strategy[j].Reduction;
                const inputList = strategy[j].StrategyInputList;
                for (let k = 0; k < inputList.length; k++) {
                    const input = inputList[k];
                    toTdm[i].Strategy[j].StrategyInputList[k].Input = input.Input;
                }
            }
        }
    }

    selectedLandUseChange() {
        let residentialMetric = 'Home-based VMT per Capita';
        let employeeVmtMetric = 'Home-based VMT per Worker'
        if (this.vmtMetrics.length > 1)
            this.vmtMetrics.pop();
        this.vmtMetric = this.vmtMetrics[0];
        if (!this.selectedLandUse) return;
        if (this.selectedLandUse.category == 'Residential') {
            this.vmtMetrics = [...this.vmtMetrics, residentialMetric];
        } else {
            this.vmtMetrics = [...this.vmtMetrics, employeeVmtMetric];
        }

        this.landUseHasAirQuality = true;
        let county = this.agencyConfig.isStatewide ? this.agencyConfig.county: this.latestTazData[0].county;
        let sub = this.iteDataService.hasAirQuality(county, this.selectedLandUse.iteCode).subscribe(result => {
            sub.unsubscribe();
            this.landUseHasAirQuality = result;
        });
    }

    calculateBanking() {
        this.landUseBanks = [];
        let city = this.cities.find(c => c.CityId === this.selectedCity);
        let bankInputs: BankInput[] = [];
        this.totalBankCost = 0;
        this.totalBankCostVt = 0;
        this.totalOptionalBankCost = 0;
        this.totalOptionalBankCostVt = 0;

        this.output.forEach((o, i) => {
            let peopleRate = this.getPeopleRate(city, o.iteCode, o.landUseType);
            let bankInput = new BankInput();
            bankInput.conversionFactor = peopleRate;
            bankInput.bankingCost = this.perUnitCost;
            bankInput.feasibleMitigationPercent = this.feasibleMitigation;
            bankInput.vmtOutput = {
                VMTCapitaReduction: o.capitaReduction,
                VMTCapitaProjWithWithMitigation: o.capitaProjectWithMitigation,
                DailyTripsProject: o.dailyTripsProject,
                DailyTripsReduction: o.dailyTripsReduction,
                DailyTripsProjWithMitigation: o.dailyTripsProjectWithMitigation,
                ITECode: this.lastVmtSettings.iteCodes[i],
                VMTLandUseType: o.landUseType,
                AverageHBWVMTEmpl: o.average,
                Threshold15BelowAverage: o.threshold,
                VMTCapitaProject: o.capitaProject,
                PedAccessibility: o.pedAccessibility,
                AirQuality: o.airQuality,
                VMT_SP: o.vmtPerServicePop,
                VMT_SP_Mitigation: o.vmtPerServicePopMitigation,
                VT_SP: o.vtPerServicePop,
                VT_SP_Mitigation: o.vtPerServicePopMitigation,
                MitigationPercent: o.mitigationPercent,
                TotalVMT: o.totalVmt,
                TotalVMTMitigation: o.totalVmtMitigation,
                IsLandUsePreexisting: o.isPreexisting,
            };
            bankInputs.push(bankInput);
        });

        this.calculatorService.calculatBanking(bankInputs).subscribe((results: Array<any>) => {
            if (results.length === 0) return;
            results.forEach(result => {
                let bank = {
                    absoluteVmt: result.absoluteVmt,
                    absoluteVt: result.absoluteVt,
                    feasibleMitigation:result.feasibleMitigation,
                    feasibleMitigationVt:result.feasibleMitigationVt,
                    totalMinMitigation:result.totalMinMitigation,
                    totalMinMitigationVt:result.totalMinMitigationVt,
                    remainingMitigation: result.remainingMitigation,
                    remainingMitigationVt: result.remainingMitigationVt,
                    remainingAbsoulteVmt: result.remainingAbsoulteVmt,
                    remainingAbsoulteVt: result.remainingAbsoulteVt,
                    cost: result.cost,
                    costVt: result.costVt,
                    optionalFullMitigation: result.optionalFullMitigation,
                    optionalFullMitigationVt: result.optionalFullMitigationVt,
                    optionalFullCost: result.optionalFullCost,
                    optionalFullCostVt: result.optionalFullCostVt,
                    requiresOverride: result.requiresOverride,
                    requiresOverrideVt: result.requiresOverrideVt
                }
                this.landUseBanks.push(bank);
                this.totalBankCost += bank.cost;
                this.totalBankCostVt += bank.costVt;
                this.totalOptionalBankCost += bank.optionalFullCost;
                this.totalOptionalBankCostVt += bank.optionalFullCostVt;
            });
        }, error => {
            console.log('Error');
        }).add(() => {
            let loader = document.getElementById('pageLoader');
            loader.classList.remove('is-active');
        });
    }

    onBankChange() {
        if (this.perUnitCost === 0 || this.feasibleMitigation === 0) return;
        this.calculateBanking();
    }

    loadProjectSettings(projectId: string) {
        this.calculatorService.getProject(this.authenticationService.getUser().userId, projectId)
        .subscribe((result: any) => {
            let city = this.cities.find(c => c.CityId === result.CityID);
            this.selectedCity = city.CityId;
            if (result.PresumptionIDs) {
                result.PresumptionIDs.forEach(p => {
                    this.updatePresumption({id: p, isSelected: true});
                });
            }

            this.mapInteractionService.setHighlights(result.HighlightFeatures);
            this.projectName = result.ProjectName;
            this.selectedProject = {
                id: result.ProjectID,
                name: this.projectName,
                isHidden: false,
                url: null,
                description: result.ProjectDescription
            };
            this.analysisYear = result.AnalysisYear;
            this.projectContext = result.ProjectContextSetting;
            this.loadedIteCodes = result.ITECodes;
            result.ITECodes.forEach((ite, i) => {
                this.selectedLandUse = this.landUses.find(lu => lu.iteCode == ite.ITECode);
                this.selectedSize = ite.NoOfUnits;
                this.vmtMetric = ite.IsTotalVMT ? this.vmtMetrics[0] : this.vmtMetrics[1];
                this.onAddLandUseClick();

                let defaultTdm = JSON.parse(JSON.stringify(this.tdmStrategies));
                this.landUseTdms.push(defaultTdm);
                let landUseTdm =  this.landUseTdms[i];
                let flatIndex = 0;
                landUseTdm.forEach(group => {
                    group.Strategy.forEach(strategy => {
                        strategy.StrategyInputList.forEach(input => {
                            input.Input = ite.TDMUserInputs[flatIndex];
                            flatIndex++;
                        });
                    });
                });
            });
            let firstTdm = JSON.parse(JSON.stringify(this.landUseTdms[0]));
            this.selectedTdm = firstTdm;
            let tazData = [];
            result.TAZ.forEach(taz => {
                tazData.push({
                    id: taz.ID,
                    percentage: taz.Percentage,
                    county: result.County
                });
            });
            this.latestTazData = tazData;
            this.calculate(tazData);
            this.toDataURL(result.ImagePath).then(dataUrl => {
                this.screenshot = {
                    data: null,
                    dataUrl: typeof dataUrl === 'string' ? dataUrl : null
                }
            }, reject => {
                debugger;
            });

            this.loadProjectID = null;
        });
    }

    getLandUseListSumVmt() {
        return this.landUseList.reduce((sum, item) => sum + item.vmt, 0);
    }

    getLandUseListSumVmtMitigation() {
        return this.landUseList.reduce((sum, item) => sum + (item.vmtWithMitigation ? item.vmtWithMitigation : 0), 0);
    }

    getLandUseListSumVmtTotal() {
        return this.agencyConfig.hasHomeBasedWork
            ? this.landUseList.reduce((sum, item) => sum + item.totalVmt, 0)
            : this.output.reduce((sum, item) => sum + item.totalVmt, 0);
    }

    getLandUseListSumDailyTrips() {
        return this.output.reduce((sum, item) => sum + item.dailyTripsProject, 0);
    }

    getSummaryMitigationPercent() {
        return 1 - (this.output.reduce((sum, item) => sum + item.vmtPerServicePopMitigation, 0)
            / this.cityVmtPerServicePopulation);
    }

    getSummaryVmtMitigation() {
        return this.output.reduce((sum, item) => sum + item.vmtPerServicePopMitigation, 0);
    }

    getSummaryVtMitigation() {
        return this.output.reduce((sum, item) => sum + item.vtPerServicePopMitigation, 0);
    }

    getSummaryVmtThreshold() {
        if (this.output.length > 0)
            return this.output[0].vmtThreshold;
        return 0;
    }

    getSummaryVtThreshold() {
        if (this.output.length > 0)
            return this.output[0].vtThreshold;
        return 0;
    }

    getSummaryPedAccessibility() {
        if (this.output.length > 0)
            return this.output[0].pedAccessibility;
        return 0;
    }

    getSummaryPedThreshold() {
        if (this.output.length > 0)
            return this.output[0].pedThreshold;
        return 0;
    }

    hasSummaryPedAccessibility() {
        if (this.output.length > 0)
            return this.output[0].pedAccessibility > 0;
        return false;
    }

    onPresumptionsInfoClick() {
        let modal = document.getElementById('presumptionsModal');
        modal.classList.add('is-active');
    }

    onPresumptionsModalClose() {
        let modal = document.getElementById('presumptionsModal');
        modal.classList.remove('is-active');
    }

    onCheckboxClick($event, id) {
        let presumption = this.defaultPresumptions.find(p => p.id === id);
        presumption.isSelected = $event.checked;
    }

    onCaptureMethodChanged($event) {
        // restore internal capture
        if (this.captureMethod === 1)
            this.calculateInternalCapture();
    }

    onCustomInternalCaptureChange(newValue, id) {
        let row = this.landUseList.find(x => x.id === id);
        row.internalCapture = newValue;
        row.internalTrips = Math.round(row.trips * row.internalCapture / 100);
    }

    onKeyDown($event: KeyboardEvent) {
        if ($event.key === 'Enter')
            this.onAddLandUseClick();
    }

    setUpMapRenderers(layer?: string) {
        this.isRendererSetUp = true;

        let state = this.agencyConfig.agencyName;
        if (isGuid(state)) state = 'California';
        const blockGroupName = `${state} Block Group`;

        let layerName = layer ?? blockGroupName;
        let classBreaks: ClassBreak[] = [];
        let opacity = 0.5;
        classBreaks.push({
            min: 1.0001,
            max: 10,
            color: [205, 35, 30, opacity],
            label: '15% or More Above Threshold'
        });
        classBreaks.push({
            min: 0.8501,
            max: 1,
            color: [255, 140, 0, opacity],
            label: '1% to 15% Above Threshold'
        });
        classBreaks.push({
            min: 0.00001,
            max: 0.85,
            color: [15, 255, 80, opacity],
            label: 'At or Below Threshold'
        });

        let titles = ['Residential VMT/Capita', 'Work VMT/Employee'];
        let expressions = this.agencyConfig.isStatewide
            ? ['$feature.RESI_VMTPC / Max($feature.JUR_AVG_RE, $feature.COUNTY_AVG)', '$feature.HBW_VMTPEM / $feature.COUNTY_AV1']
            : ['$feature.RESIVMTPER / 22.3', '$feature.WORKVMTPER / 19.0'] ;
        expressions.forEach((expression, i) => {
            let classBreakInfo = new ClassBreakInfo();
            classBreakInfo.classBreaks = classBreaks;
            classBreakInfo.title = titles[i];
            this.mapInteractionService.addLayerClassBreaks(layerName,
                expression, classBreakInfo);
        });
    }

    checkFar(layerTitle: string, $event: MatLegacyCheckboxChange) {
        if (layerTitle.includes('Transit') && this.agencyConfig.hasPriFloorAreaRatio){
            this.needFloorAreaRatioInput = $event.checked;
        }
    }

    //TODO: fix
    toDataURL = url => fetch(url, {mode: 'no-cors'})
        .then(response => response.blob())
        .then(blob => new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                resolve(reader.result)
            };
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        }));

}
