import { SiteProperty } from "./siteProperty";

export class SiteScoreRow {
    public siteCharacteristic: string;
    public unit: string;
    public threshold1: string;
    public siteValue: number;
    public threshold2: string;
    public allSiteAverage: number;
    public stateAverage: number;
    public thresholdPassed: boolean;
}

export class EvSite {
    public siteId: number;
    public geoId: number;
    public blockGeoId: number;
    public address: string;
    public city: string;
    public county: string;
    public state: string;
    public zip: string;
    public additionalProperties: Map<string, SiteProperty>;
    public finalScore: string;
    public finalScoreNum: number;
    public adjustedScore: string;
    public adjustedScoreNum: number;
    public siteValues: number[];
}

export interface SiteDataForExport {
    'Site Id': number;
    Address: string;
    City: string;
    County: string;
    State: string;
    'Zip Code': string;
    'Preset Score': string;
    'Adjusted Final Score': string;
    [propName: string]: string | number;
}

