import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ScreenInputDataService {

  constructor(private _httpClient: HttpClient) { }

  getScreenInputs(): Observable<any> {
    const url = `${environment.api.baseURL}/Sites/ScreenInputs/GetScreenInputs`;
    return this._httpClient.get(url);
  }

}
