import { Injectable } from '@angular/core';
import * as Notifications from 'simple-notifications-solution';

export enum NotificationType {
    Success,
    Error,
    Warning,
    Default,
    Theme
}

// is flag
export enum NotificationCloseType {
    Self = 1,
    DeleteButton = 2
}

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    private notificationOptions: any = {
        animationInName: 'slidein',
        animationOutSelf: 'slideout 0.5s',
        animationOutClose: 'fadeout 0.5s',
        closeButtonSelector: '.delete',
        closeSelfOnClick: true,
        startTopPosition: 8,
        gap: 8,
        // i - is the number of notifications before the current one
        delayFunction: (i) => 3 + 2 * i,
        topTransition: 'top .75s ease-in-out'
    };
    private allCloseTypes = NotificationCloseType.Self | NotificationCloseType.DeleteButton;

    constructor() {
        let notifications = new Notifications(".notification");
        notifications.init();
    }

    // Use NotificationCloseType not integer for closeType
    showNotification(notificationType: NotificationType, message: string, closeType: number) {
        let el = document.createElement('p');
        el.classList.add('notification');
        let innerHTML = message;

        // Notification has to either close itself, show delete button, or both
        // If correct flag not picked then close self so it's not stuck open
        if (closeType === 0 || closeType > this.allCloseTypes)
            closeType = 1;

        if ((closeType & NotificationCloseType.Self) === NotificationCloseType.Self)
            el.setAttribute('data-close', 'self');
        if ((closeType & NotificationCloseType.DeleteButton) === NotificationCloseType.DeleteButton)
            innerHTML += '<button class="delete will-close" type="button">Close</button>';

        switch (notificationType) {
            case NotificationType.Success:
                el.classList.add('is-success');
                break;
            case NotificationType.Error:
                el.classList.add('is-danger');
                break;
            case NotificationType.Warning:
                el.classList.add('is-warning');
                break;
            case NotificationType.Theme:
                el.classList.add('is-primary');
                break;
            case NotificationType.Default:
            default:
                break;
        }

        el.innerHTML = innerHTML;
        document.querySelector('body').appendChild(el);
    }

}
