import { Component, OnInit } from '@angular/core';
import { IProject } from 'src/app/models/shared/user.model';
import { AuthenticationService } from 'src/app/services/shared/authentication.service';
import { CalculatorService } from 'src/app/services/vmt/calculator.service';
import { NavigationService } from 'src/app/services/shared/navigation.service';

@Component({
    selector: 'app-user-projects',
    templateUrl: './user-projects.component.html',
    styleUrls: ['./user-projects.component.scss']
})
export class UserProjectsComponent implements OnInit {
    columnSize = 4;
    projects: IProject[] = [];
    projectGroups: IProject[][] = [];
    clientSite: string;

    constructor(private calculatorService: CalculatorService,
        private authenticationService: AuthenticationService,
        private navigationService: NavigationService) {
            navigationService.clientUpdated$.subscribe(clientSite => {
                this.clientSite = clientSite
            });
            this.clientSite = sessionStorage.getItem('agency');
    }

    ngOnInit(): void {
        this.calculatorService.getProjects(this.authenticationService.getUser().userId).subscribe((results: any[]) => {
            results.forEach(result => {
                this.projects.push({
                    id: result.ProjectID,
                    isHidden: false,
                    name: result.ProjectName,
                    description: result.ProjectDescription,
                    url: result.ImagePath
                })
            });
            this.organizeProjects();
        })
    }

    organizeProjects() {
        this.projects.forEach((project, i) => {
            let group: IProject[];
            if (i % this.columnSize === 0) {
                group = [];
                this.projectGroups.push(group);
            }
            else
                group = this.projectGroups[this.projectGroups.length - 1];

            group.push(project);
        });

        if (this.projectGroups.length  === 0) return;
        let lastGroup = this.projectGroups[this.projectGroups.length - 1];
        let diff = this.columnSize - lastGroup.length;
        for (let i = 0; i < diff; i++) {
            lastGroup.push({id: 'placeholder_' + i, name: '', description: '', url: '', isHidden: true})
        }
    }

}
